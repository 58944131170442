import React, { useCallback, useEffect, useState } from "react";
import YouTube from "react-youtube";
import Modal from "./../../../../SharedComponents/Modal/Modal";
import "./ProductWatchDemoVideoModal.css";

interface Props {
  visable: boolean;
  videoId: string;
  handleClose: () => void;
}

const ProductWatchDemoVideoModal: React.FC<Props> = ({
  visable,
  handleClose,
  videoId,
}) => {
  const [player, setPlayer]: any = useState(null);

  const opts: any = {
    height: "100%",
    width: "100%",
    playerVars: {
      // autoplay: 1,
    },
  };

  useEffect(() => {
    !visable && player && player.pauseVideo();
  }, [player, visable]);

  const _onReady = useCallback((event) => {
    setPlayer(event.target);
    event.target.pauseVideo();
  }, []);
  return (
    <>
      <div className="product__video__modal">
        <Modal
          visable={visable}
          title="Demo Video"
          closeModalHandler={handleClose}
        >
          <YouTube videoId={videoId} opts={opts} onReady={_onReady} />
        </Modal>
      </div>
    </>
  );
};

export default React.memo(ProductWatchDemoVideoModal);
