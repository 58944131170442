import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Button, Col, Row } from "reactstrap";
import TableWithCheckBoxs from "./TableWithCheckBoxs";
import InputTwoLanguages from "./../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import { Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";
import { REQUEST } from "../../../../Services/callAPI";
import { apiEndPoint } from "../../../../Services/config.json";
import ImportPopup from "./ImportPopup";

const PricingForm = ({ productId, data }) => {
	const { t } = useTranslation();
	const colAttributes = [
		["Option", "الخاصية"],
		["OptionEn", "الخاصية بالانجليزية"],
		["IsGold", "ذهبي"],
		["IsSilver", "فضي"],
		["IsBronze", "برونزي"],
	];

	const [priceSectionInitValues, setPriceSectionInitValues] = useState({
		Title: "",
		TitleEn: "",
		SubTitle: "",
		SubTitleEn: "",
	});

	const [priceSection, setPriceSection] = useState({
		...priceSectionInitValues,
	});

	const [status, setStatus] = useState(Status.IDLE);

	useEffect(() => {
		if (data) {
			setStatus(Status.UPDATE);
			setPriceSection({ ...data });
			setPriceSectionInitValues({ ...data });
		} else {
			setStatus(Status.ADD);
		}
	}, [data]);

	//
	const updatePriceSection = useCallback((value, id) => {
		setPriceSection((prev) => ({ ...prev, [id]: value }));
	}, []);

	const isNotValid = useMemo(() => {
		for (let key of Object.keys(priceSection)) {
			if (!priceSection[key] && key !== "ProductOptions") {
				return true;
			}
		}

		if (priceSection.ProductId) {
			let result = true;
			for (let key of Object.keys(priceSection)) {
				if (priceSection[key] !== priceSectionInitValues[key]) {
					result = false;
				}
			}

			return result;
		}

		return false;
	}, [priceSection, priceSectionInitValues]);

	const priceSectionValuesAsFormData = useMemo(() => {
		let formData = new FormData();
		formData.append("ProductId", productId.toString());
		for (let [key, value] of Object.entries(priceSection)) {
			formData.append(key.toString(), value);
		}
		return formData;
	}, [priceSection, productId]);

	// submit form
	const addHandle = useCallback(() => {
		let config = {
			method: status === Status.ADD ? "post" : "PUT",
			url: "ProductPrice",
			data: priceSectionValuesAsFormData,
		};

		REQUEST(config)
			.then((response) => {
				response.Image = `${apiEndPoint}${response.ImagePath}`;
				delete response.ImagePath;
				setPriceSectionInitValues({ ...response });
				setPriceSection({ ...response });
				setStatus(Status.UPDATE);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [priceSectionValuesAsFormData, status]);

	// cancel form
	const cancelForm = useCallback(() => {
		let config = {
			method: "DELETE",
			url: `ProductPrice`,
			data: {
				ProductId: 2,
			},
		};

		REQUEST(config)
			.then((response) => {
				setPriceSectionInitValues({
					ProductId: productId,
					Title: "",
					TitleEn: "",
					SubTitle: "",
					SubTitleEn: "",
				});
				setPriceSection({
					ProductId: productId,
					Title: "",
					TitleEn: "",
					SubTitle: "",
					SubTitleEn: "",
				});
				setStatus(Status.ADD);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [productId]);

	const [showImportPopup, setShowImportPopup] = useState(false);
	const importFromOtherProduct = useCallback(() => {
		setShowImportPopup(true);
	}, []);
	const importHandle = useCallback(
		(id) => {
			console.log(id);

			let config = {
				method: "POST",
				url: `Products/ImportFromProduct/${productId}/${id}`,
			};

			REQUEST(config).then((response) => {
				setShowImportPopup(false);
			});
		},
		[productId]
	);

	return (
		<div className="mt-2">
			<ImportPopup
				visible={showImportPopup}
				onHiding={() => setShowImportPopup(false)}
				importHandle={importHandle}
			/>
			<InputTwoLanguages
				id="Title"
				label={t("Title")}
				onValueChange={updatePriceSection}
				value={priceSection.Title}
				valueEn={priceSection.TitleEn}
			/>
			<InputTwoLanguages
				id="SubTitle"
				label={t("SubTitle")}
				onValueChange={updatePriceSection}
				value={priceSection.SubTitle}
				valueEn={priceSection.SubTitleEn}
			/>
			<div className="button-container">
				<Row>
					<Col style={{ width: "170px" }}>
						<Button
							className="btn btn btn-success col-12"
							disabled={isNotValid}
							onClick={addHandle}
						>
							{status === Status.UPDATE ? t("Update") : t("Add")}
						</Button>
					</Col>
					{/* <Col style={{ width: "170px" }}>
						<Button
							className=" btn btn-danger col-12"
							disabled={status !== Status.UPDATE}
							onClick={cancelForm}
						>
							{t("Delete")}
						</Button>
					</Col> */}
				</Row>
			</div>
			{priceSection?.ProductId && (
				<TableWithCheckBoxs
					colAttributes={colAttributes}
					ProductPriceId={priceSection?.ProductId}
					importFromOtherProduct={importFromOtherProduct}
				/>
			)}
		</div>
	);
};

export default React.memo(PricingForm);
