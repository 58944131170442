import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./productPrice.css";
import { Button } from "devextreme-react/button";
import { useTranslation } from "react-i18next";

const ProductPrice = ({ productPricing, subscriptionPrices, productInfo }) => {
	const { t, i18n } = useTranslation();

	const [options, setOptions] = useState([]);

	useEffect(() => {
		let sorted = productPricing.ProductOptions.sort((a, b) =>
			a.Rank < b.Rank ? -1 : 1
		);
		setOptions(sorted);
	}, [productPricing.ProductOptions]);

	const prices = useMemo(() => {
		return subscriptionPrices.reduce((prevValue, currentValue) => {
			return {
				...prevValue,
				[currentValue.PriceCategoryTitle]: currentValue,
			};
		}, {});
	}, [subscriptionPrices]);

	const subscriptionHandle = useCallback(
		(e) => {
			const { Icon, ProductNameEn, ProductId } = productInfo;
			localStorage.setItem(
				"cart",
				JSON.stringify({ ...e, ProductId, ProductNameEn })
			);
			window.location.href = "#/cart";
		},
		[productInfo]
	);

	return (
		<>
			<section className="product__section product__faqs product__price">
				<div className="index__container d-flex flex-column justify-content-center align-items-center">
					<h1 className="product__section__title text-center">
						{i18n.language === "ar"
							? productPricing.Title
							: productPricing.TitleEn}
					</h1>
					<h4 className="product__section__pragraph text-center py-3 w-75">
						{i18n.language === "ar"
							? productPricing.SubTitle
							: productPricing.SubTitleEn}
					</h4>
					<div className="table__wrapper col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 mt-5">
						<div className="BestValueRow row d-flex">
							<div className="col-6 d-none d-lg-block"></div>
							<div
								className="BestValue col-sm-6 col-md"
								style={{ backgroundColor: "#fab005" }}
							>
								Best Value
							</div>
							<div className="col-md col-sm-0"></div>
							<div className="col-md col-sm-0"></div>
						</div>
						<div className="table__header row table__row">
							<div className="col-6 d-none d-lg-block">
								<span>{t("System Options")}</span>
							</div>
							<div className="BestValue col-sm-6 col-md">
								<i className="fa-solid fa-file-invoice fa-2xl text-gold my-2"></i>
								<span className="cell__title  text-gold mb-2">
									{t("Golden")}
								</span>
								<div className="Separator"></div>
								<span className="Price my-3">
									{prices["Gold"]?.YearPrice || 0}${" "}
								</span>
								{/* <span className="Price my-3">$2,199</span> */}
								<div
									className="btn btn-outline-warning"
									onClick={() =>
										subscriptionHandle(prices["Gold"])
									}
								>
									{t("Subscribe")}
								</div>
							</div>
							<div className="silver__value col-sm-6 col-md">
								<i className="fa-solid fa-receipt fa-2xl text-secondary my-2"></i>
								<span className="cell__title mb-2 text-secondary">
									{t("Silver")}
								</span>
								<div className="Separator"></div>
								<span className="Price my-3">
									{prices["Silver"]?.YearPrice || 0}$
								</span>
								<div
									className="btn btn-outline-secondary"
									onClick={() =>
										subscriptionHandle(prices["Silver"])
									}
								>
									{t("Subscribe")}
								</div>
							</div>
							<div className="bronze__value col-sm-6 col-md">
								<i className="fa-solid fa-file-invoice-dollar fa-2xl text-bronze my-2"></i>
								<span className="cell__title mb-2 text-bronze">
									{t("Bronze")}
								</span>
								<div className="Separator"></div>
								<span className="Price my-3">
									{prices["Bronze"]?.YearPrice || 0}$
								</span>
								<div
									className="btn btn-outline-secondary text-bronze"
									onClick={() =>
										subscriptionHandle(prices["Bronze"])
									}
								>
									{t("Subscribe")}
								</div>
							</div>
						</div>
						<div className="price__table d-none d-lg-block">
							{options.map((v, index) => {
								return (
									<div key={index} className="row table__row">
										<div className="col-6 value__description">
											<i className="fa-solid fa-arrow-right-long"></i>
											<span className="mx-3">
												{i18n.language === "en"
													? v.OptionEn
													: v.Option}
											</span>
										</div>
										<div className="BestValue col-2">
											<i
												className={
													v.IsGold
														? "fa-solid fa-check text-success"
														: "fa-solid fa-xmark text-danger"
												}
											></i>
										</div>
										<div className="silver__value col-2">
											<i
												className={
													v.IsSilver
														? "fa-solid fa-check text-success"
														: "fa-solid fa-xmark text-danger"
												}
											></i>
										</div>
										<div className="bronze__value col-2">
											<i
												className={
													v.IsBronze
														? "fa-solid fa-check text-success"
														: "fa-solid fa-xmark text-danger"
												}
											></i>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default React.memo(ProductPrice);
