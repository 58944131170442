import React, { useEffect, useState } from "react";

import { apiEndPoint } from "../../../../../Services/config.json";
// Css
import "./MainPrograms.css";

import PageHeader from "../../../../../SharedComponents/PageHeader/PageHeader";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	defualtParagraphSectionInterface,
	ParagraphSectionInterface,
} from "../../../../../Interfaces/Interfaces";

import { HOME_PROGRAMS } from "../../API.Home";
interface Props {
	dataTitle: ParagraphSectionInterface[];
	iconsdata: ParagraphSectionInterface | any;
}
const MainPrograms: React.FC<Props> = ({ dataTitle, iconsdata }) => {
	let history = useHistory();
	const { i18n } = useTranslation();
	let [data, setdata] = useState([
		defualtParagraphSectionInterface,
		defualtParagraphSectionInterface,
	]);
	interface Product {
		categoryId: number;
		domainId: number;
		iconPath: string;
		id: number;
		isActive: Boolean;
		productDarkSection: any;
		productName: string;
		productNameEn: string;
		productQuestions: null;
		rank: number;
	}
	let [programdata, setprogramdata] = useState<Product[]>([]);

	useEffect(() => {
		if (dataTitle.length > 0) {
			setdata(dataTitle);
		}
	}, [dataTitle]);
	useEffect(() => {
		let datafetch = async () => {
			if (iconsdata.description && iconsdata.title) {
				let x: any = await HOME_PROGRAMS(
					parseInt(iconsdata.description),
					parseInt(iconsdata.title)
				);
				setprogramdata(x);
			}
		};
		datafetch();
	}, [iconsdata]);
	useEffect(() => {
		let datafetch = async () => {
			if (iconsdata.description && iconsdata.title) {
				let x: any = await HOME_PROGRAMS(
					parseInt(iconsdata.description),
					parseInt(iconsdata.title)
				);
				setprogramdata(x);
			}
		};
		datafetch();
	}, [iconsdata.description, iconsdata.title]);
	return (
		<PageHeader
			backgroundImage={
				typeof data[0].imagePath == "string"
					? `${apiEndPoint}${encodeURI(
							data[0].imagePath.replaceAll("\\", "/")
					  )}`
					: ""
			}
		>
			<div className="container">
				<div className="mainTitle">
					{i18n.language === "en" ? data[0].titleEn : data[0].title}
				</div>
				<div className="subTitle">
					{data.length > 0
						? i18n.language === "en"
							? data[0].descriptionEn
							: data[0].description
						: null}
				</div>

				<div className="allIconsContainer">
					{}

					{programdata.map((da, index) => {
						return (
							<div
								key={index}
								className="iconContainer"
								onClick={() =>
									history.push("/product/" + da.id)
								}
							>
								<div
									style={{
										maxHeight: "50px",
										maxWidth: "50px",
										height: "50px",
										width: "50px",
										margin: "auto",
									}}
								>
									<img
										alt="1"
										src={
											typeof da.iconPath == "string"
												? `${apiEndPoint}${encodeURI(
														da.iconPath.replaceAll(
															"\\",
															"/"
														)
												  )}`
												: ""
										}
										width="100%"
										height={"100%"}
									/>
								</div>

								<div style={{}}>
									{" "}
									{i18n.language === "en"
										? da.productNameEn
										: da.productName}
								</div>
							</div>
						);
					})}
				</div>

				<div className="moreProgramsContainer">
					<div className="moreProgramsText">
						{i18n.language === "en"
							? data[1].titleEn
							: data[1].title}
					</div>
					{/* <Link
						to="/demos"
						className="btn btn-lg exploreButtonCustomization"
					>
						{i18n.language === "en"
							? data[1].descriptionEn
							: data[1].description}
					</Link> */}
				</div>
			</div>
		</PageHeader>
	);
};

/**

  <div className="iconContainer">
              <SiNiconico className="mainProgramsIcon" />
              <div>Title of the MainProgram</div>
              <div>Sub Title</div>
            </div>*/

export default MainPrograms;
