import { useState, useEffect } from "react";

import "./Map.css";

import TooltipContent from "./Components/TooltipContent";
import { Popover } from "devextreme-react/popover";

import MapComponents from "../../../../../SharedComponents/MapComponents/MapComponents";

import { GET_BRANCHES } from "../../../admin/Admin.Map/Map.Api";
import { useTranslation } from "react-i18next";
import { BranchInterface } from "../../../../../Interfaces/Interfaces";
import { GET_SECTION } from "../../../../12.TeamMembers/admin/Admin.Team.Api";

const Map = () => {
  const { i18n } = useTranslation();
  const [show, setShow] = useState<BranchInterface[]>([]);
  const [bran, setbran] = useState<BranchInterface[]>([]);
  const [Head, setHead] = useState({
    id: 0,
    title: "",
    titleEn: "",
    description: "",
    descriptionEn: "",
    imagePath: "",
    image: "",
  });

  useEffect(() => {
    let get_data = async () => {
      let x: any = await GET_SECTION("Map");
      let y: any = await GET_BRANCHES();
      let n: any = y.map((da) => {
        return { ...da, idx: "x" + da.id, show: false, styleclass: "" };
      });
      setShow(n);
      setbran(n);

      if (x.length > 0) {
        x[0].image = x[0].imagePath;
        setHead(x[0]);
      }
    };
    get_data();
  }, []);
  const getBranch = (id: string) => {
    return show.findIndex((x) => x.idx === id);
  };
  return (
    <div>
      <div className="PreMap">
        <div className="contentM">
          <h2 className="sys-h-align-center sys-max-width-md-80">
            {i18n.language === "en" ? Head.titleEn : Head.title}
          </h2>
          <p className="sys-h-align-center sys-max-width-md-80">
            {i18n.language === "en" ? Head.descriptionEn : Head.description}
          </p>
        </div>
      </div>
      <div className="Map">
        <div className="FullWidthContainer">
          <div className="MapContainer">
            <MapComponents />
            <div className="Points">
              {bran.map((da, index) => {
                return (
                  <div key={index}>
                    <div
                      key={index}
                      className={"Point " + show[getBranch(da.idx)].styleclass}
                      id={da.idx}
                      onClick={() => {
                        show[getBranch(da.idx)].show = true;
                        show[getBranch(da.idx)].styleclass = "PointTransction";
                        setShow([...show]);
                      }}
                      style={{
                        left: da.left + "%",
                        top: da.top + "%",
                      }}
                    ></div>
                    <Popover
                      target={"#" + da.idx}
                      position="right"
                      width={300}
                      visible={show[getBranch(da.idx)].show}
                      onHiding={() => {
                        show[getBranch(da.idx)].show = false;
                        show[getBranch(da.idx)].styleclass = "";
                        setShow([...show]);
                      }}
                    >
                      <TooltipContent data={da} />
                    </Popover>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
