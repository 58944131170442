//React
import React, { useEffect, useState } from "react";
import GoalsList from "./Components/GoalsList/GoalsList";

// Components
import MainPrograms from "../../1.Home/user/Components/MainPrograms/MainPrograms";
import ProgramsDefinations2 from "../../1.Home/user/Components/ProgramDefinations2/ProgramsDefinations2";
import Footer from "./Components/Footer/user/Footer";
import SuccessParteners from "./Components/SuccessParteners/SuccessParteners";
import Map from "./Components/Map/Map";
import Navbar from "../../../SharedComponents/Navbar/Navbar";

import {
  defualtParagraphSectionInterface,
  ParagraphSectionInterface,
} from "../../../Interfaces/Interfaces";
import { GET_SECTION } from "../../12.TeamMembers/admin/Admin.Team.Api";

import { useTranslation } from "react-i18next";

import "./Home.css";

function Home() {
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("no__scroll");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document
      .getElementsByTagName("html")[0]
      .classList.remove("sub__menu__open");
    document
      .getElementsByTagName("html")[0]
      .classList.remove("root__menu__open");
  }, []);

  let [paragraph, setparagraph] = useState<ParagraphSectionInterface[]>([]);
  let fetch = async () => {
    let y: any = await GET_SECTION("Home");

    setparagraph(y);
  };
  useEffect(() => {
    setLoading(true);
    fetch().then(() => {});
  }, []);

  return (
    <div className="">
      {loading && (
        <div className="overlay__loading">
          <svg
            version="1.1"
            id="L7"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 100 100"
          >
            <path
              fill="#3a7498"
              d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
  								c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="2.1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
            <path
              fill="#3a7498"
              d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
  								c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="2s"
                from="0 50 50"
                to="-360 50 50"
                repeatCount="indefinite"
              />
            </path>
            <path
              fill="#fff"
              d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
  								L82,35.7z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="2.5s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
          {/* <img
						style={{ width: "200px", position: "absolute" }}
						alt="Al-MedadSoft"
						src="https://api2.almedadsoft.com/Uplode/logomain%20xx.png"
					/> */}
        </div>
      )}
      <Navbar />

      {/* <div className="product__container"> */}
      <div className="home">
        <section style={{ position: "relative" }}>
          <MainPrograms
            dataTitle={
              paragraph.filter((x) => x.index === 1 || x.index === 0)
                ? paragraph.filter((x) => x.index === 1 || x.index === 0)
                : []
            }
            iconsdata={
              paragraph.find((x) => x.index === 4)
                ? paragraph.find((x) => x.index === 4)
                : defualtParagraphSectionInterface
            }
          />
        </section>
        <main>
          <div className="bodyComponent">
            <div
              className="Title text__middle w-100"
              style={{ textAlign: "center" }}
            >
              {" "}
              {i18n.language === "en"
                ? paragraph.find((x) => x.index === 2)?.titleEn
                : paragraph.find((x) => x.index === 2)?.title}
            </div>
            <div
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "17px",
              }}
            >
              {i18n.language === "en"
                ? paragraph.find((x) => x.index === 2)?.descriptionEn
                : paragraph.find((x) => x.index === 2)?.description}
            </div>
          </div>

          <ProgramsDefinations2 setLoading={setLoading} />

          <GoalsList />

          <SuccessParteners
            dataTitle={
              paragraph.find((x) => x.index === 3)
                ? paragraph.find((x) => x.index === 3)
                : defualtParagraphSectionInterface
            }
          />

          <Map />
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default Home;
