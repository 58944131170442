import { useCallback, useEffect, useState } from "react";
import "./TeamMembers.css";
import { useTranslation } from "react-i18next";
import { SiFacebook, SiTwitter, SiLinkedin, SiGoogle } from "react-icons/si";
import PageHeader from "../../../SharedComponents/PageHeader/PageHeader";
import Navbar from "../../../SharedComponents/Navbar/Navbar";

import { apiEndPoint } from "../../../Services/config.json";
import { GET_MEMBER, GET_SECTION } from "../admin/Admin.Team.Api";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";

const TeamMembers = () => {
  const { i18n } = useTranslation();
  const [selectedperson, setselectedperson] = useState(0);

  interface person {
    id: number;
    memberName: string;
    memberNameEn: string;
    jopTitle: string;
    jopTitleEn: string;
    body: string;
    bodyEn: string;
    image: string;
    imagePath: string;
    facebook?: string;
    google?: string;
    twitter?: string;
    linkedin?: string;
  }
  interface data {
    id: number;
    title: string;
    titleEn: string;
    description: string;
    descriptionEn: string;
    imagePath: string;
    image: string;
  }
  const [Data, setData] = useState<person[]>([]);
  const [Head, setHead] = useState<data>({
    id: 0,
    title: "",
    titleEn: "",
    description: "",
    descriptionEn: "",
    imagePath: "",
    image: "",
  });

  let fetch = async () => {
    let x: any = await GET_MEMBER();
    setData(x);
    x = await GET_SECTION("Team");
    x[0].image = x[0].imagePath;
    setHead(x[0]);
  };
  useEffect(() => {
    fetch();

    window.scrollTo(0, 0);
  }, []);

  const Person_details = useCallback(
    (e: number) => {
      if (Number(selectedperson) === Number(e)) {
        setselectedperson(0);
      } else {
        setselectedperson(e);
      }
    },
    [selectedperson]
  );

  return (
    <div className="about__us__header">
      <Navbar />

      <div className="product__container ">
        <section
          style={{
            position: "relative",
            boxShadow: "0 10px 20px 0px #36456466",
          }}
        >
          <PageHeader
            backgroundImage={
              typeof Head.imagePath === "string"
                ? `${apiEndPoint}${encodeURI(
                    Head.imagePath.replaceAll("\\", "/")
                  )}`
                : ""
            }
          >
            <div className="TeamMembers_headerp">
              {" "}
              {i18n.language === "en" ? Head.titleEn : Head.title}
            </div>
            <div className="TeamMembers_Bodyp">
              {i18n.language === "en" ? Head.descriptionEn : Head.description}
            </div>
          </PageHeader>
        </section>
        <main>
          <div style={{ marginTop: "20px" }}>
            <div
              className="row  TeamMembers_main-container  container"
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {Data.map((da) => {
                return (
                  <div
                    className=" col-lg-3 col-md-6 col-sm-12  TeamMembers_person-card "
                    style={{
                      direction: i18n.language === "en" ? "ltr" : "rtl",
                      justifyContent: "center",
                      display: "inline-grid",
                      margin: "30px 0",
                    }}
                  >
                    <div>
                      <div
                        className="TeamMembers_profile-img"
                        style={{
                          backgroundImage: `url(${
                            typeof da.imagePath === "string"
                              ? `${apiEndPoint}${encodeURI(
                                  da.imagePath.replaceAll("\\", "/")
                                )}`
                              : ""
                          })`,
                          borderRadius:
                            i18n.language === "en"
                              ? " 58% 53% 53% 0"
                              : " 58% 53%  0 53%",
                        }}
                      />
                      <h2 className="TeamMembers_title">
                        {i18n.language === "en"
                          ? da.memberNameEn
                          : da.memberName}
                      </h2>
                      {da.body ? (
                        <>
                          <h3
                            className="TeamMembers_joptitle"
                            onClick={() => Person_details(da.id)}
                          >
                            {i18n.language === "en"
                              ? da.jopTitleEn
                              : da.jopTitle}
                          </h3>
                          <p
                            className={
                              +Number(selectedperson) ===
                              Number(da.id.toString())
                                ? "clickedJop TeamMembers_details"
                                : " TeamMembers_details"
                            }
                          >
                            {da.body}
                          </p>
                        </>
                      ) : (
                        <h3 className="TeamMembers_joptitle-Without-body">
                          {i18n.language === "en" ? da.jopTitleEn : da.jopTitle}
                        </h3>
                      )}
                    </div>

                    <div className="TeamMembers_contactsContainerem  ">
                      {da.facebook ? (
                        <a href={da.facebook}>
                          <SiFacebook className="TeamMembers_icon" />
                        </a>
                      ) : null}
                      {da.google ? (
                        <a href={"mailto:" + da.google}>
                          <SiGoogle className="TeamMembers_icon" />
                        </a>
                      ) : null}
                      {da.twitter ? (
                        <a href={da.twitter}>
                          <SiTwitter className="TeamMembers_icon" />
                        </a>
                      ) : null}
                      {da.linkedin ? (
                        <a href={da.linkedin}>
                          <SiLinkedin className="TeamMembers_icon" />
                        </a>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};

export default TeamMembers;
