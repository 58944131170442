import { REQUEST } from "../../../Services/callAPI";
export const GET_HOME_NEWS = async (e) => {
  return await REQUEST({
    method: "get",
    url: "Post/HomeShow/" + e,
  });
};
export const UPDATE_HOME_POST = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Post/update-state",
    data: e,
  });
};
export const GET_PROGRAMS_DEFINITION = async () => {
  return await REQUEST({
    method: "get",
    url: "ProgramDefinition",
  });
};
export const UPDATE_PROGRAMS_DEFINITION = async (e) => {
  return await REQUEST({
    method: "put",
    url: "ProgramDefinition",
    data: e,
  });
};
export const DELETE_PROGRAMS_DEFINITION = async (e) => {
  return await REQUEST({
    method: "delete",
    url: "ProgramDefinition/" + e,
  });
};
export const DELETE_PROGRAMS_DEFINITION_LIST = async (e) => {
  return await REQUEST({
    method: "delete",
    url: "ProgramDefinitionsList/" + e,
  });
};
export const INSERT_PROGRAMS_DEFINITION = async (e) => {
  return await REQUEST({
    method: "post",
    url: "ProgramDefinition",
    data: e,
  });
};
export const GET_PARTENERS = async () => {
  return await REQUEST({
    method: "get",
    url: "Partener",
  });
};
export const UPDATE_PARTENERS = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Partener",
    data: e,
  });
};
export const DELETE_PARTENERS = async (e) => {
  return await REQUEST({
    method: "delete",
    url: "Partener/" + e,
  });
};
export const INSERT_PARTENERS = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Partener",
    data: e,
  });
};
