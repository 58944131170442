import { useCallback, useEffect, useState } from "react";
import { InputType } from "../../../Enums";
import { useHistory } from "react-router-dom";
import Input from "../../../SharedComponents/Input/Input";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import { CHECK_EMAIL, CHECK_USERNAME, REGISTIRATION } from "./API.Register";
import "./Register.css";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";
function Register() {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  let [values, setvalues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    confirmpassword: "",
  });

  let [error, seterror] = useState({
    email: "",
    username: "",
    phoneNumber: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  const CheckEmail = useCallback(
    async (e) => {
      if (!validateEmail(values.email)) {
        seterror({ ...error, email: t("mail is not valid") });
        return;
      }
      await CHECK_EMAIL(values)
        .then((res: any) => {
          seterror({ ...error, email: "" });
        })
        .catch(() => {
          seterror({ ...error, email: t("e-mail has been taken") });
        });
    },
    [values, error, t]
  );
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const CheckUsername = useCallback(
    async (e) => {
      await CHECK_USERNAME(values)
        .then((res: any) => {
          seterror({ ...error, username: "" });
        })
        .catch(() => {
          console.log(values.username);
          seterror({
            ...error,
            username: values.username.includes(" ")
              ? t("Invalid username")
              : t("username has been taken"),
          });
        });
    },
    [values, error, t]
  );
  const CheckPhoneNumber = useCallback(
    async (e) => {
      var Regex = /\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m;
      seterror({
        ...error,
        phoneNumber: Regex.test(values.phoneNumber)
          ? ""
          : t("Invalid phone number"),
      });
    },
    [values, error, t]
  );
  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!(values.password === values.confirmpassword)) {
        notify(
          { message: t("Password Does not match"), width: 600 },
          "error",
          3000
        );
        return;
      }
      if (!values.username || !values.email) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      if (error.username || error.email) {
        notify(
          { message: t("check your email or username"), width: 600 },
          "error",
          3000
        );
        return;
      }
      await REGISTIRATION(values)
        .then((res: any) => {
          notify(
            {
              message: t(
                "Account Created successfully. now you must confirm your e-mail"
              ),
              width: 900,
            },
            "success",
            6000
          );
          history.push("/log-in");
        })
        .catch(() => {
          notify(
            {
              message: t("check your username and password"),
              width: 600,
            },
            "error",
            3000
          );
        });
    },
    [values, t, history, error]
  );
  return (
    <div className="no__background">
      <Navbar />
      <div className="absolute__from__nav colorGrey">
        <main>
          <div
            className="registerWrapper"
            style={{
              direction: i18n.language === "en" ? "ltr" : "rtl",
            }}
          >
            <div className="registerContainer">
              <form
                onSubmit={submit}
                className="registerWidnow"
                style={{
                  direction: i18n.language === "en" ? "ltr" : "rtl",
                }}
              >
                <div className="registerText">{t("CREATE AN ACCOUNT")}</div>

                {/* <div className="logoContainer"></div> */}
                <div className=" registerInputsContainer">
                  <div className="">
                    <Input
                      label={t("First Name")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.firstname}
                      name="firstname"
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("Last Name")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.lastname}
                      name="lastname"
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("Phone Number")}
                      isRequired={true}
                      type={InputType.number}
                      onChange={HandleChange}
                      value={values.phoneNumber}
                      name="phoneNumber"
                      error={error.phoneNumber}
                      onBlur={CheckPhoneNumber}
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("E-mail")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.email}
                      name="email"
                      error={error.email}
                      onBlur={CheckEmail}
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("Username")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.username}
                      name="username"
                      error={error.username}
                      onBlur={CheckUsername}
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("Password")}
                      isRequired={true}
                      type={InputType.password}
                      onChange={HandleChange}
                      value={values.password}
                      name="password"
                    />
                  </div>
                  <div className="">
                    <Input
                      label={t("Confirm Password")}
                      isRequired={true}
                      type={InputType.password}
                      onChange={HandleChange}
                      value={values.confirmpassword}
                      name="confirmpassword"
                    />
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button type={"submit"} className="btn registerButton">
                    {t("CREATE AN ACCOUNT")}
                  </button>
                </div>
              </form>

              {/* <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckIndeterminate"
            />

            <div className="checkBoxContainer">
              <label
                className="form-check-label"
                htmlFor="flexCheckIndeterminate"
              >
                Receive Medad Last News and Media
              </label>
            </div>
          </div> */}
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default Register;
