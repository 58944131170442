import { useCallback, useRef, useState, useEffect } from "react";

import {
  Card,
  FormGroup,
  CardBody,
  CardHeader,
  Button,
  TabPane,
} from "reactstrap";
import { apiEndPoint } from "../../../../../Services/config.json";

import InputTwoLanguages from "../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import {
  DELETE_PROGRAMS_DEFINITION,
  GET_PROGRAMS_DEFINITION,
  INSERT_PROGRAMS_DEFINITION,
  UPDATE_PROGRAMS_DEFINITION,
} from "../../Admin.Home.APi";
import notify from "devextreme/ui/notify";
import UploadImageButton from "../../../../../SharedComponents/UploadImageButton/UploadImageButton";
import Technologies from "./ProgramDefinitionsList";
import TextEditorTwoLanguages from "../../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import {
  TechnologyInterface,
  TechnologyListInterface,
} from "../../../../../Interfaces/Interfaces";
import { useTranslation } from "react-i18next";
import ButtonsComponent from "../../../../../SharedComponents/Loaders/ButtonsComponent";

import ProgramDefinitionsFormList from "./ProgramDefinitionsFormList";
import ProgramDefinitionsList from "./ProgramDefinitionsList";

const ProgramDefinitions = () => {
  const { t, i18n } = useTranslation();
  let defaultdata = useRef<TechnologyInterface>({
    id: 0,
    imagePath: "",
    title: "",
    titleEn: "",
    subTitleEn: "",
    subTitle: "",
    description: "",
    descriptionEn: "",
    image: [],
    programDefinitionList: [],
  });
  let defaultdataList = useRef<TechnologyListInterface>({
    id: 0,
    image: "",
    imagePath: "",
    titleEn: "",
    title: "",
    link: "",
  });
  let counter = useRef<number>(0);
  let [Sevice, setServices] = useState<TechnologyInterface>(
    defaultdata.current
  );
  let [Data, setData] = useState<TechnologyInterface[]>([]);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    let fetch = async () => {
      let x: any = await GET_PROGRAMS_DEFINITION();

      setData(
        x.map((da) => {
          return {
            ...da,
            image: [apiEndPoint + da.imagePath, apiEndPoint + da.imagePath2],
          };
        })
      );
    };
    fetch();
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);

  const HandleChangeDefinition = useCallback((value) => {
    setServices((prev) => ({
      ...prev,
      programDefinitionList: value,
    }));
  }, []);

  let Updata = useCallback((element) => {
    setServices({
      ...element,
    });
    setImages([element?.imagePath, element?.imagePath2]);
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_PROGRAMS_DEFINITION(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });
          setData(x);
          setServices(defaultdata.current);
          setImages([]);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback(() => {
    setServices({ ...defaultdata.current });
    setImages([]);
  }, []);

  let NewDefinion = useCallback(() => {
    if (!Sevice.programDefinitionList) {
      Sevice.programDefinitionList = [{ ...defaultdataList.current }];
    } else {
      counter.current -= 1;
      Sevice.programDefinitionList?.push({
        ...defaultdataList.current,
        id: counter.current,
      });
    }

    setServices((prev) => ({
      ...prev,
      programDefinitionList: Sevice.programDefinitionList,
    }));
  }, [Sevice]);
  let handleGetImages = async (event) => {
    let files: any = event.target.files;

    let dataxx = [...images, ...files];
    if (dataxx.length > 2) {
      dataxx = dataxx.reverse();
      dataxx = dataxx.slice(0, 2);
      Sevice.imagePath = dataxx[0];
      Sevice.imagePath2 = dataxx[1];
    } else if (images.length === 1) {
      Sevice.imagePath2 = files[0];
    } else {
      Sevice.imagePath = files[0];
      Sevice.imagePath2 = files[1];
    }

    setServices(Sevice);
    setImages(dataxx);
  };

  let handleRemoveImage = (element: FileList | string) => {
    if (element && (element as string).includes(apiEndPoint)) {
      element = element.toString().replace(apiEndPoint, "");
    }
    console.log(images.indexOf(element));
    if (images.indexOf(element) === 0) {
      Sevice.imagePath = "";
      if (images.length === 2) {
        Sevice.imagePath = images[1];
        Sevice.imagePath2 = "";
      }
    }
    if (images.indexOf(element) === 1) {
      Sevice.imagePath2 = "";
    }
    setServices(Sevice);
    setImages((prevState) => prevState.filter((ele) => ele !== element));
  };
  let handleRemoveAllImages = () => {
    setImages([]);
    Sevice.imagePath = "";
    Sevice.imagePath2 = "";
    setServices(Sevice);
  };
  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      if (images.length !== 2 || !Sevice.title || !Sevice.titleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      let formData = new FormData();
      let values = Sevice;
      for (let [key, value] of Object.entries(values)) {
        if (key !== "programDefinitionList" && key !== "image") {
          formData.append(key.toString(), value);
        }
      }

      for (var i = 0; i < images.length; i++) {
        formData.append("image", images[i]);
      }

      formData.append(
        "programDefinitionList",
        JSON.stringify(
          values.programDefinitionList?.map((da) => {
            return {
              ...da,
              imagePath: "",
            };
          })
        )
      );

      if (Sevice.id > 0) {
        await UPDATE_PROGRAMS_DEFINITION(formData)
          .then((res: any) => {
            Updata(res);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_PROGRAMS_DEFINITION(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            Updata(res);
            //  setServices(defaultdata.current);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, images, Updata, t]
  );

  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <h4> {t("Programs Definitions")}</h4>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={submit}
            className="row"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <div className="col-lg-8">
              <div>
                <FormGroup>
                  <InputTwoLanguages
                    id="title"
                    label={t("Title")}
                    onValueChange={HandleChange}
                    value={Sevice.title}
                    valueEn={Sevice.titleEn}
                  />
                </FormGroup>
                <FormGroup>
                  <InputTwoLanguages
                    id="subTitle"
                    label={t("Subtitle")}
                    onValueChange={HandleChange}
                    value={Sevice.subTitle}
                    valueEn={Sevice.subTitleEn}
                  />
                </FormGroup>

                <TabPane tabId="2">
                  <TextEditorTwoLanguages
                    id="description"
                    label={t("Description")}
                    value={Sevice.description}
                    valueEn={Sevice.descriptionEn}
                    onValueChange={HandleChange}
                  />
                </TabPane>
                <FormGroup className="image__button">
                  <label>{t("Image")}</label>
                  <UploadImageButton
                    isMultiple={true}
                    handleGetImages={handleGetImages}
                    handleRemoveImage={handleRemoveImage}
                    handleRemoveAllImages={handleRemoveAllImages}
                    imagesFiles={
                      images
                        ? images.map((da) => {
                            return typeof da === "string"
                              ? apiEndPoint + da
                              : da;
                          })
                        : []
                    }
                  />
                </FormGroup>
                <ProgramDefinitionsFormList
                  data={Sevice.programDefinitionList}
                  setdata={HandleChangeDefinition}
                />

                <Button
                  className="btn btn btn-success col-12"
                  onClick={NewDefinion}
                  //  disabled={
                  //    Sevice.programDefinitionList?.find((e) => e.id == 0)
                  //      ? true
                  //     : false
                  // }
                  // onClick={saveHandle}
                >
                  +
                </Button>
                <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
              </div>
            </div>
            <div className="col-lg-4 Aboutus-admin-service-list">
              <ProgramDefinitionsList
                serlist={Data}
                Update={Updata}
                Delete={Delete}
              />
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default ProgramDefinitions;
