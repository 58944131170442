import { useCallback, useState, useEffect } from "react";
import Navbar from "../../../SharedComponents/Navbar/Navbar";

import { GET_POST } from "./News.Api";
import { apiEndPoint } from "../../../Services/config.json";
import "./style.news.css";
import ReactHtmlParser from "react-html-parser";

import { useTranslation } from "react-i18next";
import {
  FeaturedPostInterface,
  PostInterface,
} from "../../../Interfaces/Interfaces";
import { DateConvertor } from "../../../SharedComponents/Loaders/DateFunction";
import { GET_POST_FEATURED } from "../admin/Admin.News.Api";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";
const News = () => {
  const { t, i18n } = useTranslation();

  let [Posts, setPosts] = useState<PostInterface[]>([]);
  let [selectedPost, setselectedPost] = useState<number>(0);
  let [FeaturedPosts, setFeaturedPosts] = useState<FeaturedPostInterface[]>([]);
  let [Current, setCurrent] = useState<number>(0);
  let [TotalCount, setTotalCount] = useState<number>(0);
  let fetch = async () => {
    let x: any = await GET_POST(0);
    setCurrent(x.currentCount);
    setTotalCount(x.totalCount);
    setPosts(x.data);
    let y: any = await GET_POST_FEATURED();
    setFeaturedPosts(y);
  };
  useEffect(() => {
    fetch();

    window.scrollTo(0, 0);
  }, []);
  const Person_details = useCallback(
    (e: number) => {
      if (Number(selectedPost) === Number(e)) {
        setselectedPost(0);
      } else {
        setselectedPost(e);
      }
    },
    [selectedPost]
  );
  return (
    <>
      <div className="no__background">
        <Navbar />
        <main>
          <div
            className="mainbody"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <div className="row">
              <div className="col-lg-8">
                <h1 className="LatestPostTitle">{t("Latest Posts")}</h1>

                <>
                  {Posts.map((data, index) => {
                    return (
                      <div key={index} className="row ">
                        <div className="col-12 titleLatestadminForm outer_text_title_News">
                          {i18n.language === "en" ? data.titleEn : data.title}
                        </div>
                        <div className="imageLatest col-lg-4">
                          <img
                            alt={"1"}
                            src={
                              typeof data.imagePath === "string"
                                ? `${apiEndPoint}${encodeURI(
                                    data.imagePath.replaceAll("\\", "/")
                                  )}`
                                : ""
                            }
                            className="image"
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12">
                          <div className=" titleLatest inner_text_title_News">
                            {i18n.language === "en" ? data.titleEn : data.title}
                          </div>
                          <div className="bodyLatest">
                            {" "}
                            <p
                              style={{
                                display:
                                  Number(selectedPost) ===
                                  Number(data.id.toString())
                                    ? "none"
                                    : " ",
                              }}
                            >
                              {ReactHtmlParser(
                                i18n.language === "en"
                                  ? data.descriptionEn.length > 200
                                    ? data.descriptionEn.split("</p>")[0]
                                    : data.descriptionEn
                                  : data.description.length > 200
                                  ? data.description.split("</p>")[0]
                                  : data.description
                              )}
                            </p>
                            <p
                              className={
                                Number(selectedPost) ===
                                Number(data.id.toString())
                                  ? "clickedJop TeamMembers_details"
                                  : " TeamMembers_details"
                              }
                            >
                              {ReactHtmlParser(
                                i18n.language === "en"
                                  ? data.descriptionEn
                                  : data.description
                              )}
                            </p>
                            <p
                              className="read-more-text-news"
                              onClick={() => Person_details(data.id)}
                            >
                              {Number(selectedPost) ===
                              Number(data.id.toString())
                                ? t("Read Less...")
                                : t("Read More...")}
                            </p>
                          </div>
                          <div className=" dataForm">
                            {DateConvertor(
                              data.postDate,
                              i18n.language === "en"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
                <div className="morePosts">
                  {TotalCount !== Current ? (
                    <button
                      type="button"
                      onClick={async () => {
                        let x: any = await GET_POST(Current);
                        setCurrent(x.currentCount);
                        setTotalCount(x.totalCount);
                        setPosts(x.data);
                      }}
                      className="btn btn-outline-dark "
                    >
                      {t("More Posts")}
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4">
                <div style={{ padding: "42px 0 " }}>
                  <h2 className="FeaturedPostsTitle">{t("FEATURED POSTS")}</h2>

                  <>
                    {FeaturedPosts.map((data) => {
                      return (
                        <div>
                          <div
                            className=" FeaturesTitle"
                            style={{ fontSize: "30px" }}
                          >
                            {" "}
                            {i18n.language === "en" ? data.titleEn : data.title}
                          </div>

                          <div className="dataForm">
                            {DateConvertor(
                              data.postDate,
                              i18n.language === "en"
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default News;
