import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  defualtParagraphSectionInterface,
  ParagraphSectionInterface,
} from "../../../Interfaces/Interfaces";
import { GET_SECTION } from "../../12.TeamMembers/admin/Admin.Team.Api";
import Header1 from "./Components/Header";
import Header2 from "./Components/Header2";

const AdminDemo = () => {
  let [paragraph, setparagraph] = useState<ParagraphSectionInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_SECTION("Demos");
    setparagraph(x);
  };
  const { i18n } = useTranslation();
  useEffect(() => {
    fetch();
  }, []);
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Header1
        dataTitle={
          paragraph.find((x) => x.index === 0)
            ? paragraph.find((x) => x.index === 0)
            : defualtParagraphSectionInterface
        }
      />
      <Header2
        dataTitle={
          paragraph.find((x) => x.index === 1)
            ? paragraph.find((x) => x.index === 1)
            : defualtParagraphSectionInterface
        }
      />
    </div>
  );
};

export default AdminDemo;
