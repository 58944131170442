import React, { useEffect, useState } from "react";
import {
  defualtParagraphSectionInterface,
  ParagraphSectionInterface,
} from "../../../../../Interfaces/Interfaces";

import "./GetStarted.css";
import { useTranslation } from "react-i18next";
import { GET_SECTION } from "../../../../12.TeamMembers/admin/Admin.Team.Api";
function GetStarted() {
  const { i18n } = useTranslation();

  let [paragraph, setparagraph] = useState<ParagraphSectionInterface[]>([
    defualtParagraphSectionInterface,
    defualtParagraphSectionInterface,
  ]);
  let fetch = async () => {
    let x: any = await GET_SECTION("Demos");
    setparagraph(x);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="getStartedFreeTrialWrapper ">
      <div className="container getStartedFreeTrialContainer w-100">
        <div className="row  m-0">
          <div className="col-12 col-md-6 getStartedContainer">
            <div className="getStartedTitle">
              {" "}
              {i18n.language === "en"
                ? paragraph[0].titleEn
                : paragraph[0].title}
            </div>
            <div className="getStartedSubTitle">
              {i18n.language === "en"
                ? paragraph[0].descriptionEn
                : paragraph[0].description}
            </div>
          </div>
          <div className="col-12 col-md-6 freeTrialWrapper">
            <div className="freeTrialContainer">
              <div className="freeTrialTitle">
                {" "}
                {i18n.language === "en"
                  ? paragraph[1].titleEn
                  : paragraph[1].title}
              </div>
              <div className="freeTrialSubTitle">
                {i18n.language === "en"
                  ? paragraph[1].descriptionEn
                  : paragraph[1].description}
              </div>
              {/*       <button className="freeTrialButton btn btn-lg">
                REQUEST DEMO
  </button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
