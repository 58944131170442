import { useState, useCallback, useEffect, useRef } from "react";
import { Card, FormGroup, Input, CardBody, CardHeader } from "reactstrap";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton, {
  HTMLInputEvent,
} from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { apiEndPoint } from "../../../../Services/config.json";
import "../Aboutus.css";
import ServicesList from "./ServicesList";
import {
  DELETE_SUPPORT,
  GET_SUPPORT,
  INSERT_SUPPORT,
  UPDATE_SUPPORT,
} from "../Admin.Aboutus.APi";
import notify from "devextreme/ui/notify";
import { SupportInterface } from "../../../../Interfaces/Interfaces";
import { useTranslation } from "react-i18next";
import ButtonsComponent from "../../../../SharedComponents/Loaders/ButtonsComponent";
const Services = () => {
  const { t, i18n } = useTranslation();
  let defaultdata = useRef<SupportInterface>({
    id: 0,
    imagePath: "",
    phoneNumber: "",
    title: "",
    titleEn: "",
    body: "",
    bodyEn: "",
    link: "",
    image: "",
  });
  let [Sevice, setServices] = useState<SupportInterface>(defaultdata.current);
  let [Data, setData] = useState<SupportInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_SUPPORT();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_SUPPORT(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });
          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback((element) => {
    setServices({ ...defaultdata.current });
  }, []);
  let handleGetImages = (event: HTMLInputEvent) => {
    let files: any = event.target.files;
    setServices({ ...Sevice, image: files[0] });
  };

  let handleRemoveImage = useCallback(
    (element) => {
      setServices({ ...Sevice, image: "" });
    },
    [Sevice]
  );

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!Sevice.image || !Sevice.title || !Sevice.titleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }

      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }
      Sevice.body = !Sevice.body ? " " : Sevice.body;
      Sevice.bodyEn = !Sevice.bodyEn ? " " : Sevice.bodyEn;
      Sevice.link = !Sevice.link ? " " : Sevice.link;
      Sevice.phoneNumber = !Sevice.phoneNumber ? " " : Sevice.phoneNumber;
      if (Sevice.id > 0) {
        await UPDATE_SUPPORT(formData)
          .then((res: any) => {
            setServices({ ...res, image: res.imagePath });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res, image: res.imagePath };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_SUPPORT(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            setServices(defaultdata.current);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <Card className="card-user">
      <CardHeader>
        <h4> {t("Support")}</h4>
      </CardHeader>
      <CardBody>
        <form
          className="row"
          onSubmit={submit}
          style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
        >
          <div className="col-lg-8">
            <div>
              <FormGroup>
                <InputTwoLanguages
                  id="title"
                  label={t("Title")}
                  onValueChange={HandleChange}
                  value={Sevice.title}
                  valueEn={Sevice.titleEn}
                />
              </FormGroup>
              <FormGroup>
                <InputTwoLanguages
                  id="body"
                  label={t("Description")}
                  onValueChange={HandleChange}
                  value={Sevice.body}
                  valueEn={Sevice.bodyEn}
                />
              </FormGroup>
              <FormGroup>
                <label>{t("E-mail")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"link"}
                  value={Sevice.link}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="email"
                />
              </FormGroup>
              <FormGroup>
                <label>{t("Phone Number")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"phoneNumber"}
                  value={Sevice.phoneNumber}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="text"
                />
              </FormGroup>

              <FormGroup className="image__button">
                <label>{t("Image")}</label>
                <UploadImageButton
                  isMultiple={false}
                  handleGetImages={handleGetImages}
                  handleRemoveImage={handleRemoveImage}
                  imagesFiles={
                    Sevice.image
                      ? [
                          typeof Sevice.image === "string"
                            ? apiEndPoint + encodeURI(Sevice.image)
                            : Sevice.image,
                        ]
                      : []
                  }
                />
              </FormGroup>
              <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
            </div>
          </div>
          <div className="col-lg-4 Aboutus-admin-service-list">
            <ServicesList serlist={Data} Update={Updata} Delete={Delete} />
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default Services;
