import { SelectBox } from "devextreme-react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../SharedComponents/Navbar/Navbar";
import Footer from "../1.Home/user/Components/Footer/user/Footer";
import "./cart.css";
import { Button } from "devextreme-react/button";
import { useHistory } from "react-router-dom";
import { REQUEST } from "../../Services/callAPI";

const Cart = () => {
	const { t, i18n } = useTranslation();

	const history = useHistory();

	const [selectedPeriod, setSelectedPeriod] = useState("YearPrice");

	const [cart, setCart] = useState({});

	const [loading, setLoading] = useState(false);

	const iconsClass = {
		Gold: "fa-solid fa-file-invoice fa-2xl text-gold my-2",
		Silver: "fa-solid fa-receipt fa-2xl text-secondary my-2",
		Bronze: "fa-solid fa-file-invoice-dollar fa-2xl text-bronze my-2",
	};

	const fontClass = {
		Gold: "text-gold",
		Silver: "text-secondary",
		Bronze: "text-bronze",
	};

	const periodsObj = {
		YearPrice: "One Year",
		HalfYearPrice: "Six Months",
		QuarterYearPrice: "Three Months",
	};

	const periods = [
		{ id: "YearPrice", value: "One Year" },
		{ id: "HalfYearPrice", value: "Six Months" },
		{ id: "QuarterYearPrice", value: "Three Months" },
	];

	useEffect(() => {
		if (!localStorage.getItem("user")) {
			localStorage.setItem(
				"lastpage",
				JSON.stringify({ url: window.location.href })
			);
			history.push("/log-in");
		}

		let _cart = JSON.parse(localStorage.getItem("cart")) || {};
		setCart(_cart);
	}, []);

	const freeCart = useCallback(() => {
		localStorage.removeItem("cart");
		setCart({});
	}, []);

	// const handleCheckout = useCallback(() => {
	// 	console.log(cart);

	// 	const config = {
	// 		method: "GET",
	// 		url: `PaymobPayment/${cart.Id}/${selectedPeriod}`,
	// 	};

	// 	REQUEST(config).then((response) => {
	// 		localStorage.removeItem("cart");
	// 		window.location.replace(response);
	// 	});
	// }, [cart, selectedPeriod]);

	const handleCheckout = useCallback(() => {
		console.log(cart);
		setLoading(true);

		const config = {
			method: "Post",
			url: `Orders/InsertOrder`,
			data: {
				methodId: cart.Id,
				period: selectedPeriod,
			},
		};

		REQUEST(config)
			.then((response) => {
				console.log(response);
			})
			.finally(() => {
				setLoading(false);
			});

		// REQUEST(config).then((response) => {
		// 	localStorage.removeItem("cart");
		// 	window.location.replace(response);
		// });
	}, [cart, selectedPeriod]);

	return (
		<>
			<div className="no__background">
				<Navbar />

				<div className="absolute__from__nav colorGrey ">
					<main>
						<div className="cart__wrapper">
							<div className="container">
								<h1 className={"cart__title"}>
									Your Shopping Cart
								</h1>
								<div class="CartContactInfo">
									If you have any questions or require
									assistance, call us at{" "}
									<a href="tel:01201088886">01201088886</a>{" "}
									between <br />
									7:30am and 4:30pm Pacific Time or email{" "}
									<a href="mailto:info@almedadsoft.com">
										info@almedadsoft.com
									</a>
								</div>
								<div className="cart justify-content-center">
									{!cart?.PriceCategoryTitle ? (
										<div className="empty__cart">
											<h2>
												Your shopping cart is empty.
											</h2>
										</div>
									) : (
										<>
											<div className="row d-flex p-5 justify-content-between align-items-between w-100 border-bottom">
												<div className="col-lg-6 col-md-12 py-lg-0 py-md-5 text-gold">
													<div className="d-flex justify-content-center align-items-center left__section">
														<div className="d-flex flex-column justify-content-center align-items-center">
															<i
																className={
																	iconsClass[
																		cart
																			.PriceCategoryTitle
																	]
																}
															></i>
															<span
																className={`cell__title mb-2 ${
																	fontClass[
																		cart
																			.PriceCategoryTitle
																	]
																}`}
															>
																{t(
																	cart.PriceCategoryTitle
																)}
															</span>
														</div>
														<div className="Group">
															<span
																className={`cart__product__title ${
																	fontClass[
																		cart
																			.PriceCategoryTitle
																	]
																}`}
															>
																{
																	cart.ProductNameEn
																}
															</span>
															<span className="Action">
																<div
																	onClick={
																		freeCart
																	}
																>
																	Delete
																</div>
															</span>
														</div>
													</div>
												</div>
												<div className="col-lg-6 col-md-12">
													<div className="d-flex justify-content-center align-items-center">
														<span className="price d-flex justify-content-center align-items-center">
															$
															{
																cart[
																	selectedPeriod
																]
															}{" "}
															<span className="sub__string">
																/{" "}
																{
																	periodsObj[
																		selectedPeriod
																	]
																}
															</span>{" "}
															<i class="fa-solid fa-xmark"></i>
														</span>
														<SelectBox
															id="period"
															items={periods}
															displayExpr={
																"value"
															}
															valueExpr={"id"}
															value={
																selectedPeriod
															}
															onValueChange={(
																e
															) =>
																setSelectedPeriod(
																	e
																)
															}
														/>
													</div>
												</div>
											</div>
											<div className="total__price">
												<div className="row h-100 p-5 justify-content-center align-items-center">
													<div className="col-lg-8 col-md-12">
														<span>
															This is your
															sub-total. To
															calculate your final
															sales price with
															sales tax due (for
															U.S. residents
															only), please
															proceed to checkout.
														</span>
													</div>
													<div className="col-lg-4 col-md-12">
														<div class="TotalContent d-flex flex-column text-end">
															<span class="Label">
																ORDER SUBTOTAL
															</span>
															<span className="total__number justify-content-end">
																$
																{
																	cart[
																		selectedPeriod
																	]
																}
															</span>
															<div
																class="hidden"
																id="orderId"
															></div>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
								<div className="row d-flex  p-2 justify-content-end mt-3">
									<div className="col-4">
										<Button
											className="w-100 py-2 px-3 checkout__button"
											text="Checkout"
											type="default"
											stylingMode="outlined"
											disabled={
												!cart?.PriceCategoryTitle ||
												loading
											}
											onClick={handleCheckout}
										>
											<span className="dx-button-text">
												{loading ? (
													<LoadIndicator
														width={"24px"}
														height={"24px"}
														visible={true}
													/>
												) : (
													"Checkout"
												)}
											</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
						<Footer />
					</main>
				</div>
			</div>
		</>
	);
};

export default Cart;
