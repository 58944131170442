import React, { useCallback, useState } from "react";
// javascript plugin used to create scrollbars on windows
import { Switch, useLocation, useHistory, Route } from "react-router-dom";

import AdminNavbar from "../SharedComponents/AdminPanel/Navbars/AdminNavbar";
import Sidebar from "../SharedComponents/AdminPanel/Sidebar/Sidebar";

import { routes } from "../SharedComponents/AdminPanel/Sidebar/routes";
import { SupperAdminRoutes } from "../SharedComponents/AdminPanel/Sidebar/SupperAdminRoutes";

import axios from "axios";
import { apiEndPoint } from "../Services/config.json";
// import "../css/paper-dashboard.css";

interface Props {}

const Dashboard: React.FC<Props & Record<string, any>> = (props) => {
  require("../css/paper-dashboard.css");

  const mainPanel = React.useRef<HTMLDivElement>(null);

  let history = useHistory();
  const [Auth, setAuth] = useState<any>("");
  React.useEffect(() => {
    mainPanel.current && (mainPanel.current.scrollTop = 0);
    document.scrollingElement && (document.scrollingElement.scrollTop = 0);

    let INFI = async () => {
      let x = await checkusertype();
      setAuth(x ? x : "");
    };
    INFI();
  }, []);

  let checkusertype: any = useCallback(async function () {
    let item = JSON.parse(localStorage.getItem("user") || "{}");

    !item || !item.type
      ? history.push("/")
      : await axios
          .get(apiEndPoint + "/api/check-type", {
            headers: {
              ...axios.defaults.headers,
              Authorization: `bearer ${
                JSON.parse(localStorage.getItem("user") || "{}").token
              }`,
            },
          })
          .then((res) => {
            return res[0] ? res[0] : "";
          })
          .catch((err) => {
            history.push("/log-in");
            localStorage.removeItem("user");
            return "";
          });
    return item?.type ? item?.type[0] : "";
  }, []);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={Auth === "Admin" ? routes : SupperAdminRoutes}
        bgColor={"black"}
        activeColor={"primary"}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} />

        <Switch>
          <Route
            path={"/admin"}
            exact
            component={() => {
              return <></>;
            }}
          />

          {Auth === "Admin"
            ? routes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    exact
                    key={key}
                  />
                );
              })
            : SupperAdminRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    exact
                    key={key}
                  />
                );
              })}
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
