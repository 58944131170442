import React, { useState } from "react";
import { Button } from "reactstrap";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useTranslation } from "react-i18next";
interface props {
  Delete?: any;
  buttondisable?: any;
  onsubmit: any;
}
const ButtonsSaveDelete = ({ Delete, buttondisable, onsubmit }: props) => {
  let [loadIndicatorVisible, setloadIndicatorVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col">
          <Button
            className="btn btn btn-success col-12"
            // disabled={!isValueChanged}
            disabled={loadIndicatorVisible}
            onClick={async () => {
              setloadIndicatorVisible(true);
              await onsubmit();
              setloadIndicatorVisible(false);
            }}
          >
            <span className="dx-button-text">
              <LoadIndicator
                className="button-indicator"
                visible={loadIndicatorVisible}
              />
            </span>

            <span className="dx-button-text">
              {loadIndicatorVisible ? t("Loading") : t("Save")}
            </span>
          </Button>
        </div>
        <div className="col">
          <Button
            className="btn btn btn-success col-12"
            onClick={Delete}
            disabled={buttondisable}
            // onClick={saveHandle}
          >
            {t("Delete")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ButtonsSaveDelete;
