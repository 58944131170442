import React from "react";
import {
  Column,
  DataGrid,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  RowDragging,
} from "devextreme-react/data-grid";
import { apiEndPoint } from "../../../../Services/config.json";
import { createStore } from "devextreme-aspnet-data-nojquery";
import notify from "devextreme/ui/notify";

const TableWithCheckBoxs = ({
  colAttributes,
  ProductPriceId,
  importFromOtherProduct,
}) => {
  const dataSource = createStore({
    key: "Id",
    loadUrl: `${apiEndPoint}/Api/ProductOption/${ProductPriceId}`,
    insertUrl: `${apiEndPoint}/Api/ProductOption/Insert/${ProductPriceId}`,
    updateUrl: `${apiEndPoint}/Api/ProductOption/Update`,
    deleteUrl: `${apiEndPoint}/Api/ProductOption/Delete`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        Authorization: `bearer ${
          JSON.parse(localStorage.getItem("user") || "{}").token
        }`,
      };
    },
  });

  const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;

    // Adds a new item
    toolbarItems.push({
      widget: "dxButton",
      options: {
        icon: "movetofolder",
        hint: "Import",
        onClick: function () {
          importFromOtherProduct();
        },
      },
      location: "after",
    });
  };

  let onReorder = (e) => {
    e.promise = processReorder(e);
  };

  let processReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.Rank;
    await dataSource.update(e.itemData.Id, { Rank: newOrderIndex });
    await e.component.refresh();
  };

  return (
    <>
      <DataGrid
        id="gridContainer"
        remoteOperations={ProductPriceId}
        repaintChangesOnly={true}
        showRowLines={true}
        hoverStateEnabled={true}
        dataSource={ProductPriceId ? dataSource : []}
        onToolbarPreparing={onToolbarPreparing}
        rtlEnabled={true}
        showBorders={true}
        allowColumnResizing={true}
        disabled={!ProductPriceId}
      >
        <RowDragging
          allowReordering={true}
          onReorder={onReorder}
          dropFeedbackMode="push"
        />
        <Scrolling mode="virtual" />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} />
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          startEditAction={"click"}
        ></Editing>
        {colAttributes.map((attr, index) => {
          return (
            <Column
              allowEditing={true}
              key={index + 1}
              name={index}
              dataField={attr[0]}
              headerCellRender={() => (
                <>
                  <i className={attr[2]} />
                  <i className="px-1 dx-datagrid-text-content">{attr[1]}</i>
                </>
              )}
              dataType={index > 1 ? "boolean" : "string"}
              width={index > 1 ? "100px" : null}
            />
          );
        })}
      </DataGrid>
    </>
  );
};

export default React.memo(TableWithCheckBoxs);
