import axios, { AxiosRequestConfig } from "axios";

import { apiEndPoint } from "./config.json";
import { DomainValue } from "./DomainValue";

let REQUEST = async (config: AxiosRequestConfig) => {
  config.url = `${apiEndPoint}/Api/${config.url}`;
  const item = localStorage.getItem("user");
  if (item != null) {
    const itemx = JSON.parse(item);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      Authorization: `bearer ${itemx.token}`,
    };
  }

  axios.defaults.headers = {
    ...axios.defaults.headers,
    Domain: DomainValue(),
    "Access-Control-Allow-Origin": "*",
  };

  let data = await axios(config)
    .then((response) => {
      return JSON.stringify(response.data);
    })
    .catch(function (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem("user");
        window.location.reload();
      }
      throw error;
    });

  data = JSON.parse(data);

  return data;
};

export { REQUEST };
