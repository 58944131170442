import React from "react";
import "../Aboutus.css";

import { apiEndPoint } from "../../../../Services/config.json";
import { useTranslation } from "react-i18next";
import UpdateDelete from "../../../../SharedComponents/Loaders/UpdateDelete";
const ServicesList = ({ serlist, Update, Delete }) => {
  const { i18n } = useTranslation();
  return (
    <div id="mybodyService">
      {serlist.map((data, index) => {
        return (
          <div key={index} className="about-us-service-list-card">
            <div className="row">
              <div
                className=" col-md-4 col-lg-3 col-sm-6"
                style={{
                  minWidth: "70px",
                  minHeight: "50px",
                  maxWidth: "100px",
                }}
              >
                <img
                  alt="1"
                  src={
                    typeof data.imagePath == "string"
                      ? `${apiEndPoint}${encodeURI(
                          data.imagePath.replaceAll("\\", "/")
                        )}`
                      : ""
                  }
                  className="Enterprise-st0"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className=" col-md-8 col-lg-9 col-sm-6 about-us-service-list-card-text">
                <b>{i18n.language === "en" ? data.titleEn : data.title}</b>
                <br />
                <b> {i18n.language === "en" ? data.bodyEn : data.body}</b>
                <UpdateDelete
                  data={data}
                  Updata={Update}
                  Delete={Delete}
                  ComponentID="#mybodyService"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
/**
 * 
 *                 <br />
                <b>link text : </b>
                {data.linktext.text}
                <br />
                <b>link text english: </b> {data.linktext.textEn}
                <br />
                <b>normal: </b> {data.normaltext.text}
                <br />
                <b>normal english: </b> {data.normaltext.textEn}
                <br />
                <b>E-mail: </b>
                {data.mail}
                <br />
                <b>link: </b>
                {data.link}
                <br />
                <b>number: </b> {data.number}
                <br />
 */
export default ServicesList;
