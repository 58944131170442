import Joi from "joi";

export const validateForm = (data, schema) => {
  let formSchema = Joi.object().keys(schema).options({ allowUnknown: true });
  let errors = formSchema.validate(data, { abortEarly: false });
  let error = {};
  if (errors.error) {
    errors.error.details.forEach((err: any) => {
      let key = err.context.key;
      let message = err.message;

      error[key] = message;
    });
  }
  return error;
};
