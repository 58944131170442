import { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { REQUEST } from "../../../../Services/callAPI";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { Demo } from "../../Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";
import { Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";

interface Props {
  data: Demo | undefined;
  productId: number;
}

const DemoForm: React.FC<Props> = ({ productId, data }) => {
  const { t } = useTranslation();
  const [demoInitValues, setDemoInitValues] = useState<Demo>({
    ProductId: productId,
    Tip: "",
    TipEn: "",
    Description: "",
    DescriptionEn: "",
    Image: null,
  });
  const [demo, setDemo] = useState<Demo>({ ...demoInitValues });

  const [status, setStatus] = useState(Status.IDLE);

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setDemo({ ...data, Image: data.ImagePath });
      setDemoInitValues({ ...data, Image: data.ImagePath });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  let handleGetimages = async (event) => {
    let files = event.target.files;
    let image = files[0];
    setDemo((prevState) => {
      return { ...prevState, Image: image };
    });
  };

  let handleRemoveimage = (element: FileList | string) => {
    setDemo((prevState) => {
      return { ...prevState, Image: null };
    });
  };

  //
  const updateDemo = useCallback((value, id) => {
    setDemo((prev) => ({ ...prev, [id]: value }));
  }, []);

  const isNotValid = useMemo(() => {
    for (let key of Object.keys(demo)) {
      if (!demo[key]) {
        return true;
      }
    }

    if (demo.ProductId) {
      let result = true;
      for (let key of Object.keys(demo)) {
        if (demo[key] !== demoInitValues[key]) {
          result = false;
        }
      }
      if (
        demo.Image &&
        demoInitValues.Image &&
        demo.Image.name !== demoInitValues.Image.name &&
        demo.Image.size !== demoInitValues.Image.size
      ) {
        result = false;
      }
      return result;
    }

    return false;
  }, [demo, demoInitValues]);

  const demoValuesAsFormData = useMemo(() => {
    let formData = new FormData();
    formData.append("ProductId", productId.toString());
    for (let [key, value] of Object.entries(demo)) {
      formData.append(key.toString(), value);
    }
    return formData;
  }, [demo, productId]);

  // submit form
  const addHandle = useCallback(() => {
    let config: AxiosRequestConfig = {
      method: status === Status.ADD ? "post" : "PUT",
      url: "ProductDemos",
      data: demoValuesAsFormData,
    };

    REQUEST(config)
      .then((response: any) => {
        response.Image = `${apiEndPoint}${response.ImagePath}`;
        delete response.ImagePath;
        setDemoInitValues({ ...response });
        setDemo({ ...response });
        setStatus(Status.UPDATE);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [demoValuesAsFormData, status]);

  // cancel form
  const cancelForm = useCallback(() => {
    let config: AxiosRequestConfig = {
      method: "DELETE",
      url: `ProductDemos`,
      data: {
        ProductId: productId,
      },
    };

    REQUEST(config)
      .then((response) => {
        setDemoInitValues({
          ProductId: productId,
          Tip: "",
          TipEn: "",
          Description: "",
          DescriptionEn: "",
          Image: null,
        });
        setDemo({
          ProductId: productId,
          Tip: "",
          TipEn: "",
          Description: "",
          DescriptionEn: "",
          Image: null,
        });
        setStatus(Status.ADD);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productId]);

  return (
    <>
      <div className="mt-2">
        <TextEditorTwoLanguages
          id="Description"
          label={t("Demo description")}
          onValueChange={updateDemo}
          value={demo.Description}
          valueEn={demo.DescriptionEn}
        />
        <InputTwoLanguages
          id="Tip"
          label={t("Demo tip")}
          onValueChange={updateDemo}
          value={demo.Tip}
          valueEn={demo.TipEn}
        />
        <Col className="pr-1" md="12">
          <FormGroup className="image__button">
            <label>{t("Header image")}</label>
            <UploadImageButton
              isMultiple={false}
              handleGetImages={handleGetimages}
              handleRemoveImage={handleRemoveimage}
              imagesFiles={
                demo.Image
                  ? [
                      typeof demo.Image == "string"
                        ? apiEndPoint + demo.Image
                        : demo.Image,
                    ]
                  : []
              }
            />
          </FormGroup>
        </Col>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={status !== Status.UPDATE}
                onClick={cancelForm}
              >
                {t("Delete")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DemoForm;
