import Header from "./Components/Header";
import Map from "./Components/Map";
import "./AdminMap.css";
import { useTranslation } from "react-i18next";

const AdminMap = () => {
  const { i18n } = useTranslation();
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Header />
      <Map />
    </div>
  );
};

export default AdminMap;
