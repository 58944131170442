import React from "react";

import "./PageHeader.css";

interface Props {
  backgroundImage: string;
}

const PageHeader: React.FC<Props> = ({ backgroundImage, children }) => {
  return (
    <>
      <div
        className={"header__image"}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="BackgroundOverlay"></div>
        <div className="header__content">{children}</div>
      </div>
    </>
  );
};

export default PageHeader;
