import React, { useState, useCallback, useEffect } from "react";

import { Card, FormGroup, Input, CardBody, CardHeader } from "reactstrap";

import { useTranslation } from "react-i18next";

import {
  ParagraphSectionInterface,
  defualtParagraphSectionInterface,
} from "../../../../../Interfaces/Interfaces";
import SubmitButton from "../../../../../SharedComponents/Loaders/SubmitButton";
import notify from "devextreme/ui/notify";
import { UPDATE_SECTION } from "../../../../12.TeamMembers/admin/Admin.Team.Api";
interface Props {
  dataTitle: ParagraphSectionInterface | any;
}
const HomeProdct: React.FC<Props> = ({ dataTitle }) => {
  const { t } = useTranslation();
  useEffect(() => {
    setHead({ ...dataTitle, image: dataTitle.imagePath });
  }, [dataTitle]);

  let [Head, setHead] = useState<ParagraphSectionInterface>(
    defualtParagraphSectionInterface
  );
  const HandleChange = useCallback((value, id) => {
    setHead((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      let formData = new FormData();

      for (let [key, value] of Object.entries(Head)) {
        formData.append(key.toString(), value);
      }
      await UPDATE_SECTION(formData)
        .then((res: any) => {
          notify(
            { message: t("Saved Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Head, t]
  );
  return (
    <Card className="card-user">
      <CardHeader>
        <h4>{t("home page products")} </h4>
      </CardHeader>
      <CardBody>
        <form onSubmit={submit} className="row">
          <div className="col">
            <FormGroup>
              <label>{t("Skip")}</label>
              <Input
                style={{ fontFamily: "Tajawal", height: "40px" }}
                id={"description"}
                value={parseInt(Head.description)}
                onChange={(e) =>
                  HandleChange(e.target.value.toString(), e.target.id)
                }
                type="number"
              />
            </FormGroup>
          </div>
          <div className="col">
            <FormGroup>
              <label>{t("Take")}</label>
              <Input
                style={{ fontFamily: "Tajawal", height: "40px" }}
                id={"title"}
                value={parseInt(Head.title)}
                onChange={(e) =>
                  HandleChange(e.target.value.toString(), e.target.id)
                }
                type="number"
              />
            </FormGroup>
          </div>

          <SubmitButton />
        </form>
      </CardBody>
    </Card>
  );
};

export default HomeProdct;
