import React, { useCallback, useEffect, useRef, useState } from "react";

import { apiEndPoint } from "../../../../../Services/config.json";
import ReactHtmlParser from "react-html-parser";
import "./style.technologies.css";
import { TechnologyInterface } from "../../../../../Interfaces/Interfaces";
import { useTranslation } from "react-i18next";
import { GET_TECHNOLOGY } from "../../../admin/Admin.Aboutus.APi";
const Tecnologies = () => {
  const { i18n, t } = useTranslation();
  let [data, setData] = useState<TechnologyInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_TECHNOLOGY();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  let direction = useRef("rtl");
  let setdirection = useCallback(() => {
    if (direction.current === "ltr") {
      direction.current = "rtl";
      return "rtl";
    } else {
      direction.current = "ltr";
      return "ltr";
    }
  }, []);
  return (
    <div className="container">
      <div
        className="successParteners text__middle w-100 container mt-5"
        style={{ paddingBottom: "50px" }}
      >
        {t("About the company ")}
      </div>
      <div className="grid block-grid my-5">
        {data.map((da) => {
          return (
            <>
              <div className="row cellpartion" dir={setdirection()}>
                <div
                  className="col-12 col-sm-7 col-md-8 text-container"
                  style={{
                    direction: i18n.language === "en" ? "ltr" : "rtl",
                  }}
                >
                  <h1> {i18n.language === "en" ? da.titleEn : da.title}</h1>
                  <h2>
                    {i18n.language === "en" ? da.subTitleEn : da.subTitle}
                  </h2>
                  <p>
                    {ReactHtmlParser(
                      i18n.language === "en" ? da.descriptionEn : da.description
                    )}
                  </p>
                </div>
                <div className="col-0 col-sm-5 col-md-4 sys-h-align-right image-container">
                  <img
                    alt="1"
                    src={
                      typeof da.imagePath === "string"
                        ? `${apiEndPoint}${encodeURI(
                            da.imagePath.replaceAll("\\", "/")
                          )}`
                        : ""
                    }
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              </div>
              {da.title !== data[data.length - 1].title ? (
                <div className="col-12 H-Separator"></div>
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Tecnologies;
