import { useState, useCallback, useEffect } from "react";

import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Label,
} from "reactstrap";
import InputTwoLanguages from "../../../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import { useTranslation } from "react-i18next";

import SubmitButton from "../../../../../../../SharedComponents/Loaders/SubmitButton";
import notify from "devextreme/ui/notify";
import {
  defualtFooterInterface,
  FooterInterface,
} from "../../../../../../../Interfaces/Interfaces";
import { GET_FOOTER, UPDATE_FOOTER } from "../../API.Footer";
const Header = () => {
  const { t } = useTranslation();

  useEffect(() => {
    let get_data = async () => {
      let x: any = await GET_FOOTER(1);
      setHead(x);
    };
    get_data();
  }, []);

  let [Head, setHead] = useState<FooterInterface>(defualtFooterInterface);
  const HandleChange = useCallback((value, id) => {
    setHead((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      await UPDATE_FOOTER(Head)
        .then((res: any) => {
          notify(
            { message: t("Saved Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Head, t]
  );

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>{t("Header")} </h4>
      </CardHeader>
      <CardBody>
        <form onSubmit={submit}>
          <FormGroup>
            <InputTwoLanguages
              id="address"
              label={t("Address")}
              onValueChange={HandleChange}
              value={Head.address}
              valueEn={Head.addressEn}
            />
          </FormGroup>
          <FormGroup>
            <InputTwoLanguages
              id="copyRights"
              label={t("Copyrights")}
              onValueChange={HandleChange}
              value={Head.copyRights}
              valueEn={Head.copyRightsEn}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("E-mail")}</Label>
            <Input
              name={"email"}
              onChange={(e) => HandleChange(e.target.value, e.target.name)}
              value={Head.email}
              type="email"
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("Hotline")}</Label>
            <Input
              name={"hotline"}
              value={Head.hotline}
              onChange={(e) => HandleChange(e.target.value, e.target.name)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t("Phone Number")}</Label>
            <Input
              name={"phoneNumber"}
              value={Head.phoneNumber}
              onChange={(e) => HandleChange(e.target.value, e.target.name)}
            />
          </FormGroup>

          <SubmitButton />
        </form>
      </CardBody>
    </Card>
  );
};

export default Header;
