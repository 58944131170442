import { Redirect, Route, Switch } from "react-router-dom";

import NotFound from "../Pages/NotFound/NotFound";
import { routesUser } from "../SharedComponents/AdminPanel/Sidebar/UserRoutes";
import LoginBar from "../SharedComponents/LoginBar/LoginBar";
import MessengerCustomerChat from "react-messenger-customer-chat";
import egypt from "../Assets/egypt.png";
import lybia from "../Assets/libya.png";
import turkey from "../Assets/turkey.png";
import global from "../Assets/globe.png";
import { useEffect, useState } from "react";

const UserLayOut = () => {
  let [domain, setdomain] = useState("");
  useEffect(() => {
    if (window.location.href.includes(".eg")) {
      setdomain("eg");
    } else if (window.location.href.includes(".tr")) {
      setdomain("tr");
    } else if (window.location.href.includes(".ly")) {
      setdomain("ly");
    } else {
      setdomain("gl");
    }
  }, []);
  return (
    <div>
      <LoginBar />
      <div>
        <div
          className={
            "icon-bar icon-bar-en  " + (domain == "eg" ? "activeicon" : "")
          }
          style={{ top: "37%" }}
          onClick={() =>
            domain != "eg" &&
            (window.location.href = "https://almedadsoft.com.eg")
          }
        >
          <div>
            {" "}
            <img src={egypt} width={"30px"} alt="logo" />
          </div>
        </div>
        <div
          className={
            "icon-bar icon-bar-en  " + (domain == "ly" ? "activeicon" : "")
          }
          style={{ top: "43%" }}
          onClick={() =>
            domain != "ly" && (window.location.href = "https://almedadsoft.ly")
          }
        >
          <div>
            {" "}
            <img src={lybia} width={"30px"} alt="logo" />
          </div>
        </div>
      </div>

      <div
        className={
          "icon-bar icon-bar-en  " + (domain == "tr" ? "activeicon" : "")
        }
        onClick={() =>
          domain != "tr" && (window.location.href = "https://medadsoft.com.tr")
        }
        style={{ top: "49%" }}
      >
        <div>
          {" "}
          <img src={turkey} width={"30px"} alt="logo" />
        </div>
      </div>
      <div
        className={
          "icon-bar icon-bar-en  " + (domain == "gl" ? "activeicon" : "")
        }
        onClick={() =>
          domain != "gl" && (window.location.href = "https://almedadsoft.com")
        }
        style={{ top: "55%" }}
      >
        <div>
          {" "}
          <img src={global} width={"30px"} alt="logo" />
        </div>
      </div>
      <MessengerCustomerChat
        pageId="693052224145781"
        appId="277984650908368"
        themeColor={"#3a7498"}
      />
      <Switch>
        {routesUser.map((prop, key) => {
          return (
            <Route
              path={prop.path}
              key={key}
              exact
              component={prop.component}
            />
          );
        })}
        <Route path={"/not-found"} component={NotFound} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default UserLayOut;
