import { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { REQUEST } from "../../../../Services/callAPI";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import { ProductText, ProductTextinputDefualt } from "../../Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";
import { Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";

interface Props {
  data: ProductText | undefined;
  productId: number;
}

const ProductTextForm: React.FC<Props> = ({ productId, data }) => {
  const { t } = useTranslation();
  /*  const [darkSectionInitValues, setDarkSectionInitValues] =
    useState<ProductText>({
      ProductId: productId,
      Title: "",
      TitleEn: "",
      Description: "",
      DescriptionEn: "",
    });*/
  const [darkSection, setDarkSection] = useState<ProductText>({
    ...ProductTextinputDefualt,
  });

  const [status, setStatus] = useState(Status.IDLE);

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setDarkSection({ ...data });
      // setDarkSectionInitValues({ ...data });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  //
  const updateDarkSection = useCallback((value, id) => {
    setDarkSection((prev) => ({ ...prev, [id]: value }));
  }, []);
  /*
  const isNotValid = useMemo(() => {
    for (let key of Object.keys(darkSection)) {
      if (!darkSection[key]) {
        return true;
      }
    }

    if (darkSection.ProductId) {
      let result = true;
      for (let key of Object.keys(darkSection)) {
        if (darkSection[key] !== darkSectionInitValues[key]) {
          result = false;
        }
      }

      return result;
    }

    return false;
  }, [darkSection, darkSectionInitValues]);
*/ /*
  const darkSectionValuesAsFormData = useMemo(() => {
    let formData = new FormData();
    formData.append("ProductId", productId.toString());
    for (let [key, value] of Object.entries(darkSection)) {
      formData.append(key.toString(), value);
    }
    return formData;
  }, [darkSection, productId]);*/

  // submit form
  const addHandle = useCallback(() => {
    let config: AxiosRequestConfig = {
      method: status === Status.ADD ? "post" : "PUT",
      url: "ProductText",
      data: { ...darkSection, ProductId: productId },
    };

    REQUEST(config)
      .then((response: any) => {
        response.Image = `${apiEndPoint}${response.ImagePath}`;
        delete response.ImagePath;
        //  setDarkSectionInitValues({ ...response });
        setDarkSection({ ...response });
        setStatus(Status.UPDATE);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [status, productId, darkSection]);

  // cancel form
  const cancelForm = useCallback(() => {
    let config: AxiosRequestConfig = {
      method: "DELETE",
      url: `ProductText`,
      data: {
        ProductId: 2,
      },
    };

    REQUEST(config)
      .then((response) => {
        /*   setDarkSectionInitValues({
          ProductId: productId,
          Title: "",
          TitleEn: "",
          Description: "",
          DescriptionEn: "",
        });*/
        setDarkSection({
          ProductId: productId,
          Title: "",
          TitleEn: "",
          Description: "",
          DescriptionEn: "",
        });
        setStatus(Status.ADD);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productId]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="Title"
          label={t("Title")}
          onValueChange={updateDarkSection}
          value={darkSection.Title}
          valueEn={darkSection.TitleEn}
        />
        <TextEditorTwoLanguages
          id="Description"
          label={t("Description")}
          onValueChange={updateDarkSection}
          value={darkSection.Description}
          valueEn={darkSection.DescriptionEn}
        />

        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={status !== Status.UPDATE}
                onClick={cancelForm}
              >
                {t("Delete")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ProductTextForm;
