import { useTranslation } from "react-i18next";
import Header from "./Components/Header";
import History from "./Components/History";
import Services from "./Components/Services";
import TechnologiesTable from "./Components/TeachnologiesTable";
const Aboutus = () => {
  const { i18n } = useTranslation();
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Header />
      <Services />
      <History />
      <TechnologiesTable />
    </div>
  );
};

export default Aboutus;
