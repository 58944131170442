import { useCallback, useEffect, useMemo, useState } from "react";
import { apiEndPoint } from "../../../Services/config.json";
import { Card, CardBody, CardHeader } from "reactstrap";
import ImageViewer from "react-simple-image-viewer";
import notify from "devextreme/ui/notify";

import { useTranslation } from "react-i18next";
import { ContactUsFormInterface } from "../../../Interfaces/Interfaces";
import { DELETE_CONTACT_US, GET_CONTACT_US } from "../user/API.ContactUs";
import { DateConvertor } from "../../../SharedComponents/Loaders/DateFunction";
import UpdateDelete from "../../../SharedComponents/Loaders/UpdateDelete";
const ContactUs = () => {
  const { t, i18n } = useTranslation();
  let [Posts, setPosts] = useState<ContactUsFormInterface[]>([]);
  let [imageslist, setimageslist] = useState<any>([]);
  let [Current, setCurrent] = useState<number>(0);
  let [TotalCount, setTotalCount] = useState<number>(0);
  let [selected, setselected] = useState<number>();
  let [Open, setOpen] = useState(false);
  let fetch = async () => {
    let x: any = await GET_CONTACT_US(0);
    setCurrent(x.currentCount);
    setTotalCount(x.totalCount);
    setPosts(x.data);
  };
  useEffect(() => {
    fetch();
  }, []);
  let closeImage = useCallback(() => {
    setOpen(false);
  }, []);

  let Delete = useCallback(
    async (element) => {
      await DELETE_CONTACT_US(element)
        .then(() => {
          let x = Posts.filter(function (el) {
            return el.id !== element;
          });

          setPosts(x);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Posts, t]
  );

  const DeprtementList = useMemo(() => {
    return [
      { id: 0, text: "Electronic software and systems" },
      { id: 1, text: "Mobile Apps" },
      { id: 2, text: "Websites" },
      { id: 3, text: "Technical support" },
      { id: 4, text: "Request a call" },
      { id: 5, text: "Other Services" },
    ];
  }, []);
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Card className="card-user">
        <CardHeader>
          <h4>{t("Contact Us")} </h4>
        </CardHeader>
        <CardBody>
          {Posts.map((data, mainindex) => {
            return (
              <div style={{ padding: "20px" }}>
                <div
                  className="row "
                  style={{
                    border: "0.5px solid #e69a5c",
                    borderRadius: "20px",
                    padding: "20px",
                  }}
                >
                  <div className="col-12 titleLatestadminForm ">
                    {t("First Name")} : {data.firstName}
                    <br />
                    {t("Last Name")} : {data.lastName}
                  </div>

                  <div className="image-container-list-display col-lg-4">
                    <div
                      className="row-images-contactus"
                      style={{ overflowX: "scroll" }}
                    >
                      {Open ? (
                        <ImageViewer
                          src={imageslist}
                          currentIndex={selected}
                          onClose={closeImage}
                          disableScroll={false}
                          backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.9)",
                            zIndex: 10000000000000,
                          }}
                          closeOnClickOutside={true}
                        />
                      ) : null}
                      {data.contactUsImages.map((dataimage, index) => {
                        return (
                          <div
                            className="column-images-contactus"
                            style={{ minWidth: "200px" }}
                            onClick={() => {
                              setselected(index);
                              setOpen(true);
                              setimageslist(
                                Posts[mainindex].contactUsImages.map(
                                  (dataimage) => {
                                    return typeof dataimage.imagePath ==
                                      "string"
                                      ? `${apiEndPoint}${encodeURI(
                                          dataimage.imagePath.replaceAll(
                                            "\\",
                                            "/"
                                          )
                                        )}`
                                      : "";
                                  }
                                )
                              );
                            }}
                          >
                            <img
                              alt="1"
                              src={
                                typeof dataimage.imagePath == "string"
                                  ? `${apiEndPoint}${encodeURI(
                                      dataimage.imagePath.replaceAll("\\", "/")
                                    )}`
                                  : ""
                              }
                              className="image"
                              width={"100%"}
                              height={"100%"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-8">
                    <div className=" titleLatestadminForm">
                      {t("Image Description")} : {data.imageDescription}
                      <br />
                      {t("E-mail")} : {data.email}
                      <br />
                      {t("Phone Number")} : {data.phoneNumber}
                      <br />
                      {t("Service Type")}:{" "}
                      {t(DeprtementList[data.serviceType].text)}
                      <br />
                      {t("Message")} : {data.message}
                    </div>
                    <div className=" dataForm">
                      {DateConvertor(data.messageDate, i18n.language === "en")}
                    </div>
                    <UpdateDelete
                      data={data}
                      withoutUpdate
                      Updata={() => {}}
                      Delete={Delete}
                      ComponentID="#mybodyTeam"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="morePosts">
            {TotalCount !== Current ? (
              <button
                type="button"
                onClick={async () => {
                  let x: any = await GET_CONTACT_US(Current);
                  setCurrent(x.currentCount);
                  setTotalCount(x.totalCount);
                  setPosts(x.data);
                }}
                className="btn btn-outline-dark "
              >
                {t("More Posts")}
              </button>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ContactUs;
