import Home from "../../../Pages/1.Home/user/Home";

import ChangePassword from "../../../Pages/10.ChangePassword/ChangePassword";
import EmailChangePassword from "../../../Pages/11.EmailChangePassword/EmailChangePassword";
import Demos from "../../../Pages/4.Demos/user/Demos";
import News from "../../../Pages/9.News/user/News";
import Product from "../../../Pages/2.Product/Product";
import Login from "../../../Pages/6.Login/user/Login";
import Register from "../../../Pages/7.Register/user/Register";
import TeamMembers from "../../../Pages/12.TeamMembers/user/TeamMembers";
import ContactUs from "../../../Pages/5.ContactUs/user/ContactUs";
import Buy from "../../../Pages/8.Buy/user/Buy";
import AboutUS from "../../../Pages/3.AboutUs/user/AboutUS";
import EmailConfirmation from "../../../Pages/13.EmailConfirmation/EmailConfirmation";
import Cart from "../../../Pages/Cart/Cart";
import PaymentSuccess from "../../../Pages/PaymentSuccess/PaymentSuccess";
import PaymentDeclined from "../../../Pages/PaymentSuccess/PaymentDeclined";
import PaymobResponse from "../../../Pages/PaymentSuccess/PaymobResponse";

export const routesUser: any[] = [
	{
		path: "/about-us",
		component: AboutUS,
	},

	{
		path: "/demos",

		component: Demos,
	},
	{
		path: "/email-confirmation",

		component: EmailConfirmation,
	},
	// -Ahmed-  -Home- -Route-

	{
		path: "/news",
		component: News,
	},
	{
		path: "/team",
		component: TeamMembers,
	},

	{
		path: "/contact-us",

		component: ContactUs,
	},

	{
		path: "/log-in",

		component: Login,
	},
	{
		path: "/change-password",
		component: ChangePassword,
	},
	{
		path: "/register",

		component: Register,
		layout: "/",
	},
	{
		path: "/email-change-password",

		component: EmailChangePassword,
	},
	{
		path: "/cart",

		component: Cart,
	},
	{
		path: "/paymobResponse",

		component: PaymobResponse,
	},
	{
		path: "/paymentSuccess",

		component: PaymentSuccess,
	},
	{
		path: "/paymentDeclined",

		component: PaymentDeclined,
	},

	{
		path: "/",
		component: Home,
	},
	{
		path: "/product/:id",
		component: Product,
	},
	{
		path: "/buy",
		component: Buy,
	},
];
