import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Status } from "../../../../Enums";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import { AdditionalInfo } from "../../Interfaces";

interface Props {
  data: AdditionalInfo | undefined;
  onSubmit: (formData: AdditionalInfo) => void;
  onCancel: () => void;
}

const AdditionalInfoForm: React.FC<Props> = ({ onSubmit, onCancel, data }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.IDLE);

  const [additionalInfoInitValues, setAdditionalInfoInitValues] = useState({
    Id: 0,
    IsActive: false,
    TitleEn: "",
    Title: "",
    DescriptionEn: "",
    Description: "",
    ProductId: 2,
  });

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    ...additionalInfoInitValues,
  });

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setAdditionalInfo({ ...data });
      setAdditionalInfoInitValues({ ...data });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  //
  const updateAdditionalInfo = useCallback((value, id) => {
    setAdditionalInfo((prev) => ({ ...prev, [id]: value }));
  }, []);

  const isNotValid = useMemo(() => {
    let keysToCheck = [
      "TitleEn",
      "Title",
      "DescriptionEn",
      "Description",
      "ProductId",
    ];

    for (let key of keysToCheck) {
      if (!additionalInfo[key] || !additionalInfo[key].toString()) {
        return true;
      }
    }

    if (additionalInfo.Id) {
      let result = true;
      for (let key of Object.keys(additionalInfo)) {
        if (additionalInfo[key] !== additionalInfoInitValues[key]) {
          result = false;
        }
      }
      return result;
    }
  }, [additionalInfo, additionalInfoInitValues]);

  // submit form
  const addHandle = useCallback(() => {
    onSubmit(additionalInfo);
  }, [additionalInfo, onSubmit]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="Title"
          label={t("AdditionalInfo Title")}
          onValueChange={updateAdditionalInfo}
          value={additionalInfo.Title}
          valueEn={additionalInfo.TitleEn}
        />
        <TextEditorTwoLanguages
          id="Description"
          label={t("AdditionalInfo Description")}
          onValueChange={updateAdditionalInfo}
          value={additionalInfo.Description}
          valueEn={additionalInfo.DescriptionEn}
        />
        <Col className="pr-1" md="6">
          <FormGroup>
            <label htmlFor="isActive" className="d-block">
              {t("Is Active")}
            </label>
            <input
              style={{
                width: "40px",
                height: "40px",
              }}
              type="checkbox"
              checked={additionalInfo.IsActive}
              id="IsActive"
              onChange={(e) =>
                updateAdditionalInfo(e.target.checked, e.target.id)
              }
            />
          </FormGroup>
        </Col>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={false}
                onClick={onCancel}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AdditionalInfoForm;
