import { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { REQUEST } from "../../../../Services/callAPI";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { Header } from "../../Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";
import { Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";

interface Props {
	data: Header | undefined;
	productId: number;
}

const HeaderForm: React.FC<Props> = ({ productId, data }) => {
	const { t } = useTranslation();
	const [headerInitValues, setHeaderInitValues] = useState<Header>({
		ProductId: productId,
		Title: "",
		TitleEn: "",
		SubTitle: "",
		SubTitleEn: "",
		DemoVideoUrl: "",
		Image: null,
	});
	const [header, setHeader] = useState<Header>({ ...headerInitValues });

	const [status, setStatus] = useState(Status.IDLE);

	useEffect(() => {
		if (data) {
			if (data.Image && !(data.Image as string).includes(apiEndPoint)) {
				data.Image = `${apiEndPoint}${data.Image}`;
			}
			setHeader({ ...data });
			setHeaderInitValues({ ...data });
			setStatus(Status.UPDATE);
		} else {
			setStatus(Status.ADD);
		}
	}, [data]);

	let handleGetimages = async (event) => {
		let files = event.target.files;
		let image = files[0];
		setHeader((prevState) => {
			return { ...prevState, Image: image };
		});
	};

	let handleRemoveimage = (element: FileList | string) => {
		setHeader((prevState) => {
			return { ...prevState, Image: null };
		});
	};

	//
	const updateHeader = useCallback((value, id) => {
		setHeader((prev) => ({ ...prev, [id]: value }));
	}, []);

	const isNotValid = useMemo(() => {
		for (let key of Object.keys(header)) {
			if (!header[key]) {
				return true;
			}
		}
		if (header.ProductId) {
			let result = true;
			for (let key of Object.keys(header)) {
				if (header[key] !== headerInitValues[key]) {
					result = false;
				}
			}
			if (
				header.Image &&
				headerInitValues.Image &&
				header.Image.name !== headerInitValues.Image.name &&
				header.Image.size !== headerInitValues.Image.size
			) {
				result = false;
			}
			return result;
		}

		return false;
	}, [header, headerInitValues]);

	// submit form
	const addHandle = useCallback(() => {
		let data = { ...header };

		if (
			typeof data.Image === "string" &&
			data.Image &&
			data.Image.includes(apiEndPoint)
		) {
			data.Image = data.Image.replace(apiEndPoint, "");
		}

		let formData = new FormData();
		formData.append("ProductId", productId.toString());
		for (let [key, value] of Object.entries(data)) {
			formData.append(key.toString(), value);
		}

		let config: AxiosRequestConfig = {
			method: status === Status.ADD ? "post" : "PUT",
			url: "ProductHeaders",
			data: formData,
		};

		REQUEST(config)
			.then((response: any) => {
				response.Image = `${apiEndPoint}${response.ImagePath}`;
				delete response.ImagePath;
				setHeaderInitValues({ ...response });
				setHeader({ ...response });
				setStatus(Status.UPDATE);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [header, productId, status]);

	// cancel form
	const cancelForm = useCallback(() => {
		let config: AxiosRequestConfig = {
			method: "DELETE",
			url: `ProductHeaders`,
			data: {
				ProductId: productId,
			},
		};

		REQUEST(config)
			.then(() => {
				setHeaderInitValues({
					ProductId: productId,
					Title: "",
					TitleEn: "",
					SubTitle: "",
					SubTitleEn: "",
					DemoVideoUrl: "",
					Image: null,
				});
				setHeader({
					ProductId: productId,
					Title: "",
					TitleEn: "",
					SubTitle: "",
					SubTitleEn: "",
					DemoVideoUrl: "",
					Image: null,
				});
				setStatus(Status.ADD);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [productId]);

	return (
		<>
			<div className="mt-2">
				<InputTwoLanguages
					id="Title"
					label={t("Header description")}
					onValueChange={updateHeader}
					value={header.Title}
					valueEn={header.TitleEn}
				/>
				<InputTwoLanguages
					id="SubTitle"
					label={t("Header tip")}
					onValueChange={updateHeader}
					value={header.SubTitle}
					valueEn={header.SubTitleEn}
				/>
				<Row>
					<Col className="pr-1" md="12">
						<FormGroup>
							<label> {t("Demo video url")}</label>
							<Input
								id={"DemoVideoUrl"}
								value={header.DemoVideoUrl}
								onChange={(e) =>
									updateHeader(e.target.value, "DemoVideoUrl")
								}
								type="url"
							/>
						</FormGroup>
					</Col>
					<Col className="pr-1" md="12">
						<FormGroup className="image__button">
							<label> {t("Header image")}</label>
							<UploadImageButton
								isMultiple={false}
								handleGetImages={handleGetimages}
								handleRemoveImage={handleRemoveimage}
								imagesFiles={header.Image ? [header.Image] : []}
							/>
						</FormGroup>
					</Col>
				</Row>

				<div className="button-container">
					<Row>
						<Col style={{ width: "170px" }}>
							<Button
								className="btn btn btn-success col-12"
								disabled={isNotValid}
								onClick={addHandle}
							>
								{status === Status.UPDATE ? "Update" : "Add"}
							</Button>
						</Col>
						<Col style={{ width: "170px" }}>
							<Button
								className="btn btn-danger col-12"
								disabled={status !== Status.UPDATE}
								onClick={cancelForm}
							>
								{t("Delete")}
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default HeaderForm;
