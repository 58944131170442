import axios from "axios";
import { REQUEST } from "../../../../../Services/callAPI";
export const SUBMIT_FORM = async (e) => {
	return await REQUEST({
		method: "post",
		url: "RequestDemo ",
		// responseType: "blob",
		data: e,
	});
};
export const DELETE_REQUEST_DEMO = async (e) => {
	return await REQUEST({
		method: "delete",
		url: "RequestDemo/" + e,
	});
};
export const GET_REQUEST_DEMO = async (e, status) => {
	return await REQUEST({
		method: "get",
		url: "RequestDemo/SkipAndTake/" + e + "/" + status,
	});
};
export const UPDATE_REQUEST_DEMO_STATUS = async (e) => {
	return await REQUEST({
		method: "put",
		url: "RequestDemo ",
		data: e,
	});
};
