import PageHeader from "../../../../../SharedComponents/PageHeader/PageHeader";
import "./style.Header.css";
import { apiEndPoint } from "../../../../../Services/config.json";
import { useTranslation } from "react-i18next";
import { ParagraphSectionInterface } from "../../../../../Interfaces/Interfaces";
const Header = (props: { obj: ParagraphSectionInterface }) => {
  const { i18n } = useTranslation();

  return (
    <PageHeader
      backgroundImage={
        typeof props.obj.imagePath == "string"
          ? `${apiEndPoint}${encodeURI(
              props.obj.imagePath.replaceAll("\\", "/")
            )}`
          : ""
      }
    >
      <div className="align-cell middle ">
        <div className="content">
          <h1>
            {" "}
            {i18n.language === "en" ? props.obj.titleEn : props.obj.title}
          </h1>
          <p>
            {" "}
            {i18n.language === "en"
              ? props.obj.descriptionEn
              : props.obj.description}
          </p>
        </div>
      </div>
    </PageHeader>
  );
};
export default Header;
