import React, { useEffect, useState } from "react";
import "./product.css";

import ProductHeader from "./Components/ProductHeader/ProductHeader";
import ProductFAQs from "./Components/ProductFAQs/ProductFAQs";
import ProductBenefits from "./Components/ProductBenefits/ProductBenefits";

import ProductController from "./Components/ProductController/ProductController";
import ProductDarkSection from "./Components/ProductDarkSection/ProductDarkSection";
import ProductAdditionalInfo from "./Components/ProductAdditionalInfo/ProductAdditionalInfo";

import ProductControlInfo from "./Components/ProductControlInfo/ProductControlInfo";

import Navbar from "../../SharedComponents/Navbar/Navbar";

import "./Components/ProductHeader/productHeader.css";
import { RouteComponentProps } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import { REQUEST } from "../../Services/callAPI";
import { Product as ProductInformation } from "../Admin.Product/Interfaces";
import { useTranslation } from "react-i18next";
import Footer from "../1.Home/user/Components/Footer/user/Footer";
import ProductPrice from "./Components/ProductPrice/ProductPrice";

interface RouteParams {
	id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const Product: React.FC<Props> = ({ match }) => {
	const { i18n } = useTranslation();
	const [product, setProduct] = useState<ProductInformation>();

	useEffect(() => {
		// request and get product data
		if (match.params.id) {
			let config: AxiosRequestConfig = {
				method: "GET",
				url: `Products/${match.params.id}`,
			};

			REQUEST(config).then((response) => {
				let _product = response as unknown as ProductInformation;

				setProduct({ ..._product });
			});
		}
	}, [match.params.id]);

	return (
		<>
			{/* <div className="no__background"> */}
			<div className="">
				<Navbar />

				<div className="product__container">
					<section className="product__section product__header">
						<ProductHeader
							productHeader={product?.ProductHeader}
							id={match.params.id}
							Demo={product?.ProductDemo}
							productImages={product?.ProductImages}
						/>
					</section>
					<main
						style={{
							direction: i18n.language === "en" ? "ltr" : "rtl",
						}}
					>
						<div
							className="Main__Product"
							style={{ direction: "ltr" }}
						>
							<ProductController
								productControls={product?.ProductControls}
							/>
						</div>

						<ProductBenefits
							productBenefits={product?.ProductBenefits}
							productText={product?.ProductText}
						/>

						{product?.ProductPricing && (
							<ProductPrice
								productInfo={product?.ProductBasicsInformation}
								productPricing={product?.ProductPricing}
								subscriptionPrices={product?.SubscriptionPrices}
							/>
						)}

						{product?.ProductQuestions &&
						product?.ProductQuestions?.length > 0 ? (
							<ProductFAQs
								productQuestions={product?.ProductQuestions}
							/>
						) : null}
						{product?.ProductDarkSection ? (
							<ProductDarkSection
								productDarkSection={product?.ProductDarkSection}
							/>
						) : null}

						{product?.ProductAdditionalInfos &&
						product?.ProductAdditionalInfos?.length > 0 ? (
							<ProductAdditionalInfo
								productionAdditionalInfo={
									product?.ProductAdditionalInfos
								}
							/>
						) : null}
						{product?.ProductControlInformation &&
						product?.ProductControlInformation?.length > 0 ? (
							<ProductControlInfo
								productControlInfo={
									product?.ProductControlInformation
								}
							/>
						) : null}

						<section className="product__section product__description"></section>
					</main>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default React.memo(Product);
