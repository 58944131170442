import React, { useEffect, useState } from "react";
import "./style.Servicies.css";
import { apiEndPoint } from "../../../../../Services/config.json";
import { useTranslation } from "react-i18next";
import {
  ParagraphSectionInterface,
  SupportInterface,
} from "../../../../../Interfaces/Interfaces";
import { GET_SUPPORT } from "../../../admin/Admin.Aboutus.APi";

const Services = (props: { obj: ParagraphSectionInterface }) => {
  const { i18n, t } = useTranslation();
  let [data, setData] = useState<SupportInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_SUPPORT();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <div>
      <div className="successParteners text__middle w-100 container mt-5">
        {t("Contact Us")}
      </div>
      <div className="mainpage container " style={{ width: "100%" }}>
        {data.map((da, index) => {
          return (
            <div
              key={index}
              className="servierContent col-lg-3 col-md-6 col-sm-12"
            >
              <img
                alt="1"
                src={
                  typeof da.imagePath == "string"
                    ? `${apiEndPoint}${encodeURI(
                        da.imagePath.replaceAll("\\", "/")
                      )}`
                    : ""
                }
                className="Enterprise-st0"
                width="100%"
                height="100%"
              />{" "}
              <h2 className="title-service">
                {" "}
                {i18n.language === "en" ? da.titleEn : da.title}
              </h2>
              <p style={{ margin: "0" }}>
                {" "}
                {i18n.language === "en" ? da.bodyEn : da.body}
              </p>
              <p style={{ margin: "0" }}>{da.phoneNumber}</p>
              <a
                href={"mailto:" + da.link}
                style={{ margin: "0", color: "#3a7498" }}
              >
                {da.link}{" "}
              </a>
            </div>
          );
        })}
      </div>
      <div
        className="successParteners text__middle w-100 container mt-5"
        style={{ paddingBottom: "50px" }}
      >
        {t("Our Journey")}
      </div>
      <div className="HistoyInfo d-flex justify-content-center align-items-center">
        <div>
          <h2>
            {" "}
            {i18n.language === "en" ? props.obj.titleEn : props.obj.title}
          </h2>
          <h3>
            {i18n.language === "en"
              ? props.obj.descriptionEn
              : props.obj.description}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Services;
