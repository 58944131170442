import React, { useEffect, useState } from "react";
import { ProductsGroup } from "../../../../../SharedComponents/Navbar/Navbar";
import { CATEGORIES_PROGRAMS } from "../../AP.Demos";
// Css
import { apiEndPoint } from "../../../../../Services/config.json";
import "./CategoriesExamples.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
function ApplicationExamples() {
  const { i18n } = useTranslation();
  let [Data, setData] = useState<ProductsGroup[]>([]);
  let fetch = async () => {
    let x: any = await CATEGORIES_PROGRAMS();

    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="applicattionExamplesWrapper w-100">
      <div className="applicattionExamplesContainer  container">
        <div className="row m-0 ">
          {Data.map((data) => {
            return (
              <div className="applicationExampleListContainer  col-lg-4 col-md-6 col-sm-12">
                <div className="applicationExamplesListTitle">
                  {i18n.language === "en" ? data.TitleEn : data.Title}
                </div>
                <hr style={{ width: "80%" }} />
                <ul className="applicationExamplesList">
                  {data.Products?.map((datapro, index) => {
                    console.log(datapro);
                    return (
                      <NavLink to={`/product/${datapro.Id}`} key={index}>
                        <li className="category_product_list_demo">
                          <div
                            className="category_product_icon_demo"
                            style={{
                              backgroundImage: `url(${apiEndPoint}${encodeURI(
                                datapro.Icon?.replaceAll("\\", "/")
                              )})`,
                            }}
                          ></div>
                          {i18n.language === "en"
                            ? datapro.ProductNameEn
                            : datapro.ProductName}
                        </li>
                      </NavLink>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ApplicationExamples;
