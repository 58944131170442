import { useCallback, useEffect, useRef, useState } from "react";
import "./Navbar.css";
import MenuButton from "../MenuButton/MenuButton";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosRequestConfig } from "axios";
import { ProductBasicsInformation } from "../../Pages/Admin.Product/Interfaces";
import { REQUEST } from "../../Services/callAPI";
import { apiEndPoint } from "../../Services/config.json";

export interface ProductsGroup {
  Id: number;
  Title: string;
  TitleEn: string;
  Products: ProductBasicsInformation[];
}

const Navbar = () => {
  const { t, i18n } = useTranslation();
  // const [contry, setcountry] = useState({ name: t("EGYPT"), img: egypt });
  const [groups, setGroups] = useState<ProductsGroup[]>([]);
  /*let countries = useRef([
    { name: t("EGYPT"), img: egypt },
    { name: t("TURKEY"), img: turkey },
    { name: t("LYBIA"), img: lybia },
    { name: t("GLOBAL"), img: global },
  ]);*/
  const [showSubMenu, setShowSubMenu]: any = useState({
    products: false,
    country: false,
  });

  const menuButtonClicked = useCallback((e) => {
    document
      .getElementsByTagName("html")[0]
      .classList.toggle("root__menu__open");

    document
      .getElementsByTagName("html")[0]
      .classList.remove("sub__menu__open");
    setShowSubMenu((prev: any) => ({
      products: false,
      country: false,
    }));
  }, []);

  const openSubMenu = useCallback((e) => {
    setShowSubMenu((prev: any) => ({
      ...prev,
      products: !prev["products"],
      country: false,
    }));

    document
      .getElementsByTagName("html")[0]
      .classList.toggle("sub__menu__open");
  }, []);
  const openSubMenuCountry = useCallback((e) => {
    setShowSubMenu((prev: any) => ({
      ...prev,
      country: !prev["country"],
      products: false,
    }));

    document
      .getElementsByTagName("html")[0]
      .classList.toggle("sub__menu__open_country");
  }, []);
  const navbarStateChecker = useCallback(() => {
    window.scrollTo(0, 0);

    document
      .getElementsByTagName("html")[0]
      .classList.remove("root__menu__open");

    document
      .getElementsByTagName("html")[0]
      .classList.remove("sub__menu__open");
    setShowSubMenu((prev: any) => ({
      products: false,
      country: false,
    }));
  }, []);
  let [Maindata, setMainData] = useState<any>({});

  useEffect(() => {
    let config: AxiosRequestConfig = {
      method: "GET",
      url: `Categories/ProductsCategories`,
    };

    REQUEST(config).then((response) => {
      let _productsCategries = response as unknown as ProductsGroup[];

      setGroups([
        ..._productsCategries.filter((group) => group.Products.length > 0),
      ]);
    });
    let getHeaders = async () => {
      await REQUEST({
        method: "get",
        url: "ParagraphSection/page/main",
      })
        .then((res: any) => {
          res = res[0];
          setMainData(res);
        })
        .catch(() => {
          document.title = "Almedad Soft";
        });
    };
    getHeaders();
  }, []);
  useEffect(() => {
    navbarStateChecker();
  }, [window.location]);

  return (
    <>
      <div dir="auto">
        <nav id="main__menu" className="">
          <div className="menu__container">
            <div className="inner__container container">
              {/* <div className="Logo" title="Al-MedadSoft" id="TOC-Logo" /> */}
              <Link to="/">
                <div className="Logo">
                  <img
                    src={
                      typeof Maindata.imagePath == "string"
                        ? `${apiEndPoint}${Maindata.imagePath.replaceAll(
                            "\\",
                            "/"
                          )}`
                        : ""
                    }
                    alt="logo"
                  />
                </div>
              </Link>
              <div className="d-flex flex-column">
                <div
                  className="Menu-Toggle justify-content-end"
                  data-role="Menu-Toggle-Button"
                >
                  <MenuButton toggleMenu={menuButtonClicked} />
                </div>
                <ul>
                  <li className="">
                    <div id="products" className="title" onClick={openSubMenu}>
                      {t("PROGRAMS")}
                    </div>
                    <div
                      className={
                        "sub__menu" + (showSubMenu.products ? " open" : "")
                      }
                    >
                      <div className="Header hidden-md">
                        <span>{t("PRODUCTS")}</span>
                        <div
                          className="CloseButton"
                          onClick={openSubMenu}
                        ></div>
                      </div>
                      <div className="sub__menu__container">
                        <div className="ScrollContainer">
                          {groups.map((group, index) => {
                            return (
                              <div className="Column" key={index}>
                                <div className="GroupTitle d-flex">
                                  {i18n.language === "en"
                                    ? group.TitleEn
                                    : group.Title}
                                </div>
                                <div>
                                  {group.Products.map((product, index) => {
                                    return (
                                      <NavLink
                                        onClick={navbarStateChecker}
                                        to={`/product/${product.Id}`}
                                        key={index}
                                      >
                                        <div
                                          className="icon"
                                          style={{
                                            backgroundImage: `url(${apiEndPoint}${encodeURI(
                                              product?.Icon?.toString().replaceAll(
                                                "\\",
                                                "/"
                                              )
                                            )})`,
                                          }}
                                        ></div>
                                        {i18n.language === "en"
                                          ? product.ProductNameEn
                                          : product.ProductName}
                                      </NavLink>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="sperator"></li>

                  <li className="">
                    <NavLink to="/about-us" onClick={navbarStateChecker}>
                      <div className="title">{t("ABOUT US")}</div>
                      <div className="sub__menu"></div>
                    </NavLink>
                  </li>

                  <li className="sperator"></li>
                  <li className=" ">
                    <NavLink to="/Demos" onClick={navbarStateChecker}>
                      <div className="title">{t("DEMOS")}</div>
                    </NavLink>
                  </li>
                  <li className="sperator"></li>
                  <li className=" ">
                    <NavLink to={"/news"} onClick={navbarStateChecker}>
                      <div className="title">{t("BLOGS")}</div>
                    </NavLink>
                  </li>
                  <li className="sperator"></li>
                  <li className=" ">
                    <NavLink to={"/team"} onClick={navbarStateChecker}>
                      <div className="title">{t("TEAM")}</div>
                    </NavLink>
                  </li>
                  <li className="sperator"></li>

                  <li className=" ">
                    <NavLink to={"/buy"} onClick={navbarStateChecker}>
                      <div className="title">{t("BUY")}</div>
                    </NavLink>
                  </li>
                  <li className="sperator"></li>
                  <li className=" ">
                    <NavLink to="/contact-us" onClick={navbarStateChecker}>
                      <div className="title">{t("CONTACT US")}</div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
/*
  <li className="sperator"></li>
                  <li className=" ">
                    <div
                      id="products"
                      className="title"
                      onClick={openSubMenuCountry}
                    >
                      <div className="country_cell" style={{ margin: "auto" }}>
                        <div style={{ padding: "0 8px" }}>
                          <img src={contry.img} width={"30px"} alt="logo" />
                        </div>
                        <div style={{ margin: "auto" }}>{contry.name}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        display: showSubMenu.country ? "" : "none",
                      }}
                    >
                      <div className="ScrollContainer country_countainer">
                        {countries.current.map((ele) => {
                          if (ele.name != contry.name)
                            return (
                              <div className="country_cell">
                                <div style={{ padding: "0 8px" }}>
                                  <img
                                    src={ele.img}
                                    width={"30px"}
                                    alt="logo"
                                  />
                                </div>
                                <div style={{ margin: "auto" }}>{ele.name}</div>
                              </div>
                            );
                        })}
                      </div>
                    </div>

                    <div
                      className={
                        "sub__menu" + (showSubMenu.country ? " open" : "")
                      }
                    >
                      <div className="Header hidden-md">
                        <span>{t("Country")}</span>
                        <div
                          className="CloseButton"
                          onClick={openSubMenuCountry}
                        ></div>
                      </div>

                      <div
                        className="sub__menu__container container"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div className="ScrollContainer country_countainer"></div>
                      </div>
                    </div>
                  </li>

*/
