import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InputType } from "../../Enums";

import Input from "../../SharedComponents/Input/Input";
import Navbar from "../../SharedComponents/Navbar/Navbar";
import { CHANGE_PASSWORD } from "./EmailChangePassword.Api";

import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import Footer from "../1.Home/user/Components/Footer/user/Footer";

function EmailChangePassword() {
  let history = useHistory();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let [values, setvalues] = useState({ email: "" });
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.email) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      await CHANGE_PASSWORD(values)
        .then((res: any) => {
          notify(
            { message: t("Reset Password mail has been send"), width: 600 },
            "success",
            3000
          );
          history.push("/log-in");
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, t, history]
  );
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  return (
    <div className="no__background">
      {Headers}
      <Navbar />
      <div className="absolute__from__nav colorGrey ">
        <main>
          <div
            className="loginWrapper"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <form onSubmit={submit} className="loginContainer">
              <div className="loginWidnow">
                <div className="signInText">{t("RESET PASSWORD")}</div>
                <div className="loginInputsContainer">
                  <div>
                    <Input
                      label={t("E-mail")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.email}
                      name="email"
                    />
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button className="btn signInButton"> {t("Send")}</button>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default EmailChangePassword;
