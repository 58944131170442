import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { Status } from "../../../../Enums";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import { Question } from "../../Interfaces";

interface Props {
  data: Question | undefined;
  onSubmit: (formData: Question) => void;
  onCancel: () => void;
}

const QuestionForm: React.FC<Props> = ({ onSubmit, onCancel, data }) => {
  const [questionInitValues, setQuestionInitValues] = useState<Question>({
    Id: 0,
    IsActive: false,
    Rank: 0,
    QuestionEn: "",
    AnswerEn: "",
    Question: "",
    Answer: "",
    ProductId: 2,
  });
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.IDLE);

  const [question, setQuestion] = useState<Question>(questionInitValues);

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setQuestion({ ...data });
      setQuestionInitValues({ ...data });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  //

  //
  const updateQuestion = useCallback((value, id) => {
    setQuestion((prev) => ({ ...prev, [id]: value }));
  }, []);

  // const isNotValid = useMemo(() => {
  // 	for (var value of Object.values(question)) {
  // 		if (value === "") {
  // 			return true;
  // 		}
  // 	}
  // 	return false;
  // }, [question]);

  const isNotValid = useMemo(() => {
    let keysToCheck = [
      "QuestionEn",
      "Question",
      "AnswerEn",
      "Answer",
      "ProductId",
    ];

    for (let key of keysToCheck) {
      if (!question[key] || !question[key].toString()) {
        return true;
      }
    }

    if (question.Id) {
      let result = true;
      for (let key of Object.keys(question)) {
        if (question[key] !== questionInitValues[key]) {
          result = false;
        }
      }
      return result;
    }
  }, [question, questionInitValues]);

  // submit form
  const addHandle = useCallback(() => {
    onSubmit(question);
  }, [question, onSubmit]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="Question"
          label={t("Question")}
          onValueChange={updateQuestion}
          value={question.Question}
          valueEn={question.QuestionEn}
        />
        <InputTwoLanguages
          id="Answer"
          label={t("Question Answer")}
          onValueChange={updateQuestion}
          value={question.Answer}
          valueEn={question.AnswerEn}
        />
        <Row>
          <Col className="pr-1" md="6">
            <FormGroup>
              <label htmlFor="Rank">{t("Rank")}</label>
              <Input
                type="number"
                min={0}
                value={question.Rank}
                id="Rank"
                onChange={(e) => updateQuestion(e.target.value, e.target.id)}
              />
            </FormGroup>
          </Col>
          <Col className="pr-1" md="6">
            <FormGroup>
              <label htmlFor="IsActive" className="d-block">
                {t("Is Active")}
              </label>
              <input
                style={{
                  width: "40px",
                  height: "40px",
                }}
                type="checkbox"
                checked={question.IsActive}
                id="IsActive"
                onChange={(e) => updateQuestion(e.target.checked, e.target.id)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? "Update" : "Add"}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={false}
                onClick={onCancel}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default QuestionForm;
