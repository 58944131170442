import { REQUEST } from "../../../Services/callAPI";
export const SUBMIT_FORM = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Buy",
    data: e,
  });
};
export const GET_BUY = async (e) => {
  return await REQUEST({
    method: "get",
    url: "Buy/SkipAndTake/" + e,
  });
};
export const DELETE_BUY = async (e) => {
  return await REQUEST({
    method: "delete",
    url: "Buy/" + e,
  });
};
