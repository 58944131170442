import Aboutus from "../../../Pages/3.AboutUs/admin/Aboutus";
import AdminMap from "../../../Pages/1.Home/admin/Admin.Map/AdminMap";
import AdminNews from "../../../Pages/9.News/admin/AdminNews";
import AdminTeam from "../../../Pages/12.TeamMembers/admin/AdminTeam";

import Products from "../../../Admin/Products/Products";
import Categories from "../../../Admin/Categories/Categories";
import AdminHome from "../../../Pages/1.Home/admin/Admin.Home";
import AdminDemo from "../../../Pages/4.Demos/admin/Admin.Demo";
import ContactUs from "../../../Pages/5.ContactUs/Admin/ContactUs";
import Buy from "../../../Pages/8.Buy/Admin/Buy";
import Footer from "../../../Pages/1.Home/user/Components/Footer/admin/Footer";
import RequestDemo from "../../../Pages/2.Product/Components/RequestDemoModal/admin/RequestDemo";
import AdminProduct from "../../../Pages/Admin.Product/Admin.Product";

export interface Routeing {
  path: string;
  name: string;
  icon: string;
  component: any;
  layout: string;
}

export const routes: Routeing[] = [
  {
    path: "/Home",
    name: "Home",
    icon: "fas fa-home",
    component: AdminHome,
    layout: "/admin",
  },
  {
    path: "/products/add",
    name: "",
    icon: "fas fa-home",
    component: AdminProduct,
    layout: "/admin",
  },
  {
    path: "/products/edit/:id",
    name: "",
    icon: "fas fa-home",
    component: AdminProduct,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "fas fa-project-diagram",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "fas fa-barcode",
    component: Products,
    layout: "/admin",
  },

  {
    path: "/demos",
    name: "Demos",
    icon: "fas fa-desktop",
    component: AdminDemo,
    layout: "/admin",
  },
  {
    path: "/about-us",
    name: "About us",
    icon: "far fa-file-alt",
    component: Aboutus,
    layout: "/admin",
  },
  {
    path: "/blogs",
    name: "Blogs",
    icon: "fas fa-newspaper",
    component: AdminNews,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Map",
    icon: "far fa-map",
    component: AdminMap,
    layout: "/admin",
  },

  {
    path: "/team-member",
    name: "Team Members",
    icon: "fas fa-users-cog",
    component: AdminTeam,
    layout: "/admin",
  },
  {
    path: "/contact-us",
    name: " contact us ",
    icon: "fas fa-network-wired",
    component: ContactUs,
    layout: "/admin",
  },
  {
    path: "/buy",
    name: " buy ",
    icon: "fas fa-store",
    component: Buy,
    layout: "/admin",
  },
  {
    path: "/footer",
    name: " footer ",
    icon: "fas fa-fast-forward",
    component: Footer,
    layout: "/admin",
  },
  {
    path: "/request-demo",
    name: " RequestDemo ",
    icon: "fas fa-fast-forward",
    component: RequestDemo,
    layout: "/admin",
  },
];
