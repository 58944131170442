import { REQUEST } from "../../../../Services/callAPI";

export const GET_BRANCHES = async () => {
  return await REQUEST({
    method: "get",
    url: "Branch",
  });
};
export const UPDATE_BRANCHES = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Branch",
    data: e,
  });
};
export const INSERT_BRANCHES = async (data) => {
  return await REQUEST({
    method: "post",
    url: "Branch",
    data: data,
  });
};
export const DELETE_BRANCHES = async (id: number) => {
  return await REQUEST({
    method: "delete",
    url: "Branch/" + id,
  });
};
