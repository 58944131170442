import React, { useCallback, useState, useEffect, useRef } from "react";
import { apiEndPoint } from "../../../../Services/config.json";
import { Card, FormGroup, Input, CardBody, CardHeader } from "reactstrap";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton, {
  HTMLInputEvent,
} from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { useTranslation } from "react-i18next";

import {
  DELETE_MEMBER,
  GET_MEMBER,
  INSERT_MEMBER,
  UPDATE_MEMBER,
} from "../Admin.Team.Api";
import { MemberInterface } from "../../../../Interfaces/Interfaces";
import ButtonsComponent from "../../../../SharedComponents/Loaders/ButtonsComponent";
import UpdateDelete from "../../../../SharedComponents/Loaders/UpdateDelete";
import notify from "devextreme/ui/notify";
interface Props {}
const Members: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();

  let [Data, setData] = useState<MemberInterface[]>([]);
  let defaultdata = useRef({
    id: 0,
    memberName: "",
    memberNameEn: "",
    jopTitleEn: "",
    jopTitle: "",
    body: "",
    rank: 0,
    bodyEn: "",
    image: "",
    imagePath: "",
    facebook: "",
    google: "",
    twitter: "",
    linkedin: "",
  });
  let [Sevice, setServices] = useState<MemberInterface>(defaultdata.current);

  useEffect(() => {
    let get_data = async () => {
      let x: any = await GET_MEMBER();
      setData(x);
    };
    get_data();
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let handleGetImages = (event: HTMLInputEvent) => {
    let files: any = event.target.files;
    setServices({ ...Sevice, image: files[0] });
  };

  let handleRemoveImage = useCallback(() => {
    setServices({ ...Sevice, image: "" });
  }, [Sevice]);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_MEMBER(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });

          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback((element) => {
    setServices({ ...defaultdata.current });
  }, []);

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        !Sevice.memberName ||
        !Sevice.memberNameEn ||
        !Sevice.jopTitle ||
        !Sevice.jopTitleEn ||
        !Sevice.bodyEn ||
        !Sevice.body ||
        !Sevice.image
      ) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      Sevice.google = !Sevice.google ? " " : Sevice.google;
      Sevice.facebook = !Sevice.facebook ? " " : Sevice.facebook;
      Sevice.linkedin = !Sevice.linkedin ? " " : Sevice.linkedin;
      Sevice.twitter = !Sevice.twitter ? " " : Sevice.twitter;
      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }
      if (Sevice.id > 0) {
        await UPDATE_MEMBER(formData)
          .then((res: any) => {
            setServices({ ...res, image: res.imagePath });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res, image: res.imagePath };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_MEMBER(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            setServices(defaultdata.current);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <div>
      <Card className="card-user">
        <CardHeader>
          <h4>{t("TEAM")}</h4>
        </CardHeader>

        <CardBody>
          <form
            className="row"
            onSubmit={submit}
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <div className=" col-md-8 col=lg-8 col-sm-12">
              <div>
                <FormGroup>
                  <InputTwoLanguages
                    id="memberName"
                    label={t("Name")}
                    onValueChange={HandleChange}
                    value={Sevice.memberName}
                    valueEn={Sevice.memberNameEn}
                  />
                </FormGroup>
                <FormGroup>
                  <InputTwoLanguages
                    id="jopTitle"
                    label={t("Job Title")}
                    onValueChange={HandleChange}
                    value={Sevice.jopTitle}
                    valueEn={Sevice.jopTitleEn}
                  />
                </FormGroup>
                <FormGroup>
                  <InputTwoLanguages
                    id="body"
                    label={t("Description")}
                    onValueChange={HandleChange}
                    value={Sevice.body}
                    valueEn={Sevice.bodyEn}
                  />
                </FormGroup>
                <FormGroup className="image__button">
                  <label>{t("Image")} </label>
                  <UploadImageButton
                    isMultiple={false}
                    handleGetImages={handleGetImages}
                    handleRemoveImage={handleRemoveImage}
                    imagesFiles={
                      Sevice.image
                        ? [
                            typeof Sevice.image === "string"
                              ? apiEndPoint + Sevice.image
                              : Sevice.image,
                          ]
                        : []
                    }
                  />
                </FormGroup>
                <label>{t("Rank")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"rank"}
                  value={Sevice.rank}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="number"
                />
                <label>facebook {t("Link")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"facebook"}
                  value={Sevice.facebook}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="url"
                />
                <label>google {t("E-mail")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"google"}
                  value={Sevice.google}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="email"
                />
                <label>linkedin {t("Link")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"linkedin"}
                  value={Sevice.linkedin}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="url"
                />
                <label>twitter {t("Link")}</label>
                <Input
                  style={{ fontFamily: "Tajawal", height: "40px" }}
                  id={"twitter"}
                  value={Sevice.twitter}
                  onChange={(e) => HandleChange(e.target.value, e.target.id)}
                  type="url"
                />
                <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
              </div>
            </div>
            <div
              style={{
                maxHeight: "647px",
              }}
              id="mybodyTeam"
              className="col-md-4 col-lg-4 col-sm-12 Aboutus-admin-service-list"
            >
              {Data.map((data) => {
                return (
                  <div
                    style={{
                      direction: i18n.language === "en" ? "ltr" : "rtl",
                    }}
                  >
                    <img
                      alt="1"
                      className="TeamMembers_profile-img"
                      style={{
                        minHeight: "300px",
                        borderRadius:
                          i18n.language === "en"
                            ? " 58% 53% 53% 0"
                            : " 58% 53%  0 53%",
                      }}
                      src={
                        typeof data.imagePath === "string"
                          ? `${apiEndPoint}${encodeURI(
                              data.imagePath.replaceAll("\\", "/")
                            )}`
                          : ""
                      }
                      height="100%"
                      width="100%"
                    />
                    <h2 className="TeamMembers_title">
                      {" "}
                      {i18n.language === "en"
                        ? data.memberNameEn
                        : data.memberName}
                    </h2>
                    <h3 className="TeamMembers_joptitle">
                      {" "}
                      {i18n.language === "en" ? data.jopTitleEn : data.jopTitle}
                    </h3>
                    <p className={"clickedJop TeamMembers_details"}>
                      {i18n.language === "en" ? data.bodyEn : data.body}
                    </p>
                    <UpdateDelete
                      data={data}
                      Updata={Updata}
                      Delete={Delete}
                      ComponentID="#mybodyTeam"
                    />
                  </div>
                );
              })}
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Members;
