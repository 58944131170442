import { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { REQUEST } from "../../../../Services/callAPI";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { ProductBasicsInformation } from "../../Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";
import { Status } from "../../../../Enums";
import { SelectBox } from "devextreme-react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

interface Props {
  data: ProductBasicsInformation | undefined;
  categries: any[] | undefined;
  updateProduct: (vlaue: any, id: any) => void;
  productId: number;
}

const BasicInformationForm: React.FC<Props> = ({
  data,
  categries,
  updateProduct,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [basicInitValues, setBasicInitValues] =
    useState<ProductBasicsInformation>({
      Id: 0,
      ProductName: "",
      ProductNameEn: "",
      CategoryId: 1,
      IsActive: false,
      Icon: null,
      Link: null,
      Rank: 0,
      DomainId: 1,
      TrialDuration: 7,
    });
  const [basic, setBasic] = useState<ProductBasicsInformation>({
    ...basicInitValues,
  });

  const [status, setStatus] = useState(Status.IDLE);
  useEffect(() => {
    if (data) {
      if (data.Icon && !(data.Icon as string).includes(apiEndPoint)) {
        data.Icon = `${apiEndPoint}${data.Icon}`;
      }
      setBasic({ ...data });
      setBasicInitValues({ ...data });
      setStatus(Status.UPDATE);
    } else {
      setStatus(Status.ADD);
    }
  }, [data]); //TrialDuration
  //TrialDuration
  let handleGetimages = async (event) => {
    let files = event.target.files;
    let image = files[0];
    setBasic((prevState) => {
      return { ...prevState, Icon: image };
    });
  };

  let handleRemoveimage = (element: FileList | string) => {
    setBasic((prevState) => {
      return { ...prevState, Icon: null };
    });
  };

  //
  const updateBasic = useCallback((value, id) => {
    setBasic((prev) => ({ ...prev, [id]: value }));
  }, []);

  const isNotValid = useMemo(() => {
    let keysToCheck = ["ProductNameEn", "ProductName", "CategoryId", "Icon"];

    for (let key of keysToCheck) {
      if (!basic[key]) {
        return true;
      }
    }

    if (basic.Id) {
      let result = true;
      for (let key of Object.keys(basic)) {
        if (basic[key] !== basicInitValues[key]) {
          result = false;
        }
      }
      if (
        basic.Icon &&
        basicInitValues.Icon &&
        basic.Icon.name !== basicInitValues.Icon.name &&
        basic.Icon.size !== basicInitValues.Icon.size
      ) {
        result = false;
      }
      return result;
    }

    return false;
  }, [basic, basicInitValues]);

  // submit form
  const addHandle = useCallback(() => {
    let data = { ...basic };
    if (
      typeof data.Icon === "string" &&
      data.Icon &&
      data.Icon.includes(apiEndPoint)
    ) {
      data.Icon = data.Icon.replace(apiEndPoint, "");
    }

    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key.toString(), value);
    }

    let config: AxiosRequestConfig = {
      method: status === Status.ADD ? "post" : "PUT",
      url: "Products",
      data: formData,
    };

    REQUEST(config)
      .then((response: any) => {
        if (response.Icon && !(response.Icon as string).includes(apiEndPoint)) {
          response.Icon = `${apiEndPoint}${response.Icon}`;
        }
        setBasicInitValues({ ...response });
        setBasic({ ...response });
        if (status === Status.ADD) {
          updateProduct(response.Id, "Id");
          history.push(`/admin/products/edit/${response.Id}`);
        }
        setStatus(Status.UPDATE);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [basic, history, status, updateProduct]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="ProductName"
          label={t("Product Name")}
          onValueChange={updateBasic}
          enDisabled
          value={basic.ProductName}
          valueEn={basic.ProductNameEn}
        />
        <Row>
          <Col className="pr-1" md="6">
            <FormGroup>
              <label>Category</label>
              <SelectBox
                id="CategoryId"
                disabled
                items={categries}
                displayExpr={"Title"}
                valueExpr={"Id"}
                value={basic.CategoryId}
                onValueChange={(e) => updateBasic(e, "CategoryId")}
              />
            </FormGroup>
            <FormGroup>
              <label>{t("Link")}</label>

              <Input
                id={"Link"}
                value={basic.Link}
                onChange={(e) => updateBasic(e.target.value, e.target.id)}
                type="url"
              />
            </FormGroup>
          </Col>
          <Col className="pr-1" md="6">
            <FormGroup>
              <label>{t("Rank")}</label>

              <Input
                id={"Rank"}
                value={basic.Rank}
                onChange={(e) => updateBasic(e.target.value, e.target.id)}
                type="number"
                min="0"
              />
            </FormGroup>
            <FormGroup>
              <label>{t("Trail Duration")}</label>

              <Input
                id={"TrialDuration"}
                value={basic.TrialDuration}
                onChange={(e) => updateBasic(e.target.value, e.target.id)}
                type="number"
                min="0"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="pr-1" md="12">
            <FormGroup className="image__button">
              <label>{t("Basic image")}</label>
              <UploadImageButton
                isMultiple={false}
                handleGetImages={handleGetimages}
                handleRemoveImage={handleRemoveimage}
                imagesFiles={basic.Icon ? [basic.Icon] : []}
              />
            </FormGroup>
          </Col>
          <Col className="pr-1" md="6">
            <FormGroup>
              <label htmlFor="isActive" className="d-block">
                {t("Is Active")}
              </label>
              <input
                style={{
                  width: "40px",
                  height: "40px",
                }}
                type="checkbox"
                checked={basic.IsActive}
                id="IsActive"
                onChange={(e) => updateBasic(e.target.checked, e.target.id)}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default React.memo(BasicInformationForm);
