import { REQUEST } from "../../../../../Services/callAPI";

export const GET_SOCIAL = async () => {
  return await REQUEST({
    method: "get",
    url: "SocialMedia",
  });
};
export const UPDATE_SOCIAL = async (e) => {
  return await REQUEST({
    method: "put",
    url: "SocialMedia",
    data: e,
  });
};
export const GET_FOOTER = async (id) => {
  return await REQUEST({
    method: "get",
    url: "footer/" + id,
  });
};
export const UPDATE_FOOTER = async (e) => {
  return await REQUEST({
    method: "put",
    url: "footer",
    data: e,
  });
};
export const GET_NEAREST_BRANCH = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Branch/nearest-branch",
    data: e,
  });
};
