import { useTranslation } from "react-i18next";
import { apiEndPoint } from "../../../../Services/config.json";

import UpdateDelete from "../../../../SharedComponents/Loaders/UpdateDelete";

const Technologies = ({ serlist, Update, Delete }) => {
  const { i18n } = useTranslation();
  return (
    <div id="mybodyTech">
      {serlist.map((data) => {
        return (
          <div className="about-us-service-list-card">
            <div className="row">
              <div
                className=" col-md-4 col-lg-3 col-sm-6"
                style={{
                  minWidth: "70px",
                  minHeight: "50px",
                  maxWidth: "100px",
                }}
              >
                <img
                  alt="1"
                  src={
                    typeof data.imagePath == "string"
                      ? `${apiEndPoint}${encodeURI(
                          data.imagePath.replaceAll("\\", "/")
                        )}`
                      : ""
                  }
                  width="100%"
                  height="100%"
                />{" "}
              </div>
              <div className=" col-md-8 col-lg-9 col-sm-6 about-us-service-list-card-text">
                <b>{i18n.language === "en" ? data.titleEn : data.title}</b>
                <br />
                <b>
                  {i18n.language === "en" ? data.subTitleEn : data.subTitle}
                </b>
                <UpdateDelete
                  data={data}
                  Updata={Update}
                  Delete={Delete}
                  ComponentID="#mybodyTech"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Technologies;
