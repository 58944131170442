import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../SharedComponents/Navbar/Navbar";
import { CONFIRM } from "./EmailConfirmation.APi";

import { useTranslation } from "react-i18next";

import queryString from "query-string";

import Footer from "../1.Home/user/Components/Footer/user/Footer";
function EmailConfirmation(props) {
  const { t, i18n } = useTranslation();
  let [text, settext] = useState("");
  let submit = useCallback(
    async (e) => {
      await CONFIRM(e)
        .then((res: any) => {
          settext(t("YOUR EMAIL BEEN CONFIRMED SUCCESSFULLY"));
        })
        .catch(() => {
          settext(t("ERROR IN INFO TRY AGAIN"));
        });
    },
    [t]
  );

  useEffect(() => {
    let value = queryString.parse(props.location.search);
    if (value.token && value.username) {
      submit({
        token: value.token,
        username: value.username,
      });
    }
  }, [props.location.search, submit]);
  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  return (
    <div className="no__background">
      <Navbar />
      <div className="absolute__from__nav colorGrey ">
        <main>
          <div className="loginWrapper">
            <form onSubmit={submit} className="loginContainer">
              <div
                className="loginWidnow"
                style={{
                  direction: i18n.language === "en" ? "ltr" : "rtl",
                  textAlign: "center",
                }}
              >
                <h1>{text}</h1>
                <div className="w-100 d-flex justify-content-center">
                  <Link to="/Log-In" className="btn CreateAnAccountButton">
                    {t("Log In")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default EmailConfirmation;
