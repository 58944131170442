import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";

import { Routeing } from "../Sidebar/routes";
import { apiEndPoint } from "../../../Services/config.json";

import { REQUEST } from "../../../Services/callAPI";

interface Props {
  bgColor: string;
  activeColor: string;
  routes: Routeing[];
}

const Sidebar: React.FC<Props> = (props) => {
  const sidebar = React.useRef<HTMLDivElement>(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return window.location.pathname.indexOf(routeName) > -1
      ? "list-slid-bar active"
      : " list-slid-bar";
  };

  let [Maindata, setMainData] = useState<any>({});
  useEffect(() => {
    let getHeaders = async () => {
      await REQUEST({
        method: "get",
        url: "ParagraphSection/page/main",
      })
        .then((res: any) => {
          res = res[0];
          setMainData(res);
        })
        .catch(() => {
          document.title = "Almedad Soft";
        });
    };
    getHeaders();
  }, []);

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="/" className="logo-normal">
          <div className="logo-img">
            <img
              src={
                typeof Maindata.imagePath === "string"
                  ? `${apiEndPoint}${encodeURI(
                      Maindata.imagePath.replaceAll("\\", "/")
                    )}`
                  : ""
              }
              alt="react-logo"
            />
          </div>
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes
            .filter(function (element) {
              return element.name;
            })
            .map((prop, key) => {
              return (
                <li
                  className={activeRoute(prop.path)}
                  onClick={() => {
                    var element =
                      document.getElementsByClassName("list-slid-bar");
                    for (var i = 0; i < element.length; i++) {
                      if (i === key) {
                        if (!element[i].classList.contains("active")) {
                          element[i].classList.add("active");
                        }
                      } else {
                        if (element[i].classList.contains("active")) {
                          element[i].classList.remove("active");
                        }
                      }
                    }
                  }}
                  key={key}
                >
                  <NavLink
                    exact
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
