import React from "react";
import { Control } from "../../../Admin.Product/Interfaces";
import "./ProductController.css";
import { apiEndPoint } from "../../../../Services/config.json";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
// interface ProductControl {
// 	title: string;
// 	subTitle: string;
// 	body: string;
// 	qoute?: string;
// 	qouteSign?: string;
// 	image: any;
// 	list: string[];
// }

interface Props {
	productControls?: Control[];
}

const ProductController: React.FC<Props> = ({ productControls = [] }) => {
	const { i18n, t } = useTranslation();
	return (
		<>
			<div
				className="Title py-5 text__middle"
				style={{ textAlign: "center" }}
			>
				{t("Programs")}
			</div>
			{productControls.map((element, index) => {
				return (
					<div
						key={index}
						className="programsDefinationsWrapper"
						style={{ backgroundColor: " white" }}
					>
						<div className="container">
							<div className="row  mx-auto programsDefinationsContainer">
								<div className="col-md-12 col-lg-6">
									<div
										className="programsDefinationsTextContainer"
										style={{
											direction:
												i18n.language === "en"
													? "ltr"
													: "rtl",
										}}
									>
										<div className="programsDefinationsMainTitle">
											{i18n.language === "en"
												? element.TitleEn
												: element.Title}
										</div>
										<div className="programsDefinationsSubTitle">
											{i18n.language === "en"
												? element.SubTitleEn
												: element.SubTitle}
										</div>
										<div className="programsDefinationsExplanations">
											{ReactHtmlParser(
												i18n.language === "en"
													? element.DescriptionEn
													: element.Description
											)}
										</div>
										<div
											className="programsDefinationsList"
											style={{
												direction:
													i18n.language === "en"
														? "ltr"
														: "rtl",
												margin: "18px",
											}}
										>
											{element.Quote && (
												<div className="Quote">
													<div
														dangerouslySetInnerHTML={{
															__html:
																i18n.language ===
																"en"
																	? element.QuoteEn
																	: element.Quote,
														}}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="col-md-12 col-lg-6 programsDefinationsImageWrapper d-block d-sm-flex d-md-flex d-lg-block justify-content-center">
									<div className="mainCompouter">
										<div className="mainComputerFrame">
											<div
												className="mainComputerImage"
												style={{
													backgroundImage: `url(${apiEndPoint}${encodeURI(
														element?.Image?.replaceAll(
															"\\",
															"/"
														)
													)})`,
												}}
											>
												<div className="mainComputerImage"></div>
											</div>
										</div>
										<div className="mainComputerBase"></div>

										{/* <div>
										{element.Image2 ? (
											<div className="secondaryComputer">
												<div className="secondaryComputerFrame">
													<div
														className="secondaryComputerImage"
														style={{
															backgroundImage: `url(${apiEndPoint}${encodeURI(
																element.Image2?.replaceAll(
																	"\\",
																	"/"
																)
															)})`,
														}}
													></div>
												</div>
											</div>
										) : null}
									</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default ProductController;
/**
 * 
 *                       {item.Quote && (
                        <div className="Quote">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === "en"
                                  ? item.QuoteEn
                                  : item.Quote,
                            }}
                          />
                        </div>
                      )}
 */
