import { useCallback, useState, useEffect, useRef } from "react";
import { Card, FormGroup, CardBody, CardHeader } from "reactstrap";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import notify from "devextreme/ui/notify";
import {
  DELETE_POST_FEATURED,
  GET_POST_FEATURED,
  INSERT_POST_FEATURED,
  UPDATE_POST_FEATURED,
} from "../Admin.News.Api";

import { useTranslation } from "react-i18next";
import { FeaturedPostInterface } from "../../../../Interfaces/Interfaces";
import { DateConvertor } from "../../../../SharedComponents/Loaders/DateFunction";
import ButtonsComponent from "../../../../SharedComponents/Loaders/ButtonsComponent";
import UpdateDelete from "../../../../SharedComponents/Loaders/UpdateDelete";

const FeaturedPosts = () => {
  const { t, i18n } = useTranslation();
  let [Data, setData] = useState<FeaturedPostInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_POST_FEATURED();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);

  let defaultdata = useRef<FeaturedPostInterface>({
    id: 0,
    title: "",
    titleEn: "",
    postDate: "",
  });
  let [Sevice, setServices] = useState<FeaturedPostInterface>(
    defaultdata.current
  );

  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_POST_FEATURED(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });
          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback((element) => {
    setServices({ ...defaultdata.current });
  }, []);

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!Sevice.title || !Sevice.titleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      if (Sevice.id > 0) {
        await UPDATE_POST_FEATURED(Sevice)
          .then((res: any) => {
            setServices({ ...res });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_POST_FEATURED(Sevice)
          .then((res: any) => {
            setData([...Data, res]);
            setServices({ ...res });
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <div>
      <Card className="card-user">
        <CardHeader>
          <h4>{t("FEATURED POSTS")} </h4>
        </CardHeader>
        <CardBody>
          <form onSubmit={submit} className="row">
            <div className="col-12">
              <div>
                <FormGroup>
                  <InputTwoLanguages
                    id="title"
                    label={t("Title")}
                    onValueChange={HandleChange}
                    value={Sevice.title}
                    valueEn={Sevice.titleEn}
                  />
                </FormGroup>
                <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
              </div>
            </div>
            <div id="mybodyFeature" className=" Aboutus-admin-service-list">
              {Data.map((data) => {
                return (
                  <div
                    style={{
                      direction: i18n.language === "en" ? "ltr" : "rtl",
                    }}
                  >
                    <div className=" FeaturesTitle">
                      {i18n.language === "en" ? data.titleEn : data.title}
                    </div>

                    <div className="dataForm">
                      {DateConvertor(data.postDate, i18n.language === "en")}
                    </div>
                    <UpdateDelete
                      data={data}
                      Updata={Updata}
                      Delete={Delete}
                      ComponentID="#mybodyFeature"
                    />
                  </div>
                );
              })}
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default FeaturedPosts;
