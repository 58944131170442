import React from "react";
import "./ProductBenefits.css";

import {
	Benefit,
	ProductText,
	ProductTextinputDefualt,
} from "../../../Admin.Product/Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
export interface ProductBenefit {
	title: string;
	description: string;
	icon: any;
}

interface Props {
	productBenefits?: Benefit[];
	productText: ProductText | any;
}

const ProductBenefits: React.FC<Props> = ({
	productBenefits = [],
	productText = { ...ProductTextinputDefualt },
}) => {
	const { i18n } = useTranslation();
	return (
		<>
			<section className="product__benefits">
				<div className="index__container product__section mb-5">
					<div className="row">
						<div className="product__section__header">
							<h1 className="product__section__title text__middle">
								{i18n.language === "en"
									? productText?.TitleEn
									: productText?.Title}
							</h1>
							<span className="product__section__sub__title">
								{ReactHtmlParser(
									i18n.language === "en"
										? productText?.DescriptionEn
										: productText?.Description
								)}
							</span>
						</div>
						<div className="benfits__cards__container row mx-2">
							{productBenefits.map((item, index) => {
								return (
									<div
										className="benfits__card col-lg-4 col-md-4 col-12 px-1 my-2"
										key={index}
									>
										<div className="card__image col-lg-12 col-md-12 col-sm-6 col-8">
											<img
												src={`${apiEndPoint}${item.Image}`}
												alt=""
											/>
										</div>
										<div className="card__description col-lg-8 col-md-8 col-sm-6 col-8">
											<h5>
												{i18n.language === "en"
													? item?.TitleEn
													: item?.Title}
											</h5>
											<p>
												{i18n.language === "en"
													? item?.DescriptionEn
													: item?.Description}
												&nbsp;
											</p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ProductBenefits;
