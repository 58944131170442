import { REQUEST } from "../../../Services/callAPI";
export const GET_POST = async () => {
  return await REQUEST({
    method: "get",
    url: "Post",
  });
};
export const UPDATE_POST = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Post",
    data: e,
  });
};
export const INSERT_POST = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Post",
    data: e,
  });
};
export const DELETE_POST = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Post/" + id,
  });
};
export const GET_POST_FEATURED = async () => {
  return await REQUEST({
    method: "get",
    url: "FeaturedPost",
  });
};
export const UPDATE_POST_FEATURED = async (e) => {
  return await REQUEST({
    method: "put",
    url: "FeaturedPost",
    data: e,
  });
};
export const INSERT_POST_FEATURED = async (e) => {
  return await REQUEST({
    method: "post",
    url: "FeaturedPost",
    data: e,
  });
};
export const DELETE_POST_FEATURED = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "FeaturedPost/" + id,
  });
};
