import { useEffect } from "react";

// Css
import "./App.css";
// import "./css/paper-dashboard.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../node_modules/popper.js/dist/popper";

import "devextreme/dist/css/dx.light.css";

// import "./App.css";

import AdminLayout from "./Layouts/Admin";
import { useTranslation } from "react-i18next";

import { Route, Switch } from "react-router-dom";
import UserLayOut from "./Layouts/User";
import { apiEndPoint } from "./Services/config.json";
import img2 from "./Assets/logomain.png";
import { REQUEST } from "./Services/callAPI";
function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
    window.onscroll = function () {
      document.documentElement.scrollTop > 0
        ? document
            .getElementsByTagName("body")[0]
            .classList.add("show__upper__logo")
        : document
            .getElementsByTagName("body")[0]
            .classList.remove("show__upper__logo");
    };

    let getHeaders = async () => {
      var link: any =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/png";
      link.rel = "shortcut icon";

      link.href = img2;
      document.getElementsByTagName("head")[0].appendChild(link);
      await REQUEST({
        method: "get",
        url: "ParagraphSection/page/main",
      })
        .then((res: any) => {
          res = res[0];
          // document.title = i18n.language === "en" ? res.titleEn : res.title;
        })
        .catch(() => {});
    };

    getHeaders();

    let url = window.location.href;
    if (
      !url.toString().includes("eg") &&
      !url.toString().includes("ly") &&
      !url.toString().includes("tr") &&
      !localStorage.getItem("Domain")
    ) {
      i18n.changeLanguage("en");
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const { latitude, longitude } = position.coords;
          localStorage.setItem(
            "Domain",
            JSON.stringify({
              x: latitude,
              y: longitude,
            })
          );
        },
        () => {
          localStorage.setItem(
            "Domain",
            JSON.stringify({
              x: 0,
              y: 0,
            })
          );
        }
      );
    }
    if (localStorage.getItem("Domain")) {
      let local: any = localStorage.getItem("Domain");
      let { x, y } = JSON.parse(local);
      let { x1, x2, y1, y2 } = {
        x1: 42.030013882448415,
        x2: 36.94554591737612,
        y1: 44.48768152254651,
        y2: 25.591198800385804,
      };

      if (x1 >= x && x2 <= x && y1 >= y && y2 <= y) {
        window.location.href = "https://medadsoft.com.tr";
      }
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("i18nextLng") !== "en" &&
      localStorage.getItem("i18nextLng") !== "ar" &&
      localStorage.getItem("i18nextLng") !== "tr"
    ) {
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  return (
    <div className="App">
      <Switch>
        <Route path="/admin" component={AdminLayout} />
        <Route path="/" component={UserLayOut} />
      </Switch>
    </div>
  );
}

export default App;
