import { useEffect, useState } from "react";
import { apiEndPoint } from "../../../../../Services/config.json";
//css
import "./ProgramsDefinations2.css";

import { TechnologyInterface } from "../../../../../Interfaces/Interfaces";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { GET_PROGRAMS_DEFINITION } from "../../../admin/Admin.Home.APi";

function ProgramsDefinations({ setLoading }) {
	const { i18n } = useTranslation();
	let [Data, setData] = useState<TechnologyInterface[]>([]);
	let fetch = async () => {
		GET_PROGRAMS_DEFINITION().then((x: any) => {
			setData(x);
			setLoading(false);
			document
				.getElementsByTagName("html")[0]
				.classList.remove("no__scroll");
		});
	};
	useEffect(() => {
		fetch();
	}, []);
	return (
		<>
			{Data &&
				Data.map((element, index) => {
					return (
						<div key={index} className="programsDefinationsWrapper">
							<div className="container">
								<div className="row  mx-auto programsDefinationsContainer">
									<div className="col-md-12 col-lg-5 programsDefinationsTextContainer">
										<div
											className="programsDefinationsTextContainer"
											style={{
												direction:
													i18n.language === "en"
														? "ltr"
														: "rtl",
											}}
										>
											<div className="programsDefinationsMainTitle">
												{i18n.language === "en"
													? element.titleEn
													: element.title}
											</div>
											<div className="programsDefinationsSubTitle">
												{i18n.language === "en"
													? element.subTitleEn
													: element.subTitle}
											</div>
											<div className="programsDefinationsExplanations">
												{ReactHtmlParser(
													i18n.language === "en"
														? element.descriptionEn
														: element.description
												)}
											</div>
											<ul
												className="programsDefinationsList"
												style={{
													direction:
														i18n.language === "en"
															? "ltr"
															: "rtl",
												}}
											>
												{element.programDefinitionList?.map(
													(ele, index) => {
														return (
															<li key={index}>
																<a
																	href={
																		ele.link
																	}
																	rel="noreferrer"
																	target="_blank"
																	className="list-text-prog-home"
																	style={{
																		width: "100%",
																	}}
																>
																	<div className="row">
																		<div
																			className="col-4"
																			style={{
																				width: "35px",
																				height: "20px",

																				margin: "2px 2px",
																			}}
																		>
																			<img
																				alt="1"
																				src={
																					typeof ele.imagePath ==
																					"string"
																						? `${apiEndPoint}${encodeURI(
																								ele.imagePath.replaceAll(
																									"\\",
																									"/"
																								)
																						  )}`
																						: ""
																				}
																				width={
																					"100%"
																				}
																				height={
																					"100%"
																				}
																			/>
																		</div>{" "}
																		<div
																			className="col"
																			style={{
																				margin: "2px 2px",
																				direction:
																					i18n.language ===
																					"en"
																						? "ltr"
																						: "rtl",
																				width: "100%",
																			}}
																		>
																			{i18n.language ===
																			"en"
																				? ele.titleEn
																				: ele.title}
																		</div>
																	</div>
																</a>
															</li>
														);
													}
												)}
											</ul>
										</div>
									</div>
									<div className="col-md-12 col-lg-7 programsDefinationsImageWrapper">
										<div className="mainCompouter">
											<div className="mainComputerFrame">
												<div
													className="mainComputerImage"
													style={{
														backgroundImage: `url(${apiEndPoint}${encodeURI(
															element.imagePath.replaceAll(
																"\\",
																"/"
															)
														)})`,
													}}
												>
													<div className="mainComputerImage"></div>
												</div>
											</div>
											<div className="mainComputerBase"></div>

											<div>
												{element.imagePath2 ? (
													<div className="secondaryComputer">
														<div className="secondaryComputerFrame">
															<div
																className="secondaryComputerImage"
																style={{
																	backgroundImage: `url(${apiEndPoint}${encodeURI(
																		element.imagePath2.replaceAll(
																			"\\",
																			"/"
																		)
																	)})`,
																}}
															></div>
														</div>
													</div>
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
		</>
	);
}

export default ProgramsDefinations;
