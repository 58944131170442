import React from "react";
import { useTranslation } from "react-i18next";
import { AdditionalInfo } from "../../../Admin.Product/Interfaces";
import "./ProductAdditionalInfo.css";
import ReactHtmlParser from "react-html-parser";
export interface ProductionAdditionalInfoItem {
	title: string;
	body: string;
}

interface Props {
	productionAdditionalInfo?: AdditionalInfo[];
}

const ProductAdditionalInfo: React.FC<Props> = ({
	productionAdditionalInfo = [],
}) => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<section className="product__section product__additional__info">
				<div className="index__container section-divider">
					<h1
						className="product__section__title text__middle w-100"
						style={{ fontWeight: 500 }}
					>
						{t("Controls Information")}
					</h1>
					<div className="product__additional__info__content">
						{productionAdditionalInfo.map((item, index) => {
							return (
								<div
									key={index}
									className="product__additional__info__item my-3 p-5 col-lg-4 col-md-4 col-sm-6 col-12"
								>
									<div className="title">
										{" "}
										{i18n.language === "en"
											? item?.TitleEn
											: item?.Title}
									</div>

									{ReactHtmlParser(
										i18n.language === "en"
											? item?.DescriptionEn
											: item?.Description
									)}
									{/* <p>{item.body}</p> */}
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</>
	);
};

export default React.memo(ProductAdditionalInfo);
