import React, { useEffect } from "react";

const PaymobResponse = (props) => {
	const keys = [
		"amount_cents",
		"created_at",
		"currency",
		"error_occured",
		"has_parent_transaction",
		"id",
		"integration_id",
		"is_3d_secure",
		"is_auth",
		"is_capture",
		"is_refunded",
		"is_standalone_payment",
		"is_voided",
		"order",
		"order.id",
		"owner",
		"pending",
		"source_data.pan",
		"source_data.sub_type",
		"source_data.type",
		"success",
	];

	useEffect(() => {
		let search = props.location.search.substring(1);
		let params = JSON.parse(
			'{"' +
				decodeURI(search)
					.replaceAll("%3A", ":")
					.replace(/"/g, '\\"')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}'
		);
		params.success == "true"
			? window.location.replace("#/paymentSuccess")
			: window.location.replace("#/PaymentDeclined");
	}, []);

	return null;
};

export default PaymobResponse;
