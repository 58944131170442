import { useTranslation } from "react-i18next";
import { apiEndPoint } from "../../../../Services/config.json";

const ImageSLider = ({ data }) => {
  const { i18n } = useTranslation();
  return (
    <div
      id="demo"
      className="carousel slide"
      data-interval="false"
      data-ride="carousel"
    >
      <div
        className="carousel-inner"
        style={{ maxWidth: "600px", margin: "auto" }}
      >
        {" "}
        {data &&
          data.map((ele, index) => {
            return (
              <div
                key={index}
                className={
                  index === 0 ? "carousel-item active" : "carousel-item"
                }
                style={{ height: "100%" }}
              >
                <div
                  className="row"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    alt="1"
                    style={{ margin: "auto" }}
                    className="col-12 slide-show-image"
                    src={
                      typeof ele.ImagePath === "string"
                        ? `${apiEndPoint}${encodeURI(
                            ele.ImagePath.replaceAll("\\", "/")
                          )}`
                        : ""
                    }
                    width={"100%"}
                    height={"100%"}
                  />

                  <div
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "20px",
                    }}
                    className="col-12"
                  >
                    {i18n.language === "en" ? ele?.TitleEn : ele?.Title}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <a
        className="carousel-control-prev rightleft"
        style={{ fontSize: "40px" }}
        href="#demo"
        data-slide="prev"
      >
        <i className="fas fa-arrow-left"></i>
      </a>
      <a
        className="carousel-control-next rightleft"
        href="#demo"
        data-slide="next"
      >
        <i className="fas fa-arrow-right"></i>
      </a>
      <a
        className=" carousel-control"
        href="#outerslider"
        data-slide="prev"
        style={{
          marginTop: "20px",
          fontSize: "30px",
        }}
      >
        {" "}
        <i className="fas fa-times"></i>
      </a>
      <ul
        className="carousel-indicators"
        style={{ margin: "auto", direction: "ltr" }}
      >
        {data &&
          data.map((ele, index) => {
            return (
              <li
                key={index}
                data-target="#demo"
                data-slide-to={index}
                className={index === 0 ? "active image-dots" : " image-dots"}
              ></li>
            );
          })}
      </ul>
    </div>
  );
};

export default ImageSLider;
