import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputType } from "../../../Enums";
import Input from "../../../SharedComponents/Input/Input";

import "./ContactUs.css";
import notify from "devextreme/ui/notify";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import UploadImageButton, {
  HTMLInputEvent,
} from "../../../SharedComponents/UploadImageButton/UploadImageButton";
import { useTranslation } from "react-i18next";
import { SUBMIT_FORM } from "./API.ContactUs";
import {
  ContactUsFormInterface,
  defualtContactUsFormInterface,
} from "../../../Interfaces/Interfaces";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";
function ContactUs() {
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState<any>([]);

  let handleGetImages = (event: HTMLInputEvent) => {
    let files: any = event.target.files;
    setImages((prevState) => {
      return [...prevState, ...files];
    });
  };

  let handleRemoveImage = (element: FileList | string) => {
    setImages((prevState) => prevState.filter((ele) => ele !== element));
  };

  let handleRemoveAllImages = () => {
    setImages([]);
  };
  let [values, setvalues] = useState<ContactUsFormInterface>(
    defualtContactUsFormInterface
  );
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  const DeprtementList = useMemo(() => {
    return [
      { id: 0, text: "Electronic software and systems" },
      { id: 1, text: "Mobile Apps" },
      { id: 2, text: "Websites" },
      { id: 3, text: "Technical support" },
      { id: 4, text: "Request a call" },
      { id: 5, text: "Other Services" },
    ];
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      let formData = new FormData();

      for (let [key, value] of Object.entries(values)) {
        formData.append(key.toString(), value);
      }

      for (var i = 0; i < images.length; i++) {
        formData.append("image", images[i]);
      }

      await SUBMIT_FORM(formData)
        .then((res: any) => {
          setvalues({ ...defualtContactUsFormInterface });
          setImages([]);
          notify(
            {
              message: t(
                "Send Successfully and we will connect with you soon as possible"
              ),
              width: 600,
            },
            "success",
            6000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, images, t]
  );
  return (
    <div className="no__background">
      <Navbar />
      <div className="absolute__from__nav colorGrey ">
        <main>
          <div className="contactUsWrapper">
            <div className="contactUsContainer">
              <form
                onSubmit={submit}
                className="contactUsWidnow"
                style={{
                  direction: i18n.language === "en" ? "ltr" : "rtl",
                }}
              >
                <div
                  className="signInText"
                  style={{ color: "#3a7498", padding: "35px 0px" }}
                >
                  {t("Contact Us")}
                </div>

                {/* <div className="logoContainer"></div> */}
                <div className="row contactUsInputsContainer">
                  <div className="col-md-6 col-sm-12">
                    <Input
                      label={t("First Name")}
                      isRequired={true}
                      type={InputType.text}
                      name="firstName"
                      onChange={HandleChange}
                      value={values.firstName}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Input
                      label={t("Last Name")}
                      isRequired={true}
                      type={InputType.text}
                      name="lastName"
                      onChange={HandleChange}
                      value={values.lastName}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Input
                      label={t("E-mail")}
                      isRequired={true}
                      type={InputType.email}
                      onChange={HandleChange}
                      name={"email"}
                      value={values.email}
                    />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="inputField">
                      <select
                        required
                        name="serviceType"
                        value={values.serviceType}
                        onChange={HandleChange}
                        style={{
                          border: "1px solid #3a7498",
                          color: "#3a7498",
                        }}
                      >
                        {DeprtementList.map((data) => {
                          return (
                            <option
                              style={{
                                color: "#3a7498",
                              }}
                              value={data.id}
                            >
                              {t(data.text)}
                            </option>
                          );
                        })}
                      </select>
                      <label className="invalidLabel">
                        {t("Service Type")}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <Input
                      label={t("Phone Number")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      name="phoneNumber"
                      value={values.phoneNumber}
                    />
                  </div>
                  <div>
                    <UploadImageButton
                      isMultiple={true}
                      handleGetImages={handleGetImages}
                      handleRemoveImage={handleRemoveImage}
                      handleRemoveAllImages={handleRemoveAllImages}
                      imagesFiles={images}
                    />
                  </div>

                  <div className="col-12">
                    <Input
                      label={t("Image Description")}
                      isRequired={false}
                      type={InputType.text}
                      onChange={HandleChange}
                      name="imageDescription"
                      value={values.imageDescription}
                    />
                  </div>
                  <div className="col-12">
                    <div className="inputField">
                      <textarea
                        name="message"
                        value={values.message}
                        required={true}
                        onChange={HandleChange}
                      ></textarea>
                      <label htmlFor="">{t("Message")}</label>
                    </div>
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button className="btn signInButton" type={"submit"}>
                    {t("Send")}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default ContactUs;
