import React, { useCallback } from "react";

import { Card, FormGroup, CardBody, CardHeader } from "reactstrap";
import { apiEndPoint } from "../../../../Services/config.json";

import notify from "devextreme/ui/notify";

import { useTranslation } from "react-i18next";

import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { ProductImages } from "../../Interfaces";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import {
  INSERT_PRODUCT_IMAGES,
  UPDATE_PRODUCT_IMAGES,
} from "./ProgramImagesApi";
import ButtonsSaveDelete from "../../../../SharedComponents/Loaders/ButtonsSaveDelete";
interface Props {
  data: ProductImages | undefined;
  HandleChange: any;
  Delete: any;
  insertAction: any;
}

const ProgramImageForm: React.FC<Props> = ({
  data,
  HandleChange,
  Delete,
  insertAction,
}) => {
  const { t, i18n } = useTranslation();

  let handleGetImages = (event) => {
    let files: any = event.target.files;

    HandleChange(data?.Id, "ImagePath", files[0]);
  };

  let handleRemoveImage = useCallback(
    (element) => {
      HandleChange(data?.Id, "ImagePath", "");
    },
    [data, HandleChange]
  );
  let submit = useCallback(
    async (e) => {
      if (!data?.ImagePath || !data.Title || !data.TitleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      let formData = new FormData();

      for (let [key, value] of Object.entries(data)) {
        formData.append(key.toString(), value);
      }
      if (data.Id > 0) {
        await UPDATE_PRODUCT_IMAGES(formData)
          .then((res: any) => {
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_PRODUCT_IMAGES(formData)
          .then((res: any) => {
            insertAction(res);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [data, insertAction, t]
  );
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <h4> {t("Parteners")}</h4>
        </CardHeader>
        <CardBody>
          <div
            className="row"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <div className="col-lg-8">
              <form onSubmit={submit}>
                <FormGroup>
                  <InputTwoLanguages
                    id="Title"
                    label={t("Title")}
                    onValueChange={(value, id) => {
                      HandleChange(data?.Id, id, value);
                    }}
                    value={data?.Title}
                    valueEn={data?.TitleEn}
                  />
                </FormGroup>

                <FormGroup className="image__button">
                  <label>{t("Image")}</label>
                  <UploadImageButton
                    isMultiple={false}
                    handleGetImages={handleGetImages}
                    handleRemoveImage={handleRemoveImage}
                    imagesFiles={
                      data?.ImagePath
                        ? [
                            typeof data?.ImagePath == "string"
                              ? apiEndPoint + data?.ImagePath
                              : data?.ImagePath,
                          ]
                        : []
                    }
                  />
                </FormGroup>

                <ButtonsSaveDelete
                  buttondisable={data?.Id === 0}
                  Delete={() => Delete(data?.Id)}
                  onsubmit={submit}
                />
              </form>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ProgramImageForm;
