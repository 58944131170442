import React from "react";
import "./Input.css";
import { InputType } from "../../Enums";
import { useTranslation } from "react-i18next";
interface Props {
	isRequired: boolean;
	label: string;
	labelId?: string;
	type: InputType;
	value?: string;
	onChange?: any;
	name?: string;
	error?: string;
	onBlur?: any;
}

const Input: React.FC<Props> = ({
	type,
	isRequired,
	label,
	labelId,
	value,
	onChange,
	name,
	error,
	onBlur,
}) => {
	const { i18n } = useTranslation();
	// add ar class to div.inputField to style arabic
	return (
		<>
			<div
				className="inputField"
				style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
			>
				<input
					autoComplete="off"
					id={labelId}
					type={type}
					onChange={onChange}
					required={isRequired}
					minLength={type === InputType.password ? 5 : undefined}
					value={value}
					name={name}
					onBlur={onBlur}
				/>
				<label
					style={{
						direction: i18n.language === "en" ? "ltr" : "rtl",
					}}
					htmlFor={labelId}
				>
					{label}
				</label>
			</div>
			<label style={{ color: "#ff6666" }}>{error}</label>
		</>
	);
};

export default Input;
