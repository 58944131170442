import { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { InputType } from "../../Enums";

import Input from "../../SharedComponents/Input/Input";
import Navbar from "../../SharedComponents/Navbar/Navbar";
import { CHANGE_PASSWORD } from "./ChangePassword.Api";

import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import queryString from "query-string";

import Footer from "../1.Home/user/Components/Footer/user/Footer";
function ChangePassword(props) {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  let [values, setvalues] = useState({
    email: "",
    token: "",
    ConfirmPassword: "",
    password: "",
  });

  useEffect(() => {
    let value = queryString.parse(props.location.search);
    if (value.token && value.email) {
      setvalues((prev) => ({
        ...prev,
        token: b64_to_utf8(value.token),
        email: value.email,
      }));
    }
  }, [props.location.search]);
  function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.password || !values.ConfirmPassword) {
        notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        return;
      }
      //  values.token =
      //     "CfDJ8FRM8Ry4WIVAv9kn1IBrDpu9a6BFihq9sl6vYNDvViT+v8EDSUzQzYVdX58dIEV8E5oGdMEPllIBQgSRq715mYHGEWGe+mHaMR2q5bmz9SZZBKUrbwWkzssJ9v4yG3oF4retqr/Z14G7xKD+OJoUFRQR/SvO1IQK8v2PkRlcO+FyzmPsVQD4vK2ddqhyI00OLEL2D007DSd2RKb9ZwnVkImtxZtgyy2kPJGnqlieKp9y";
      await CHANGE_PASSWORD(values)
        .then((res: any) => {
          notify(
            { message: t("Sign in successfully"), width: 600 },
            "success",
            3000
          );
          history.push("/log-in");
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, history, t]
  );
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="no__background">
      <Navbar />
      <div className="absolute__from__nav colorGrey ">
        <main>
          <div className="loginWrapper">
            <form onSubmit={submit} className="loginContainer">
              <div
                className="loginWidnow"
                style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
              >
                <div className="signInText">{t("RESET PASSWORD")}</div>
                <div className="loginInputsContainer">
                  <div>
                    <Input
                      label={t("Password")}
                      isRequired={true}
                      type={InputType.password}
                      onChange={HandleChange}
                      value={values.password}
                      name="password"
                    />
                  </div>
                  <div>
                    <Input
                      label={t("Confirm Password")}
                      isRequired={true}
                      type={InputType.password}
                      onChange={HandleChange}
                      value={values.ConfirmPassword}
                      name="ConfirmPassword"
                    />
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button className="btn signInButton">{t("Save")}</button>
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <Link to="/register" className="btn CreateAnAccountButton">
                    {t("CREATE AN ACCOUNT")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default ChangePassword;
