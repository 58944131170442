import { REQUEST } from "../../../../Services/callAPI";

export const UPDATE_PRODUCT_IMAGES = async (e) => {
  return await REQUEST({
    method: "put",
    url: "ProductImages",
    data: e,
  });
};
export const DELETE_PRODUCT_IMAGES = async (e) => {
  return await REQUEST({
    method: "delete",
    url: "ProductImages/" + e,
  });
};

export const INSERT_PRODUCT_IMAGES = async (e) => {
  return await REQUEST({
    method: "post",
    url: "ProductImages",
    data: e,
  });
};
