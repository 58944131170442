import React, { useEffect } from "react";
import Navbar from "../../SharedComponents/Navbar/Navbar";
import "./paymentSuccess.css";

const PaymentSuccess = (props) => {
	const keys = [
		"amount_cents",
		"created_at",
		"currency",
		"error_occured",
		"has_parent_transaction",
		"id",
		"integration_id",
		"is_3d_secure",
		"is_auth",
		"is_capture",
		"is_refunded",
		"is_standalone_payment",
		"is_voided",
		"order",
		// "order.id",
		"owner",
		"pending",
		"source_data.pan",
		"source_data.sub_type",
		"source_data.type",
		"success",
	];

	useEffect(() => {
		// let search = props.location.search.substring(1);
		// let params = JSON.parse(
		// 	'{"' +
		// 		decodeURI(search)
		// 			.replaceAll("%3A", ":")
		// 			.replace(/"/g, '\\"')
		// 			.replace(/&/g, '","')
		// 			.replace(/=/g, '":"') +
		// 		'"}'
		// );
	}, []);

	return (
		<>
			<div className="no__background">
				<Navbar />

				<div className="absolute__from__nav">
					<main>
						<div className="paymentSuccess__wrapper">
							<div class="container">
								<div class="row">
									<div class="col-md-6 mx-auto mt-5">
										<div class="payment">
											<div class="payment_header">
												<div class="check">
													<i
														class="fa fa-check"
														aria-hidden="true"
													></i>
												</div>
											</div>
											<div class="content">
												<h1>Payment Success !</h1>
												<p>
													Your order is confirmed. You
													will recive and order
													confirmation email/sms
													shortly with the expected
													delivary date for your
													product.
												</p>
												<a href="#/home">Go to Home</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default PaymentSuccess;
