import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import notify from "devextreme/ui/notify";
import InputTwoLanguages from "../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import MapComponents from "../../../../../SharedComponents/MapComponents/MapComponents";

import Joi from "joi";
import { validateForm } from "../../../../../SharedComponents/Valdiation_form/ValidationForm";
import {
  GET_BRANCHES,
  UPDATE_BRANCHES,
  DELETE_BRANCHES,
  INSERT_BRANCHES,
} from "../Map.Api";
import { LoadPanel } from "devextreme-react/load-panel";

import { useTranslation } from "react-i18next";

import { BranchInterface } from "../../../../../Interfaces/Interfaces";
import ButtonsComponent from "../../../../../SharedComponents/Loaders/ButtonsComponent";
const Map = () => {
  const { t } = useTranslation();
  const schema = useMemo(() => {
    return {
      title: Joi.string().required(),
      titleEn: Joi.string().required(),
      description: Joi.string().required(),
      descriptionEn: Joi.string().required(),
    };
  }, []);
  const [show, setShow] = useState<BranchInterface[]>([]);
  const [MainData, setMainData] = useState<BranchInterface[]>([]);
  async function fetchMyAPI() {
    let x: any = await GET_BRANCHES();
    setShow(
      x.map((da) => {
        return { ...da, show: false, styleclass: "" };
      })
    );
    setMainData(
      x.map((da) => {
        return { ...da, show: false, styleclass: "" };
      })
    );
  }

  useEffect(() => {
    fetchMyAPI();
  }, []);

  let currentValues = useRef<BranchInterface>({
    id: -1,
    title: "",
    titleEn: "",
    idx: "",
    description: "",
    descriptionEn: "",
    left: 0,
    top: 0,
    image: "",
    defualt: false,
    show: false,
    styleclass: "",
    longitude: 0,
    latitude: 0,
  });

  let [Head, setHead] = useState<BranchInterface>(currentValues.current);
  const HandleChange = useCallback((value, id) => {
    setHead((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let getBranch = useCallback(
    (id: number) => {
      return show.findIndex((x) => x.id === id);
    },
    [show]
  );
  let saveHandle = useCallback(
    async (e) => {
      e.preventDefault();
      let err = validateForm(Head, schema);
      if (Object.keys(err).length !== 0) {
        notify({ message: "fill the inputs", width: 600 }, "error", 3000);
        return;
      }

      if (Head.id > 0) {
        await UPDATE_BRANCHES({
          ...Head,
          longitude: parseFloat(Head.longitude.toString()),
          latitude: parseFloat(Head.latitude.toString()),
        })
          .then((res: any) => {
            show[getBranch(Head.id)] = {
              ...res,
              show: true,
              styleclass: "PointTransction",
            };
            setShow([...show]);
            setMainData([...show]);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_BRANCHES({
          ...Head,
          longitude: parseFloat(Head.longitude.toString()),
          latitude: parseFloat(Head.latitude.toString()),
        })
          .then((res: any) => {
            show[getBranch(Head.id)] = {
              ...res,
              show: true,
              styleclass: "PointTransction",
            };
            setShow([...show]);
            setMainData([...show]);
            setHead({ ...res, image: [] });
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
            setstateload(false);
          });
      }
    },
    [show, Head, t, getBranch, schema]
  );
  let NewHandle = () => {
    let x = show;
    x.push({ ...currentValues.current, top: 10, left: 51, id: 0 });

    setShow(
      x.map((data) => {
        if (0 === data.id) {
          return { ...data, show: true, styleclass: "PointTransction" };
        } else {
          return { ...data, show: false, styleclass: "" };
        }
      })
    );
    setHead({ ...currentValues.current, top: 10, left: 51, id: 0 });
  };
  let DeleteHandle = useCallback(async () => {
    await DELETE_BRANCHES(Head.id)
      .then(() => {
        let index = MainData.findIndex((x) => x.id === Head.id);
        MainData.splice(index, 1);
        setMainData(MainData);
        setShow([...MainData]);
        setHead(currentValues.current);
      })
      .catch(() => {
        notify({ message: "try again", width: 600 }, "error", 3000);
      });
  }, [MainData, Head]);

  const CloseAllBranch = useCallback(
    (id: number) => {
      let maindataselecteditem = MainData.map((data) => {
        if (id === data.id) {
          return { ...data, show: true, styleclass: "PointTransction" };
        } else {
          return { ...data, show: false, styleclass: "" };
        }
      });
      setShow([...maindataselecteditem]);
      setHead({ ...maindataselecteditem[getBranch(id)] });
    },
    [MainData, getBranch]
  );
  const position = { of: "#mapcomp" };

  let [stateload, setstateload] = useState(false);
  function hideLoadPanel() {
    setstateload(false);
  }

  return (
    <Card id="mybody" className="card-user">
      <CardHeader>
        <h4> {t("Branches")}</h4>
      </CardHeader>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        onHiding={hideLoadPanel}
        visible={stateload}
        shading={true}
        showPane={false}
      />
      <CardBody>
        <form onSubmit={saveHandle}>
          <div id="mapcomp">
            <FormGroup>
              <InputTwoLanguages
                id="title"
                label={t("Title")}
                onValueChange={HandleChange}
                value={Head.title}
                valueEn={Head.titleEn}
              />
            </FormGroup>
            <FormGroup>
              <InputTwoLanguages
                id="description"
                label={t("Description")}
                onValueChange={HandleChange}
                value={Head.description}
                valueEn={Head.descriptionEn}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="isActive" className="d-block">
                {t("Main")}
              </label>
              <input
                style={{
                  width: "40px",
                  height: "40px",
                }}
                type="checkbox"
                checked={Head.defualt}
                id="defualt"
                onChange={(e) => HandleChange(e.target.checked, e.target.id)}
              />
            </FormGroup>
            <FormGroup>
              <label>latitude</label>
              <Input
                style={{ fontFamily: "Tajawal", height: "40px" }}
                id={"latitude"}
                value={Head.latitude}
                onChange={(e) =>
                  HandleChange(parseFloat(e.target.value), e.target.id)
                }
                type="number"
              />
            </FormGroup>
            <FormGroup>
              <label>longitude</label>
              <Input
                style={{ fontFamily: "Tajawal", height: "40px" }}
                id={"longitude"}
                value={Head.longitude}
                onChange={(e) =>
                  HandleChange(parseFloat(e.target.value), e.target.id)
                }
              />
            </FormGroup>
            <ButtonsComponent New={NewHandle} buttondisable={Head.id === 0} />
            <Button
              className="btn btn btn-success col-12"
              disabled={Head.id > 0 ? false : true}
              type="button"
              onClick={useCallback(async () => {
                setstateload(true);
                await DeleteHandle();
                setstateload(false);
              }, [DeleteHandle])}
            >
              {t("Delete")}
            </Button>

            <div
              className="row allimagearrows"
              style={{
                direction: "ltr",
              }}
            >
              <div className="ArrowsContanier col-6 row">
                <i
                  onClick={() => {
                    if (Head.id === -1) {
                      return;
                    }
                    let cx = show;
                    cx[getBranch(Head.id)].top = cx[getBranch(Head.id)].top - 1;
                    setHead({ ...Head, top: cx[getBranch(Head.id)].top });
                    setShow([...cx]);
                  }}
                  className="col-12 fas fa-arrow-up"
                ></i>

                <i
                  onClick={() => {
                    if (Head.id === -1) {
                      return;
                    }
                    let cx = show;
                    cx[getBranch(Head.id)].left =
                      cx[getBranch(Head.id)].left - 1;
                    setHead({ ...Head, left: cx[getBranch(Head.id)].left });
                    setShow([...cx]);
                  }}
                  className=" col-6 fas fa-arrow-left"
                ></i>

                <i
                  onClick={() => {
                    if (Head.id === -1) {
                      return;
                    }
                    let cx = show;
                    cx[getBranch(Head.id)].left =
                      cx[getBranch(Head.id)].left + 1;
                    setHead({ ...Head, left: cx[getBranch(Head.id)].left });
                    setShow([...cx]);
                  }}
                  className=" col-6 fas fa-arrow-right"
                ></i>
                <i
                  className=" col-6 fas fa-arrow-down"
                  onClick={() => {
                    if (Head.id === -1) {
                      return;
                    }
                    let cx = show;
                    cx[getBranch(Head.id)].top = cx[getBranch(Head.id)].top + 1;
                    setHead({ ...Head, top: cx[getBranch(Head.id)].top });
                    setShow([...cx]);
                  }}
                ></i>
              </div>
            </div>
          </div>
          <FormGroup>
            <div
              className="Map"
              style={{
                direction: "ltr",
              }}
            >
              <div className="FullWidthContainer">
                <div className="MapContainer">
                  <MapComponents />
                  <div className="Points">
                    {show.map((da, index) => {
                      return (
                        <div key={index}>
                          <div
                            key={index}
                            className={"Point " + da.styleclass}
                            id={"x" + da.id}
                            onClick={() => CloseAllBranch(da.id)}
                            style={{
                              left: da.left + "%",
                              top: da.top + "%",
                            }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
};

export default Map;
