import { useCallback, useEffect, useMemo, useState } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import notify from "devextreme/ui/notify";

import { useTranslation } from "react-i18next";
import { BuyFormInterface } from "../../../Interfaces/Interfaces";

import { DateConvertor } from "../../../SharedComponents/Loaders/DateFunction";
import UpdateDelete from "../../../SharedComponents/Loaders/UpdateDelete";
import { DELETE_BUY, GET_BUY } from "../user/Buy.Api";
const Buy = () => {
  const { t, i18n } = useTranslation();
  let [Posts, setPosts] = useState<BuyFormInterface[]>([]);

  let [Current, setCurrent] = useState<number>(0);
  let [TotalCount, setTotalCount] = useState<number>(0);
  let fetch = async () => {
    let x: any = await GET_BUY(0);
    setCurrent(x.currentCount);
    setTotalCount(x.totalCount);
    setPosts(x.data);
  };
  useEffect(() => {
    fetch();
  }, []);

  let Delete = useCallback(
    async (element) => {
      await DELETE_BUY(element)
        .then(() => {
          let x = Posts.filter(function (el) {
            return el.id !== element;
          });

          setPosts(x);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Posts, t]
  );

  const DeprtementList = useMemo(() => {
    return [
      { id: 0, text: "Electronic software and systems" },
      { id: 1, text: "Mobile Apps" },
      { id: 2, text: "Websites" },
      { id: 3, text: "Technical support" },
      { id: 4, text: "Request a call" },
      { id: 5, text: "Other Services" },
    ];
  }, []);
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Card className="card-user">
        <CardHeader>
          <h4>{t("Buy")} </h4>
        </CardHeader>
        <CardBody>
          {Posts.map((data, index) => {
            return (
              <div key={index} style={{ padding: "20px" }}>
                <div
                  className="row "
                  style={{
                    border: "0.5px solid #e69a5c",
                    borderRadius: "20px",
                    padding: "20px",
                  }}
                >
                  <div className="col-4 titleLatestadminForm ">
                    {t("First Name")} : {data.firstName}
                    <br />
                    {t("Last Name")} : {data.lastName}
                    <br />
                    {t("E-mail")} : {data.email}
                    <br />
                    {t("Phone Number")} : {data.phoneNumber}
                    <br />
                  </div>

                  <div className="col-8">
                    <div className=" titleLatestadminForm">
                      {t("Industry")} : {data.industry}
                      <br />
                      {t("Company Name")} : {data.companyName}
                      <br />
                      {t("Service Type")}:{" "}
                      {t(DeprtementList[data.programType].text)}
                      <br />
                      {t("Note")} : {data.note}
                    </div>
                    <div className=" dataForm">
                      {DateConvertor(data.buyDate, i18n.language === "en")}
                    </div>
                    <UpdateDelete
                      data={data}
                      withoutUpdate
                      Updata={() => {}}
                      Delete={Delete}
                      ComponentID="#mybodyTeam"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="morePosts">
            {TotalCount !== Current ? (
              <button
                type="button"
                onClick={async () => {
                  let x: any = await GET_BUY(Current);
                  setCurrent(x.currentCount);
                  setTotalCount(x.totalCount);
                  setPosts(x.data);
                }}
                className="btn btn-outline-dark "
              >
                {t("More Posts")}
              </button>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Buy;
