import { useEffect, useState } from "react";
import {
  defualtParagraphSectionInterface,
  ParagraphSectionInterface,
} from "../../../Interfaces/Interfaces";

import HomeNews from "./Components/HomeNews/HomeNews";
import "./Admin.Home.css";
import ProgramDefinitions from "./Components/ProgramDefinition/ProgramDefinitions";

import Parteners from "./Components/Parteners/Parteners";
import Header1 from "./Components/HeaderTextParagraphes/Header";
import Header2 from "./Components/HeaderTextParagraphes/Header2";
import PartenersText from "./Components/Parteners/PartenersText";
import { GET_SECTION } from "../../12.TeamMembers/admin/Admin.Team.Api";
import { useTranslation } from "react-i18next";
import HomeProdct from "./Components/HeaderTextParagraphes/HomeProducts";

const AdminHome = () => {
  const { i18n } = useTranslation();
  let [paragraph, setparagraph] = useState<ParagraphSectionInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_SECTION("Home");
    setparagraph(x);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div
      className="content"
      style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
    >
      <Header1
        dataTitle={
          paragraph.find((x) => x.index === 0)
            ? paragraph.find((x) => x.index === 0)
            : defualtParagraphSectionInterface
        }
      />
      <Header2
        dataTitle={
          paragraph.find((x) => x.index === 1)
            ? paragraph.find((x) => x.index === 1)
            : defualtParagraphSectionInterface
        }
      />
      <HomeProdct
        dataTitle={
          paragraph.find((x) => x.index === 4)
            ? paragraph.find((x) => x.index === 4)
            : defualtParagraphSectionInterface
        }
      />
      <HomeNews
        dataTitle={
          paragraph.find((x) => x.index === 2)
            ? paragraph.find((x) => x.index === 2)
            : defualtParagraphSectionInterface
        }
      />


      
      <ProgramDefinitions />

      <PartenersText
        dataTitle={
          paragraph.find((x) => x.index === 3)
            ? paragraph.find((x) => x.index === 3)
            : defualtParagraphSectionInterface
        }
      />
      <Parteners />
    </div>
  );
};

export default AdminHome;
