import React from "react";
import { useTranslation } from "react-i18next";
import FeaturedPosts from "./Components/FeaturedPosts";
import LatestPosts from "./Components/LatestPosts";

const AdminNews = () => {
  const { i18n } = useTranslation();
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <LatestPosts />
      <FeaturedPosts />
    </div>
  );
};

export default AdminNews;
