import { Button } from "reactstrap";

import { useTranslation } from "react-i18next";
interface props {
  New?: any;
  buttondisable?: any;
}
const ButtonsComponent = ({ New, buttondisable }: props) => {
  //let [loadIndicatorVisible, setloadIndicatorVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <div className="row">
        <div className="col">
          <Button
            className="btn btn btn-success col-12"
            // disabled={!isValueChanged}
            //  disabled={loadIndicatorVisible}
            type="submit"
          >
            <span className="dx-button-text">
              {
                //loadIndicatorVisible ? t("Loading") :
                t("Save")
              }
            </span>
          </Button>
        </div>
        <div className="col">
          <Button
            className="btn btn btn-success col-12"
            onClick={New}
            disabled={buttondisable}
            // onClick={saveHandle}
          >
            {t("New")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ButtonsComponent;
