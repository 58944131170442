import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DarkSection } from "../../../Admin.Product/Interfaces";
import "./ProductDarkSection.css";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
interface Props {
  productDarkSection?: DarkSection;
}

const ProductDarkSection: React.FC<Props> = ({ productDarkSection }) => {
  const { i18n } = useTranslation();
  let history = useHistory();
  const NavToBuy = useCallback(() => {
    history.push("/buy");
  }, [history]);
  return (
    <>
      <section className="product__section product__dark__section">
        <div className="content">
          <h2 className="sys-h-align-center sys-max-width-lg-85">
            {i18n.language === "en"
              ? productDarkSection?.TitleEn
              : productDarkSection?.Title}
          </h2>
          <div />
          {ReactHtmlParser(
            i18n.language === "en"
              ? productDarkSection?.DescriptionEn
              : productDarkSection?.Description
          )}
          {/* dangerouslySetInnerHTML={{
              __html:
                i18n.language === "en"
                  ? productDarkSection?.DescriptionEn
                  : productDarkSection?.Description,
            }}*/}

          {/* <p className="sys-h-align-center sys-max-width-lg-85">
						If you are new to third-party controls, take a moment to
						review the broad range of products we offer for the VCL
						platform and see if our tools can deliver the
						functionality you require. We are here to help and ready
						to assist you in any way we can. If you've used
						third-party controls in the past, and have been
						disappointed by quality, performance or reliability, we
						ask that you give our products a chance and see for
						yourself why we are different. Our support team is on
						call and ready to discuss your needs and focus on your
						requirements. We know how difficult it is to invest in
						tools again once you’ve been burned by others, so our
						commitment to you is simple. We’ll do everything we can
						to earn your confidence and your business. Tell us how
						we can help.
					</p> */}
          {/* <h3 className="sys-h-align-center sys-max-width-lg-85">
						Together, we can do amazing things.
					</h3> */}
          <p className="sys-h-align-center sys-max-width-lg-85">
            <button className="try__button px-5 py-3" onClick={NavToBuy}>
              {i18n.language === "en"
                ? productDarkSection?.ButtonTitleEn
                : productDarkSection?.ButtonTitle}
            </button>
          </p>
        </div>
      </section>
    </>
  );
};

export default ProductDarkSection;
