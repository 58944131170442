import { useEffect, useState } from "react";
//css
import "./CategoriesItemsDefination.css";

import { useTranslation } from "react-i18next";
import { apiEndPoint } from "../../../../../Services/config.json";
import { PRODUCT_DEMO } from "../../AP.Demos";

import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";
function CategoriesItemsDefination() {
  const { i18n } = useTranslation();
  interface Product {
    categoryId: number;
    domainId: number;
    iconPath: string;
    id: number;
    isActive: Boolean;
    productDarkSection: any;
    productDemo: ProductDemo;
    productHeader: ProductHeader;
    productName: string;
    productNameEn: string;
    productQuestions: null;
    rank: number;
    productIdentity: any;
  }
  interface ProductDemo {
    description: string;
    descriptionEn: string;
    imagePath: string;
    productId: number;
    tip: string;
    tipEn: string;
  }
  interface ProductHeader {
    demoVideoUrl: string;
    imagePath: string;
    productId: number;
    subTitle: string;
    subTitleEn: string;
    title: string;
    titleEn: string;
  }
  let [data, setdata] = useState<Product[]>([]);
  let fetch = async () => {
    let x: any = await PRODUCT_DEMO();
    setdata(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="CategoriesItemsDefinationWrapper">
      <div className="CategoriesItemsDefinationContainer container row">
        {data.map((da, index) => {
          return (
            <>
              <div
                className=" col-sm-12 col-md-6 col-lg-4 "
                style={{ padding: "30px 0 " }}
              >
                <div className="row m-0">
                  <NavLink
                    to={`/product/${da?.productIdentity?.id}`}
                    key={index}
                  >
                    <div className="CategoryHeader  category_product_list_demo">
                      <div className="CategoryTitle">
                        {" "}
                        {i18n.language === "en"
                          ? da?.productIdentity?.productNameEn
                          : da.productName}
                      </div>
                    </div>
                  </NavLink>
                  <div className="categoryItemContainer ">
                    <div className="categoryItemTitle">
                      {i18n.language === "en"
                        ? da.productDemo.tipEn
                        : da.productDemo.tip}
                    </div>
                    <div
                      className="categoryItemImage"
                      style={{
                        backgroundImage: `url(${apiEndPoint}${encodeURI(
                          da.productDemo.imagePath.replaceAll("\\", "/")
                        )})`,
                      }}
                    ></div>
                    <div className="categoryItemBody">
                      {ReactHtmlParser(
                        i18n.language === "en"
                          ? da.productDemo.descriptionEn
                          : da.productDemo.description
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default CategoriesItemsDefination;

/*<div className="categoryItemContainer col-sm-12 col-md-6 col-lg-4 ">
            <div className="categoryItemTitle">
              Outlook-Inspired Application
            </div>
            <div className="categoryItemImage"></div>
            <div className="categoryItemBody">
              Build apps with the UI elements your customers have come to expect
              from the world's most popular software suite.
            </div>
            <a className="categoryItemRunDemo">Run Demo</a>
            <div className="categoryItemHint">
              Requires installation of WinForms Subscription.
            </div>
            <a className="categoryItemDownload">
              Download WinForm Subscription
            </a>
          </div>

          <div className="categoryItemContainer col-sm-12 col-md-6 col-lg-4 ">
            <div className="categoryItemTitle">
              Outlook-Inspired Application
            </div>
            <div className="categoryItemImage"></div>
            <div className="categoryItemBody">
              Build apps with the UI elements your customers have come to expect
              from the world's most popular software suite.
            </div>
            <a className="categoryItemRunDemo">Run Demo</a>
            <div className="categoryItemHint">
              Requires installation of WinForms Subscription.
            </div>
            <a className="categoryItemDownload">
              Download WinForm Subscription
            </a>
          </div>

          <div className="categoryItemContainer col-sm-12 col-md-6 col-lg-4 ">
            <div className="categoryItemTitle">
              Outlook-Inspired Application
            </div>
            <div className="categoryItemImage"></div>
            <div className="categoryItemBody">
              Build apps with the UI elements your customers have come to expect
              from the world's most popular software suite.
            </div>
            <a className="categoryItemRunDemo">Run Demo</a>
            <div className="categoryItemHint">
              Requires installation of WinForms Subscription.
            </div>
            <a className="categoryItemDownload">
              Download WinForm Subscription
            </a>
</div>*/
