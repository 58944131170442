import React, { useState, useCallback, useMemo } from "react";
// import productHeaderImg from "../../../../Assets/Product/product-header.jpg";
import RequestDemoModal from "../../Components/RequestDemoModal/user/RequestDemoModal";
import "./productHeader.css";
import ProductWatchDemoVideoModal from "./../ProductWatchDemoVideoModal/ProductWatchDemoVideoModal";
import PageHeader from "../../../../SharedComponents/PageHeader/PageHeader";
import { Header } from "../../../Admin.Product/Interfaces";
import { apiEndPoint } from "../../../../Services/config.json";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "react-awesome-slider/dist/styles.css";

import ImageSLider from "./ImageSLider";
interface Props {
  productHeader?: Header;
  id: any;
  Demo: any;
  productImages: any;
}

const ProductHeader: React.FC<Props> = ({
  productHeader,
  id,
  Demo,
  productImages,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [showRequestDemoModal, setShowRequestDemoModal] = useState(false);

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleRequestDemoClick: () => void = useCallback(() => {
    /*	if (!localStorage.getItem("user")) {
			localStorage.setItem(
				"lastpage",
				JSON.stringify({ url: window.location.href })
			);
			history.push("/log-in");
		}*/
    setShowRequestDemoModal(true);
  }, []);

  const handleRequestDemoClose: () => void = useCallback(() => {
    setShowRequestDemoModal(false);
  }, []);

  const handleVideoClick: () => void = useCallback(() => {
    setShowVideoModal(true);
  }, []);

  const handleVideoClose: () => void = useCallback(() => {
    setShowVideoModal(false);
  }, []);

  //#region Transformation

  // background image url not working with url directlry we need to transform it to "http://www.example.com/Uplode/image.jpg"
  let imageUrl = useMemo(() => {
    let url: string = productHeader?.Image;
    if (url) url = url.replaceAll("\\", "/");
    return `${apiEndPoint}${encodeURI(url)}`;
  }, [productHeader?.Image]);

  // video url = https://www.youtube.com/watch?v=QG2hPiTpaWU
  // we convert it to  => QG2hPiTpaWU
  let videoId = useMemo(() => {
    let video_id = productHeader?.DemoVideoUrl.split("v=")[1];
    if (video_id) {
      let ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
    }
    return video_id;
  }, [productHeader?.DemoVideoUrl]);

  //#region

  return (
    <>
      <RequestDemoModal
        id={id}
        visable={showRequestDemoModal}
        handleClose={handleRequestDemoClose}
      />
      <ProductWatchDemoVideoModal
        visable={showVideoModal}
        handleClose={handleVideoClose}
        videoId={videoId || ""}
      />
      <section
        className="product__section product__header"
        style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
      >
        <PageHeader backgroundImage={imageUrl}>
          <div
            id="outerslider"
            className=" carousel slide"
            data-ride="carousel"
            data-interval="false"
          >
            <div
              className="carousel-inner container"
              style={{ width: "100%" }}
              data-interval="false"
            >
              <div
                className={" item active headermain"}
                style={{ marginTop: "20vh" }}
              >
                <h1 className="product__title my-2">
                  {i18n.language === "en"
                    ? productHeader?.TitleEn
                    : productHeader?.Title}
                </h1>
                <span className="product__title__description my-2">
                  {i18n.language === "en"
                    ? productHeader?.SubTitleEn
                    : productHeader?.SubTitle}
                </span>
                <div className="product__header__buttons d-sm-flex flex-column flex-lg-row">
                  <div className="row px-0 mx-0 button-row-header d-sm-flex flex-sm-column col-12 col-lg-8 w-100 flex-lg-row">
                    <div className="col-12 col-lg-4 my-1 p-0 me-lg-3">
                      <button
                        className="watch__video__btn px-3 py-1 "
                        onClick={handleVideoClick}
                        style={{ width: "100%" }}
                      >
                        {t("Watch the video")}
                      </button>
                    </div>
                    {productImages && productImages.length > 0 ? (
                      <div className="col my-1 p-0">
                        <a href="#outerslider" data-slide="next">
                          <button className="watch__video__btn btn_galary px-3 py-1">
                            {t("Galary")}
                          </button>
                        </a>
                      </div>
                    ) : null}
                  </div>
                  {Demo ? (
                    <button
                      className="request__demo__btn px-3 py-1 my-1 col-12 col-lg-4"
                      onClick={handleRequestDemoClick}
                    >
                      {t("Request a demo")}
                    </button>
                  ) : null}
                </div>
              </div>
              <div
                className={" item "}
                style={{ width: "100%", minHeight: "500px" }}
              >
                <div>
                  {productImages && productImages.length > 0 ? (
                    <div>
                      <ImageSLider data={productImages} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </PageHeader>
      </section>
    </>
  );
};

export default ProductHeader;
