import { useCallback, useState, useEffect, useRef } from "react";
import { Card, FormGroup, CardBody, CardHeader, TabPane } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";

import {
  DELETE_POST,
  GET_POST,
  INSERT_POST,
  UPDATE_POST,
} from "../Admin.News.Api";
import { apiEndPoint } from "../../../../Services/config.json";
import notify from "devextreme/ui/notify";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";

import { useTranslation } from "react-i18next";
import { PostInterface } from "../../../../Interfaces/Interfaces";

import ButtonsComponent from "../../../../SharedComponents/Loaders/ButtonsComponent";
import { DateConvertor } from "../../../../SharedComponents/Loaders/DateFunction";
import UpdateDelete from "../../../../SharedComponents/Loaders/UpdateDelete";

const LatestPosts = () => {
  const { t, i18n } = useTranslation();

  let [Data, setData] = useState<PostInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_POST();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  let defaultdata = useRef<PostInterface>({
    id: 0,
    title: "",
    titleEn: "",
    description: "",
    descriptionEn: "",
    image: "",
    imagePath: "",
    postDate: "",
  });
  let [Sevice, setServices] = useState<PostInterface>(defaultdata.current);

  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_POST(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });

          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(async () => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback(() => {
    setServices({ ...defaultdata.current });
  }, []);
  let handleGetImages = (event) => {
    let files: any = event.target.files;
    setServices({ ...Sevice, image: files[0] });
  };

  let handleRemoveImage = useCallback(() => {
    setServices({ ...Sevice, image: "" });
  }, [Sevice]);

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        !Sevice.image ||
        !Sevice.title ||
        !Sevice.titleEn ||
        !Sevice.descriptionEn ||
        !Sevice.description
      ) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);

        return;
      }
      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }
      if (Sevice.id > 0) {
        await UPDATE_POST(formData)
          .then((res: any) => {
            setServices({ ...res, image: res.imagePath });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_POST(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            setServices({ ...res, image: res.imagePath });
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <Card className="card-user">
      <CardHeader>
        <h4>{t("Latest Posts")}</h4>
      </CardHeader>
      <CardBody>
        <form className="row" onSubmit={submit}>
          <div className="col-lg-12">
            <div>
              <FormGroup>
                <InputTwoLanguages
                  id="title"
                  label={t("Title")}
                  onValueChange={HandleChange}
                  value={Sevice.title}
                  valueEn={Sevice.titleEn}
                />
              </FormGroup>

              <TabPane tabId="2">
                <TextEditorTwoLanguages
                  id="description"
                  label={t("Description")}
                  value={Sevice.description}
                  valueEn={Sevice.descriptionEn}
                  onValueChange={HandleChange}
                />
              </TabPane>
              <FormGroup className="image__button">
                <label>{t("Image")}</label>
                <UploadImageButton
                  isMultiple={false}
                  handleGetImages={handleGetImages}
                  handleRemoveImage={handleRemoveImage}
                  imagesFiles={
                    Sevice.image
                      ? [
                          typeof Sevice.image === "string"
                            ? apiEndPoint + Sevice.image
                            : Sevice.image,
                        ]
                      : []
                  }
                />
              </FormGroup>
              <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
            </div>
          </div>

          <div id="mybody" className="col-lg-12 Aboutus-admin-service-list">
            {Data.map((data) => {
              return (
                <div
                  className="row "
                  style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
                >
                  <div className="col-12 titleLatestadminForm outer_text_title_News">
                    {i18n.language === "en" ? data.titleEn : data.title}
                  </div>
                  <div className="imageLatest col-lg-4">
                    <img
                      alt="1"
                      src={
                        typeof data.imagePath === "string"
                          ? `${apiEndPoint}${data.imagePath.replaceAll(
                              "\\",
                              "/"
                            )}`
                          : ""
                      }
                      className="image"
                      width={"100%"}
                      height={"100%"}
                    />
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <div className=" titleLatestadminForm inner_text_title_News">
                      {i18n.language === "en" ? data.titleEn : data.title}
                    </div>
                    <div className="bodyLatest">
                      {" "}
                      <p>
                        {ReactHtmlParser(
                          i18n.language === "en"
                            ? data.descriptionEn
                            : data.description
                        )}
                      </p>
                    </div>
                    <div className=" dataForm">
                      {DateConvertor(data.postDate, i18n.language === "en")}
                    </div>
                    <UpdateDelete
                      data={data}
                      Updata={Updata}
                      Delete={Delete}
                      ComponentID="#mybody"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default LatestPosts;
