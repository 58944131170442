import { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
	Button,
	Col,
	FormGroup,
	Input,
	Row,
	Form,
	Card,
	CardBody,
} from "reactstrap";
import { Status } from "../../Enums";
import { REQUEST } from "../../Services/callAPI";
import { Category } from "../Admin.Product/Interfaces";
import { SelectBox } from "devextreme-react";
interface RouteParams {
	id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}
const SupperAdminProduct: React.FC<Props> = ({ match }) => {
	let productId = parseInt(match.params.id) || 0;
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [categries, setProductsCategries] = useState<Category[]>();
	const [basicInitValues, setBasicInitValues] = useState<any>({
		Id: 0,
		ProductNameEn: "",
		CategoryId: 1,
		SubscriptionPrices: {},
	});
	const [basic, setBasic] = useState<any>({
		...basicInitValues,
	});

	const [status, setStatus] = useState(Status.IDLE);
	useEffect(() => {
		// get categories
		let config: AxiosRequestConfig = {
			method: "GET",
			url: `ProductIdentity/` + productId,
		};

		REQUEST(config).then((response: any) => {
			let _productsCategries =
				response?.Categories as unknown as Category[];
			setProductsCategries([..._productsCategries]);
			if (response.Product != null) {
				let prices = response.Product.SubscriptionPrices as any[];

				response.Product.SubscriptionPrices = {};

				prices &&
					prices.length > 0 &&
					prices.forEach((value, index) => {
						response.Product.SubscriptionPrices = {
							...response.Product.SubscriptionPrices,
							[value.PriceCategoryTitle]: value,
						};
					});

				console.log(response?.Product);

				setBasic(response?.Product);
				setBasicInitValues(response?.Product);
			}
		});

		// request and get product data
		if (productId) {
			setStatus(Status.UPDATE);
		} else {
			setStatus(Status.ADD);
		}
	}, [productId]);

	//
	const updateBasic = useCallback((value, id) => {
		setBasic((prev) => ({ ...prev, [id]: value }));
	}, []);

	const updateSubscription = useCallback((type, value, id) => {
		setBasic((prev) => ({
			...prev,
			SubscriptionPrices: {
				...prev.SubscriptionPrices,
				[type]: {
					...prev.SubscriptionPrices[type],
					[id]: value,
				},
			},
		}));
	}, []);

	let formattedBasics = useMemo(() => {
		let newBasics = { ...basic };
		if (newBasics.SubscriptionPrices) {
			newBasics.SubscriptionPrices = [];
			Object.entries(basic.SubscriptionPrices).forEach(
				([key, value]: [any, any]) => {
					newBasics.SubscriptionPrices.push({
						PriceCategoryTitle: key,
						...value,
					});
				}
			);
		} else {
			newBasics.SubscriptionPrices = {};
		}
		return newBasics;
	}, [basic]);

	const isNotValid = useMemo(() => {
		let keysToCheck = ["ProductNameEn", "CategoryId"];

		for (let key of keysToCheck) {
			if (!basic[key]) {
				return true;
			}
		}

		if (basic.Id) {
			let result = true;
			for (let key of Object.keys(basic)) {
				if (basic[key] !== basicInitValues[key]) {
					result = false;
				}
			}
			if (
				basic.Icon &&
				basicInitValues.Icon &&
				basic.Icon.name !== basicInitValues.Icon.name &&
				basic.Icon.size !== basicInitValues.Icon.size
			) {
				result = false;
			}
			return result;
		}

		return false;
	}, [basic, basicInitValues]);

	// submit form
	const addHandle = useCallback(() => {
		let config: AxiosRequestConfig = {
			method: status === Status.ADD ? "post" : "PUT",
			url: "ProductIdentity",
			data: formattedBasics,
		};

		REQUEST(config)
			.then((response: any) => {
				if (status === Status.ADD) {
					history.push(`/admin/products/edit/${response.Id}`);
				}

				let subscriptionPrices = response.SubscriptionPrices;

				response.SubscriptionPrices = {};

				subscriptionPrices.forEach((s) => {
					response.SubscriptionPrices = {
						...response.SubscriptionPrices,
						[s.PriceCategoryTitle]: s,
					};
				});

				setBasicInitValues({
					...response,
				});
				setBasic({
					...response,
				});

				setStatus(Status.UPDATE);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [formattedBasics, history, status]);

	return (
		<>
			<div className="content">
				<div
					style={{
						direction: i18n.language === "en" ? "ltr" : "rtl",
					}}
				>
					<Form>
						<Card className="card-user">
							<CardBody>
								<div className="mt-2">
									<FormGroup>
										<label>{t("Product Name")}</label>
										<Input
											style={{
												fontFamily: "Tajawal",
												height: "40px",
											}}
											id={"ProductNameEn"}
											value={basic.ProductNameEn}
											onChange={(e) =>
												updateBasic(
													e.target.value,
													e.target.id
												)
											}
											type="text"
										/>
									</FormGroup>
									<Row>
										<Col className="pr-1" md="12">
											<FormGroup>
												<label>Category</label>
												<SelectBox
													id="CategoryId"
													items={categries}
													displayExpr={"TitleEn"}
													valueExpr={"Id"}
													value={basic.CategoryId}
													onValueChange={(e) =>
														updateBasic(
															e,
															"CategoryId"
														)
													}
												/>
											</FormGroup>
										</Col>
									</Row>

									<div>
										<h3>{t("Gold")}</h3>
										<Row>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t(
															"Quarter Year Price"
														)}
													</label>

													<Input
														id={"QuarterYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Gold
																?.QuarterYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Gold",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Half Year Price")}
													</label>

													<Input
														id={"HalfYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Gold
																?.HalfYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Gold",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Year Price")}
													</label>

													<Input
														id={"YearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Gold
																?.YearPrice ?? 0
														}
														onChange={(e) =>
															updateSubscription(
																"Gold",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>

									<div>
										<h3>{t("Silver")}</h3>
										<Row>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t(
															"Quarter Year Price"
														)}
													</label>

													<Input
														id={"QuarterYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Silver
																?.QuarterYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Silver",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Half Year Price")}
													</label>

													<Input
														id={"HalfYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Silver
																?.HalfYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Silver",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Year Price")}
													</label>

													<Input
														id={"YearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Silver
																?.YearPrice ?? 0
														}
														onChange={(e) =>
															updateSubscription(
																"Silver",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>

									<div>
										<h3>{t("Bronze")}</h3>
										<Row>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t(
															"Quarter Year Price"
														)}
													</label>

													<Input
														id={"QuarterYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Bronze
																?.QuarterYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Bronze",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Half Year Price")}
													</label>

													<Input
														id={"HalfYearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Bronze
																?.HalfYearPrice ??
															0
														}
														onChange={(e) =>
															updateSubscription(
																"Bronze",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
											<Col className="pr-1" md="4">
												<FormGroup>
													<label>
														{t("Year Price")}
													</label>

													<Input
														id={"YearPrice"}
														value={
															basic
																.SubscriptionPrices
																.Bronze
																?.YearPrice ?? 0
														}
														onChange={(e) =>
															updateSubscription(
																"Bronze",
																e.target.value,
																e.target.id
															)
														}
														type="number"
														min="0"
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>

									<div className="button-container">
										<Row>
											<Col style={{ width: "170px" }}>
												<Button
													className="btn btn btn-success col-12"
													disabled={isNotValid}
													onClick={addHandle}
												>
													{status === Status.UPDATE
														? t("Update")
														: t("Add")}
												</Button>
											</Col>
										</Row>
									</div>
								</div>
							</CardBody>
						</Card>
					</Form>
				</div>
			</div>
		</>
	);
};

export default SupperAdminProduct;
