import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Question } from "../../Pages/Admin.Product/Interfaces";
import "./FAQs.css";

export interface FAQsElement {
	question: string;
	answer: string;
}

interface Props {
	data?: Question[];
}
const FAQs: React.FC<Props> = ({ data = [] }) => {
	const { t, i18n } = useTranslation();
	let [mybuttons, setmybuttons] = useState<any>([]);
	useEffect(() => {
		setmybuttons(
			data.map((da, index) => {
				return { index: index, checker: true };
			})
		);
	}, [data]);
	let openfunction = (index: any) => {
		let x = mybuttons.map((da) =>
			da.index === index
				? {
						...da,
						checker: document
							.getElementById("collapse" + index)
							?.classList.contains("in"),
				  }
				: { ...da }
		);
		setmybuttons(x);
	};
	return (
		<>
			<div className="container-extended py-3">
				<div className="row">
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12">
						<div className="accordion__container" id="faqAccordion">
							<p></p>
							<div className="accordion__standard">
								{data.map((item, index) => {
									return (
										<div className="item" key={index}>
											<div
												className="opener py-2"
												id={"heading" + index}
											>
												<h5 className="mb-0">
													<button
														id={"__btn" + index}
														className="__btn px-0 collapsed"
														data-toggle="collapse"
														onClick={() =>
															openfunction(index)
														}
														data-target={
															"#collapse" + index
														}
														aria-expanded="false"
														aria-controls={
															"collapse" + index
														}
													>
														{mybuttons?.find(
															(da) =>
																da?.index ===
																index
														)?.checker === false ? (
															<i className="fas fa-plus"></i>
														) : (
															<i className="fas fa-minus"></i>
														)}
														<span className="mx-3 question__body">
															{i18n.language ===
															"en"
																? item.QuestionEn
																: item.Question}
														</span>
													</button>
												</h5>
											</div>

											<div
												id={"collapse" + index}
												className="accordion-collapse collapse "
												aria-labelledby={
													"heading" + index
												}
												data-parent="#faqAccordion"
											>
												<div className="card-body question__answer">
													<b> {t("Answer:")} </b>

													{i18n.language === "en"
														? item.AnswerEn
														: item.Answer}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FAQs;
