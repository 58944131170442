import { useCallback, useRef, useState, useEffect } from "react";

import { Card, FormGroup, CardBody, CardHeader, TabPane } from "reactstrap";
import { apiEndPoint } from "../../../../Services/config.json";

import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import {
  DELETE_TECHNOLOGY,
  GET_TECHNOLOGY,
  INSERT_TECHNOLOGY,
  UPDATE_TECHNOLOGY,
} from "../Admin.Aboutus.APi";
import notify from "devextreme/ui/notify";
import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import Technologies from "./Technologies";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import { TechnologyInterface } from "../../../../Interfaces/Interfaces";
import { useTranslation } from "react-i18next";
import ButtonsComponent from "../../../../SharedComponents/Loaders/ButtonsComponent";

const TechnologiesTable = () => {
  const { t, i18n } = useTranslation();
  let defaultdata = useRef<TechnologyInterface>({
    id: 0,
    imagePath: "",
    title: "",
    titleEn: "",
    subTitleEn: "",
    subTitle: "",
    description: "",
    descriptionEn: "",
    image: "",
  });
  let [Sevice, setServices] = useState<TechnologyInterface>(
    defaultdata.current
  );
  let [Data, setData] = useState<TechnologyInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_TECHNOLOGY();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_TECHNOLOGY(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });
          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback(() => {
    setServices({ ...defaultdata.current });
  }, []);
  let handleGetImages = (event) => {
    let files: any = event.target.files;
    setServices({ ...Sevice, image: files[0] });
  };

  let handleRemoveImage = useCallback(
    (element) => {
      setServices({ ...Sevice, image: "" });
    },
    [Sevice]
  );

  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!Sevice.image || !Sevice.title || !Sevice.titleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }
      if (Sevice.id > 0) {
        await UPDATE_TECHNOLOGY(formData)
          .then((res: any) => {
            setServices({ ...res, image: res.imagePath });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res, image: res.imagePath };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_TECHNOLOGY(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            setServices(defaultdata.current);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <h4> {t("Technologies")}</h4>
        </CardHeader>
        <CardBody>
          <div
            className="row"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <form onSubmit={submit} className="col-lg-8">
              <div>
                <FormGroup>
                  <InputTwoLanguages
                    id="title"
                    label={t("Title")}
                    onValueChange={HandleChange}
                    value={Sevice.title}
                    valueEn={Sevice.titleEn}
                  />
                </FormGroup>
                <FormGroup>
                  <InputTwoLanguages
                    id="subTitle"
                    label={t("Subtitle")}
                    onValueChange={HandleChange}
                    value={Sevice.subTitle}
                    valueEn={Sevice.subTitleEn}
                  />
                </FormGroup>

                <TabPane tabId="2">
                  <TextEditorTwoLanguages
                    id="description"
                    label={t("Description")}
                    value={Sevice.description}
                    valueEn={Sevice.descriptionEn}
                    onValueChange={HandleChange}
                  />
                </TabPane>
                <FormGroup className="image__button">
                  <label>{t("Image")}</label>
                  <UploadImageButton
                    isMultiple={false}
                    handleGetImages={handleGetImages}
                    handleRemoveImage={handleRemoveImage}
                    imagesFiles={
                      Sevice.image
                        ? [
                            typeof Sevice.image === "string"
                              ? apiEndPoint + encodeURI(Sevice.image)
                              : Sevice.image,
                          ]
                        : []
                    }
                  />
                </FormGroup>
                <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
              </div>
            </form>
            <div className="col-lg-4 Aboutus-admin-service-list">
              <Technologies serlist={Data} Update={Updata} Delete={Delete} />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TechnologiesTable;
