import "./Buy.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputType } from "../../../Enums";
import Input from "../../../SharedComponents/Input/Input";

import notify from "devextreme/ui/notify";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import { useTranslation } from "react-i18next";

import {
  BuyFormInterface,
  defualtBuyFormInterface,
} from "../../../Interfaces/Interfaces";
import { SUBMIT_FORM } from "./Buy.Api";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";

const Buy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let [values, setvalues] = useState<BuyFormInterface>(defualtBuyFormInterface);
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  const DeprtementList = useMemo(() => {
    return [
      { id: 0, text: "Electronic software and systems" },
      { id: 1, text: "Mobile Apps" },
      { id: 2, text: "Websites" },
      { id: 3, text: "Technical support" },
      { id: 4, text: "Request a call" },
      { id: 5, text: "Other Services" },
    ];
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      await SUBMIT_FORM(values)
        .then((res: any) => {
          setvalues({ ...defualtBuyFormInterface });

          notify(
            {
              message: t(
                "Send Successfully and we will connect with you soon as possible"
              ),
              width: 600,
            },
            "success",
            6000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, t]
  );
  return (
    <>
      <div className="no__background">
        <Navbar />
        <main>
          <form onSubmit={submit} className="Buy_contactUsWidnow ">
            <div className="modal__body" style={{ padding: 0, margin: 0 }}>
              <h4 style={{ marginBottom: "20px" }}>
                {t("Thank you for your interest in our service ")}
              </h4>

              <div className="row px-5 py-2">
                <div className="col-md-6 col-12">
                  <Input
                    label={t("First Name")}
                    isRequired={true}
                    type={InputType.text}
                    name="firstName"
                    onChange={HandleChange}
                    value={values.firstName}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Input
                    label={t("Last Name")}
                    isRequired={true}
                    type={InputType.text}
                    name="lastName"
                    onChange={HandleChange}
                    value={values.lastName}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <Input
                    label={t("E-mail")}
                    isRequired={true}
                    type={InputType.email}
                    onChange={HandleChange}
                    name={"email"}
                    value={values.email}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <Input
                    label={t("Phone Number")}
                    isRequired={true}
                    type={InputType.text}
                    onChange={HandleChange}
                    name="phoneNumber"
                    value={values.phoneNumber}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <Input
                    isRequired
                    label={t("Company Name")}
                    name="companyName"
                    value={values.companyName}
                    onChange={HandleChange}
                    type={InputType.text}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <Input
                    isRequired
                    label={t("Industry")}
                    name="industry"
                    onChange={HandleChange}
                    value={values.industry}
                    type={InputType.text}
                  />
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="inputField">
                    <select
                      required
                      name="programType"
                      value={values.programType}
                      onChange={HandleChange}
                      style={{
                        border: "1px solid #3a7498",
                        color: "#3a7498",
                      }}
                    >
                      {DeprtementList.map((data, index) => {
                        return (
                          <option
                            key={index}
                            style={{
                              color: "#3a7498",
                            }}
                            value={data.id}
                          >
                            {t(data.text)}
                          </option>
                        );
                      })}
                    </select>
                    <label className="invalidLabel">{t("Service Type")}</label>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12">
                  <Input
                    label={t("Note")}
                    isRequired={false}
                    type={InputType.textarea}
                    name="note"
                    value={values.note}
                    onChange={HandleChange}
                  />
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-center">
              <button className="btn signInButton" type={"submit"}>
                {" "}
                {t("Send")}
              </button>
            </div>
          </form>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default Buy;
