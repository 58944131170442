import { REQUEST } from "../../../Services/callAPI";

export const GET_MEMBER = async () => {
  return await REQUEST({
    method: "get",
    url: "Member",
  });
};
export const UPDATE_MEMBER = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Member",
    data: e,
  });
};
export const INSERT_MEMBER = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Member",
    data: e,
  });
};
export const DELETE_MEMBER = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Member/" + id,
  });
};
export const GET_SECTION = async (page) => {
  return await REQUEST({
    method: "get",
    url: "ParagraphSection/page/" + page,
  });
};
export const UPDATE_SECTION = async (e) => {
  return await REQUEST({
    method: "put",
    url: "ParagraphSection",
    data: e,
  });
};
