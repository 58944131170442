import { useCallback, useEffect, useState } from "react";

import { Card, CardBody, CardHeader, FormGroup } from "reactstrap";

import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { DateConvertor } from "../../../../../SharedComponents/Loaders/DateFunction";
import UpdateDelete from "../../../../../SharedComponents/Loaders/UpdateDelete";
import {
  DELETE_REQUEST_DEMO,
  GET_REQUEST_DEMO,
  UPDATE_REQUEST_DEMO_STATUS,
} from "../user/RequestDemoModel.Api";

const RequestDemo = () => {
  const { t, i18n } = useTranslation();
  let [Posts, setPosts] = useState<any>([]);

  let [Current, setCurrent] = useState<number>(0);
  let [TotalCount, setTotalCount] = useState<number>(0);
  let [status, setstatus] = useState<boolean>(false);
  useEffect(() => {
    let fetch = async () => {
      let x: any = await GET_REQUEST_DEMO(0, status);
      setCurrent(x.currentCount);
      setTotalCount(x.totalCount);
      setPosts(x.data);
    };
    fetch();
  }, []);

  let Delete = useCallback(
    async (element) => {
      await DELETE_REQUEST_DEMO(element)
        .then(() => {
          let x = Posts.filter(function (el) {
            return el.id !== element;
          });

          setPosts(x);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Posts, t]
  );

  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Card className="card-user">
        <CardHeader>
          <h4>{t("Request Demo")} </h4>
        </CardHeader>
        <CardBody>
          <Button
            className="btn btn btn-success col-12"
            // disabled={!isValueChanged}
            type="button"
            // disabled={loadIndicatorVisible}
            onClick={async () => {
              let x: any = await GET_REQUEST_DEMO(0, !status);
              setCurrent(x.currentCount);
              setTotalCount(x.totalCount);
              setPosts(x.data);
              setstatus(!status);
            }}
          >
            <span className="dx-button-text">
              {
                //loadIndicatorVisible ? t("Loading") : t("Save")
                status ? t("Is not Active") : t("Is Active")
              }
            </span>
          </Button>
          {Posts.map((data, index) => {
            return (
              <div key={index} style={{ padding: "20px" }}>
                <div
                  className="row "
                  style={{
                    border: "0.5px solid #e69a5c",
                    borderRadius: "20px",
                    padding: "20px",
                  }}
                >
                  <div className="col-4 titleLatestadminForm ">
                    {t("First Name")} : {data.firstName}
                    <br />
                    {t("Last Name")} : {data.lastName}
                    <br />
                    {t("E-mail")} : {data.email}
                    <br />
                    {t("Phone Number")} : {data.phoneNumber}
                    <br />
                    <FormGroup>
                      <label htmlFor="IsActive" className="d-block">
                        {t("Is Active")}
                      </label>
                      <input
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                        type="checkbox"
                        checked={data.status}
                        id="status"
                        onChange={async (e) =>
                          await UPDATE_REQUEST_DEMO_STATUS({
                            ...data,
                            status: e.target.checked,
                          }).then(() => {
                            console.log(
                              Posts.map((da) => {
                                return data.id == da.id
                                  ? { ...da, status: !e.target.checked }
                                  : { ...da };
                              })
                            );
                            setPosts(
                              Posts.map((da) => {
                                return data.id == da.id
                                  ? { ...da, status: !e.target.checked }
                                  : { ...da };
                              })
                            );
                          })
                        }
                      />
                    </FormGroup>
                  </div>

                  <div className="col-8">
                    <div className=" titleLatestadminForm">
                      {t("Industry")} : {data.industry}
                      <br />
                      {t("Company Name")} : {data.companyName}
                      <br />
                      {t("PRODUCT")} :
                      {i18n.language === "en"
                        ? data.product?.productNameEn
                        : data.product?.productName}
                    </div>
                    <div className=" dataForm">
                      {DateConvertor(data.requestDate, i18n.language === "en")}
                    </div>
                    <UpdateDelete
                      data={data}
                      withoutUpdate
                      Updata={() => {}}
                      Delete={Delete}
                      ComponentID="#mybodyTeam"
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className="morePosts">
            {TotalCount !== Current ? (
              <button
                type="button"
                onClick={async () => {
                  let x: any = await GET_REQUEST_DEMO(Current, status);
                  setCurrent(x.currentCount);
                  setTotalCount(x.totalCount);
                  setPosts(x.data);
                }}
                className="btn btn-outline-dark "
              >
                {t("More Posts")}
              </button>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RequestDemo;
