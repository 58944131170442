import { useTranslation } from "react-i18next";
import Header from "./Components/Header";
import LogoAndTitle from "./Components/LogoAndTitle";
import SocialMediaIcons from "./Components/SocialMediaIcons";

const Footer = () => {
  const { i18n } = useTranslation();
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <LogoAndTitle />
      <Header />
      <SocialMediaIcons />
    </div>
  );
};

export default Footer;
