export interface ParagraphSectionInterface {
  id: number;
  title: string;
  titleEn: string;
  description: string;
  descriptionEn: string;
  imagePath: string;
  image: string;
  index?: number;
  page?: string;
}
export interface ContactUsFormInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  messageDate: string;
  phoneNumber: string;
  message: string;
  serviceType: number;
  imageDescription: string;
  contactUsImages: ContactUsImagesInterface[];
  images: string[];
}

export interface ContactUsImagesInterface {
  id: number;
  imagePath: string;
  contactUsId: number;
}
export interface BuyFormInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  programType: number;

  note: string;
  industry: string;
  buyDate: string;
}
export interface RequestDemoFormInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phoneNumber: string;
  industry: string;
  requestDate: string;
  productId?: number;
  product?: {};
}

export const defualtRequestDemoFormInterface: RequestDemoFormInterface = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  industry: "",
  productId: 0,

  requestDate: "",
};
export const defualtBuyFormInterface: BuyFormInterface = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",

  industry: "",
  programType: 0,
  note: "",
  buyDate: "",
};
export const defualtParagraphSectionInterface = {
  id: 0,
  title: "",
  titleEn: "",
  description: "",
  descriptionEn: "",
  imagePath: "",
  image: "",
};
export const defualtContactUsFormInterface: ContactUsFormInterface = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
  messageDate: "",
  serviceType: 0,
  imageDescription: "",
  contactUsImages: [],
  images: [],
};
export interface SupportInterface {
  id: number;
  imagePath: string;
  phoneNumber: string;
  title: string;
  titleEn: string;
  link: string;
  image: string;
  body: string;
  bodyEn: string;
}
export interface TechnologyInterface {
  id: number;
  image: any;
  imagePath: string;
  imagePath2?: string;
  titleEn: string;
  title: string;
  subTitleEn: string;
  subTitle: string;
  descriptionEn: string;
  description: string;
  programDefinitionList?: TechnologyListInterface[];
}
export interface TechnologyListInterface {
  id: number;
  image: string;
  imagePath: string;
  titleEn: string;
  title: string;
  link?: string;
}
export interface SocialInterface {
  id: number;
  link: string;
  type: string;
}

export interface PartenerInterface {
  id: number;
  image: string;
  imagePath: string;
  titleEn: string;
  title: string;
  link: string;
}
export interface FooterInterface {
  id: number;
  phoneNumber: string;
  address: string;
  addressEn: string;
  hotline: string;
  email: string;
  copyRights: string;
  copyRightsEn: string;
}
export const defualtFooterInterface: FooterInterface = {
  id: 0,
  phoneNumber: "",
  address: "",
  addressEn: "",
  hotline: "",
  email: "",
  copyRights: "",
  copyRightsEn: "",
};
export interface BranchInterface {
  id: number;
  title: string;
  titleEn: string;
  idx: string;
  description: string;
  descriptionEn: string;
  longitude: number;
  latitude: number;
  left: number;
  top: number;
  image: any;
  show: boolean;
  styleclass: string;
  phoneNumber?: string;
  hotline?: string;
  phoneNumberEn?: string;
  defualt?: boolean;
  hotlineEn?: string;
  email?: string;
  link?: [];
}
export interface PostInterface {
  id: number;
  title: string;
  titleEn: string;
  description: string;
  descriptionEn: string;
  image: string;
  imagePath: string;
  postDate: any;
  showHome?: boolean;
}
export interface FeaturedPostInterface {
  id: number;
  title: string;
  titleEn: string;
  postDate: any;
}
export interface MemberInterface {
  id: number;
  memberName: string;
  memberNameEn: string;
  jopTitle: string;
  jopTitleEn: string;
  body: string;
  bodyEn: string;
  image: string;
  rank?: number;
  imagePath: string;
  facebook?: string;
  google?: string;
  twitter?: string;
  linkedin?: string;
}
