import { REQUEST } from "../../../Services/callAPI";
export const CATEGORIES_PROGRAMS = async () => {
  return await REQUEST({
    method: "get",
    url: "Categories/ProductsCategoriesWithDemo",
  });
};
export const PRODUCT_DEMO = async () => {
  return await REQUEST({
    method: "get",
    url: "Products/info",
  });
};
