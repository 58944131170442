import Products from "../../../Admin/Products/Products";
import Categories from "../../../Admin/Categories/Categories";
import SupperAdminProduct from "../../../Pages/SupperAdmin.product/SupperAdminProduct";

export interface Routeing {
  path: string;
  name: string;
  icon: string;
  component: any;
  layout: string;
}

export const SupperAdminRoutes: Routeing[] = [
  {
    path: "/categories",
    name: "Categories",
    icon: "fas fa-project-diagram",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "fas fa-barcode",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/products/add",
    name: "",
    icon: "fas fa-home",
    component: SupperAdminProduct,
    layout: "/admin",
  },
  {
    path: "/products/edit/:id",
    name: "",
    icon: "fas fa-home",
    component: SupperAdminProduct,
    layout: "/admin",
  },
  // -Ahmed-  -Home- -Route-
];
