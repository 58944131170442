import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, FormGroup, CardBody, CardHeader, Button } from "reactstrap";
import {
  ParagraphSectionInterface,
  PostInterface,
} from "../../../../../Interfaces/Interfaces";
import notify from "devextreme/ui/notify";
import InputTwoLanguages from "../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import SubmitButton from "../../../../../SharedComponents/Loaders/SubmitButton";

import { GET_HOME_NEWS, UPDATE_HOME_POST } from "../../Admin.Home.APi";
import ReactHtmlParser from "react-html-parser";
import { Popup } from "devextreme-react/popup";
import UpdateDelete from "../../../../../SharedComponents/Loaders/UpdateDelete";
import { UPDATE_SECTION } from "../../../../12.TeamMembers/admin/Admin.Team.Api";
interface Props {
  dataTitle: ParagraphSectionInterface | any;
}
const HomeNews: React.FC<Props> = ({ dataTitle }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setServices(dataTitle);
  }, [dataTitle]);
  let [Sevice, setServices] = useState<ParagraphSectionInterface>(dataTitle);
  let [Data, setData] = useState<PostInterface[]>([]);
  let [MainDataNews, setMainDataNews] = useState<PostInterface[]>([]);
  let [dailog, setdialog] = useState(false);
  let fetch = async () => {
    let x: any = await GET_HOME_NEWS(true);
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  const closePopup = useCallback(async () => {
    setdialog(false);
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  const onClickDialog = useCallback(
    async (element) => {
      element.showHome = true;
      await UPDATE_HOME_POST(element)
        .then(() => {
          setData([...Data, element]);
          notify(
            { message: t("Saved Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let Delete = useCallback(
    async (element) => {
      let x = Data.find((e) => e.id === element)
        ? Data.find((e) => e.id === element)
        : null;
      if (!x) {
        notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        return;
      }
      x.showHome = false;
      await UPDATE_HOME_POST(x)
        .then(() => {
          let my = Data.filter(function (el) {
            return el.id !== element;
          });

          setData(my);
          notify(
            { message: t("Removed successful"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }

      await UPDATE_SECTION(formData)
        .then((res: any) => {
          notify(
            { message: t("Saved Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Sevice, t]
  );
  return (
    <div>
      <Card className="card-user">
        <CardHeader>
          <h4> {t("Home News")}</h4>
        </CardHeader>
        <CardBody>
          <form onSubmit={submit}>
            <FormGroup>
              <InputTwoLanguages
                id="title"
                label={t("Title")}
                onValueChange={HandleChange}
                value={Sevice.title}
                valueEn={Sevice.titleEn}
              />
            </FormGroup>
            <FormGroup>
              <InputTwoLanguages
                id="description"
                label={t("Description")}
                onValueChange={HandleChange}
                value={Sevice.description}
                valueEn={Sevice.descriptionEn}
              />
            </FormGroup>

            <SubmitButton />
          </form>
          <Button
            onClick={useCallback(async () => {
              setdialog(true);
              let x: any = await GET_HOME_NEWS(false);
              setMainDataNews(x);
            }, [])}
          >
            {t("Select Home News")}
          </Button>
          <div
            className="row"
            style={{
              direction: i18n.language === "en" ? "ltr" : "rtl",
            }}
          >
            {Data.map((da) => {
              return (
                <div className="carddiv col-lg-4 col-md-6 col-sm-12">
                  <div className="Header">
                    {" "}
                    {i18n.language === "en" ? da.titleEn : da.title}
                  </div>
                  <div className="bodyText">
                    {ReactHtmlParser(
                      i18n.language === "en" ? da.descriptionEn : da.description
                    )}
                  </div>
                  <UpdateDelete
                    data={da}
                    withoutUpdate
                    Updata={() => {}}
                    Delete={Delete}
                    ComponentID="#mybodyTeam"
                  />
                </div>
              );
            })}
          </div>
          <Popup
            maxWidth={1000}
            title={t("Select Home News")}
            minWidth={150}
            minHeight={500}
            showTitle={true}
            dragEnabled={false}
            closeOnOutsideClick={true}
            visible={dailog}
            onHiding={closePopup}
          >
            <div
              style={{
                direction: i18n.language === "en" ? "ltr" : "rtl",
              }}
            >
              {MainDataNews.map((da) => {
                return (
                  <div
                    className="carddiv card-hover-news-home"
                    onClick={async () => {
                      await onClickDialog(da);
                      setdialog(false);
                    }}
                  >
                    <div className="Header">
                      {i18n.language === "en" ? da.titleEn : da.title}
                    </div>
                    <div className="bodyText">
                      {ReactHtmlParser(
                        i18n.language === "en"
                          ? da.descriptionEn
                          : da.description
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </Popup>
        </CardBody>
      </Card>
    </div>
  );
};

export default HomeNews;
