import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Status } from "../../../../Enums";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { Benefit } from "../../Interfaces";

interface Props {
  data: Benefit | undefined;
  onSubmit: (formData: Benefit) => void;
  onCancel: () => void;
}

const BenefitsForm: React.FC<Props> = ({ onSubmit, onCancel, data }) => {
  const [benefitInitialValues, setBenefitsInitalValues] = useState<Benefit>({
    Id: 0,
    IsActive: false,
    Image: null,
    TitleEn: "",
    Title: "",
    DescriptionEn: "",
    Description: "",
    ProductId: 2,
  });
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.IDLE);

  const [benefit, setBenefit] = useState<Benefit>({
    ...benefitInitialValues,
  });

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setBenefit({ ...data });
      setBenefitsInitalValues({ ...data });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  const updateBenefit = useCallback((value, id) => {
    setBenefit((prev) => ({ ...prev, [id]: value }));
  }, []);

  // image handlers
  let handleGetImages = async (event) => {
    let files = event.target.files;
    let image = files[0];
    setBenefit((prevState) => {
      return { ...prevState, Image: image };
    });
  };

  let handleRemoveImage = (element: FileList | string) => {
    setBenefit((prevState) => {
      return { ...prevState, Image: null };
    });
  };

  const isNotValid = useMemo(() => {
    let keysToCheck = [
      "Image",
      "Title",
      "TitleEn",
      "Description",
      "DescriptionEn",
      "ProductId",
    ];

    for (let key of keysToCheck) {
      if (!benefit[key] || !benefit[key].toString()) {
        return true;
      }
    }

    if (benefit.Id) {
      let result = true;
      for (let key of Object.keys(benefit)) {
        if (benefit[key] !== benefitInitialValues[key]) {
          result = false;
        }
      }
      if (
        benefit.Image &&
        benefitInitialValues.Image &&
        benefit.Image.name !== benefitInitialValues.Image.name &&
        benefit.Image.size !== benefitInitialValues.Image.size
      ) {
        result = false;
      }
      return result;
    }

    return false;
  }, [benefit, benefitInitialValues]);

  // submit form
  const addHandle = useCallback(() => {
    onSubmit(benefit);
  }, [benefit, onSubmit]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="Title"
          label={t("Benefit Title")}
          onValueChange={updateBenefit}
          value={benefit.Title}
          valueEn={benefit.TitleEn}
          maxLength={300}
        />
        <InputTwoLanguages
          id="Description"
          label={t("Benefit Description")}
          onValueChange={updateBenefit}
          value={benefit.Description}
          valueEn={benefit.DescriptionEn}
          maxLength={300}
        />
        <FormGroup className="image__button">
          <label>{t("Benefit Image")}</label>
          <UploadImageButton
            isMultiple={false}
            handleGetImages={handleGetImages}
            handleRemoveImage={handleRemoveImage}
            imagesFiles={benefit.Image ? [benefit.Image] : []}
          />
        </FormGroup>
        <Col className="pr-1" md="6">
          <FormGroup>
            <label htmlFor="isActive" className="d-block">
              Is Active
            </label>
            <input
              style={{
                width: "40px",
                height: "40px",
              }}
              type="checkbox"
              checked={benefit.IsActive}
              id="IsActive"
              onChange={(e) => updateBenefit(e.target.checked, e.target.id)}
            />
          </FormGroup>
        </Col>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? "Update" : "Add"}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={false}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default BenefitsForm;
