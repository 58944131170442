import { REQUEST } from "../../../Services/callAPI";
export const REGISTIRATION = async (e) => {
  return await REQUEST({
    method: "post",
    url: "register-user",
    data: e,
  });
};
export const CHECK_EMAIL = async (e) => {
  return await REQUEST({
    method: "post",
    url: "check-email",
    data: e,
  });
};
export const CHECK_USERNAME = async (e) => {
  return await REQUEST({
    method: "post",
    url: "check-username",
    data: e,
  });
};
