import { Button } from "reactstrap";

import { useTranslation } from "react-i18next";

const SubmitButton = () => {
  // let [loadIndicatorVisible, setloadIndicatorVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <Button
      className="btn btn btn-success col-12"
      // disabled={!isValueChanged}
      type="submit"
      // disabled={loadIndicatorVisible}
    >
      <span className="dx-button-text">
        {
          //loadIndicatorVisible ? t("Loading") : t("Save")
          t("Save")
        }
      </span>
    </Button>
  );
};

export default SubmitButton;
