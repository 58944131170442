import React from "react";
import { ma } from "./Mock";
import "./MapComponents.css";
const MapComponents = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 809.2 465.7"
        className="mapsvg"
        width="809px"
        height="466px"
        style={{ fill: "#a8bdcf" }}
      >
        <path d={ma}></path>
      </svg>
    </>
  );
};

export default MapComponents;
