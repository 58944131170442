import React, { useEffect, useState } from "react";
import "./Style.GoalsList.css";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import {
  ParagraphSectionInterface,
  PostInterface,
} from "../../../../../Interfaces/Interfaces";
import { GET_HOME_NEWS } from "../../../admin/Admin.Home.APi";
import { NavLink } from "react-router-dom";
interface Props {
  dataTitle?: ParagraphSectionInterface | any;
}
const GoalsList: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  let [Data, setData] = useState<PostInterface[]>([]);

  useEffect(() => {
    let fetch = async () => {
      let x: any = await GET_HOME_NEWS(true);

      setData(x);
    };
    fetch();
  }, []);
  return (
    <div
      className="row mainDiv"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      {Data.map((x, index) => {
        return (
          <div key={index} className="carddiv col-lg-4 col-md-6 col-sm-12">
            <div className="Header">
              {i18n.language === "en" ? x.titleEn : x.title}
            </div>
            <div className="bodyText">
              {ReactHtmlParser(
                i18n.language === "en"
                  ? x.descriptionEn.length > 200
                    ? x.descriptionEn.split("</p>")[0]
                    : x.descriptionEn
                  : x.description.length > 200
                  ? x.description.split("</p>")[0]
                  : x.description
              )}
              <NavLink to={`/news`} className="read-more-text-news">
                {t("Read More...")}
              </NavLink>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GoalsList;
