import React, { useEffect } from "react";
import Navbar from "../../SharedComponents/Navbar/Navbar";
import "./paymentSuccess.css";

const PaymentDeclined = (props) => {
	return (
		<>
			<div className="no__background">
				<Navbar />

				<div className="absolute__from__nav">
					<main>
						<div className="paymentSuccess__wrapper">
							<div class="container">
								<div class="row">
									<div class="col-md-6 mx-auto mt-5">
										<div class="payment">
											<div class="payment_header">
												<div class="xmark">
													<i
														class="fa fa-xmark"
														aria-hidden="true"
													></i>
												</div>
											</div>
											<div class="content declined">
												<h1>Payment Declined !</h1>
												<p>
													If you faced any problems or
													are unable to complete your
													transaction please contact
													our support.
												</p>
												<a href="#/contact-us">
													Go to Support
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default PaymentDeclined;
