import { useState, useCallback, useEffect } from "react";
import { apiEndPoint } from "../../../../Services/config.json";
import { Card, FormGroup, CardBody, CardHeader } from "reactstrap";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton, {
  HTMLInputEvent,
} from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { useTranslation } from "react-i18next";

import { GET_SECTION, UPDATE_SECTION } from "../Admin.Team.Api";
import {
  ParagraphSectionInterface,
  defualtParagraphSectionInterface,
} from "../../../../Interfaces/Interfaces";
import SubmitButton from "../../../../SharedComponents/Loaders/SubmitButton";
import notify from "devextreme/ui/notify";
const Header = () => {
  const { t } = useTranslation();
  useEffect(() => {
    let get_data = async () => {
      let x: any = await GET_SECTION("Team");
      x[0].image = x[0].imagePath;
      setHead(x[0]);
    };
    get_data();
  }, []);

  let [Head, setHead] = useState<ParagraphSectionInterface>(
    defualtParagraphSectionInterface
  );
  const HandleChange = useCallback((value, id) => {
    setHead((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!Head.imagePath || !Head.image) {
        notify({ message: t("Image is required"), width: 600 }, "error", 3000);
        return;
      }
      Head.title = !Head.title ? " " : Head.title;
      Head.titleEn = !Head.titleEn ? " " : Head.titleEn;
      Head.description = !Head.description ? " " : Head.description;
      Head.descriptionEn = !Head.descriptionEn ? " " : Head.descriptionEn;

      let formData = new FormData();

      for (let [key, value] of Object.entries(Head)) {
        formData.append(key.toString(), value);
      }
      await UPDATE_SECTION(formData)
        .then((res: any) => {
          notify(
            { message: t("Saved Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Head, t]
  );
  let handleGetImages = (event: HTMLInputEvent) => {
    let files: any = event.target.files;
    setHead({ ...Head, image: files[0] });
  };

  let handleRemoveImage = useCallback(() => {
    setHead({ ...Head, image: "" });
  }, [Head]);
  return (
    <Card className="card-user">
      <CardHeader>
        <h4>{t("Header")} </h4>
      </CardHeader>
      <CardBody>
        <form onSubmit={submit}>
          <FormGroup>
            <InputTwoLanguages
              id="title"
              label={t("Title")}
              onValueChange={HandleChange}
              value={Head.title}
              valueEn={Head.titleEn}
            />
          </FormGroup>
          <FormGroup>
            <InputTwoLanguages
              id="description"
              label={t("Description")}
              onValueChange={HandleChange}
              value={Head.description}
              valueEn={Head.descriptionEn}
            />
          </FormGroup>
          <FormGroup className="image__button">
            <label>{t("Image")} </label>
            <UploadImageButton
              isMultiple={false}
              handleGetImages={handleGetImages}
              handleRemoveImage={handleRemoveImage}
              imagesFiles={
                Head.image
                  ? [
                      typeof Head.image == "string"
                        ? apiEndPoint + Head.image
                        : Head.image,
                    ]
                  : []
              }
            />
          </FormGroup>

          <SubmitButton />
        </form>
      </CardBody>
    </Card>
  );
};

export default Header;
