import { useCallback } from "react";

import { FormGroup, Input, Button } from "reactstrap";
import { apiEndPoint } from "../../../../../Services/config.json";

import InputTwoLanguages from "../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import { DELETE_PROGRAMS_DEFINITION_LIST } from "../../Admin.Home.APi";
import notify from "devextreme/ui/notify";
import UploadImageButton from "../../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { useTranslation } from "react-i18next";

const ProgramDefinitionsFormList = ({ data, setdata }) => {
  const { t, i18n } = useTranslation();

  let Delete = useCallback(
    async (element) => {
      if (element > 0) {
        await DELETE_PROGRAMS_DEFINITION_LIST(element)
          .then(() => {
            setdata(
              data.filter(function (el) {
                return el.id !== element;
              })
            );
            notify(
              { message: t("Deleted Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        setdata(
          data.filter(function (el) {
            return el.id !== element;
          })
        );
      }
    },
    [data, t, setdata]
  );

  let handleGetImages = async (id, event) => {
    let files: any = event.target.files[0];
    var reader = new FileReader();

    reader.readAsDataURL(files);

    reader.onload = await function () {
      setdata(
        data.map((da) => {
          if (da.id === id) {
            return {
              ...da,
              image: reader.result,
              imagePath: files,
            };
          }
          return da;
        })
      );
    };
  };

  let handleRemoveImage = useCallback(
    (id) => {
      setdata(
        data.map((da) => {
          if (da.id === id) {
            return { ...da, image: "", imagePath: "" };
          }
          return da;
        })
      );
    },
    [data, setdata]
  );
  let HandleChange = useCallback(
    (id, value, name) => {
      setdata(
        data.map((da) => {
          if (da.id === id) {
            return { ...da, [name]: value };
          }
          return da;
        })
      );
    },
    [data, setdata]
  );

  return (
    <>
      <div style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}>
        {data &&
          data.map((da) => {
            return (
              <div>
                <div>
                  <div className="row">
                    <div className="col">
                      <FormGroup>
                        <InputTwoLanguages
                          id="title"
                          label={t("Title")}
                          onValueChange={(n, v) => HandleChange(da.id, n, v)}
                          value={da.title}
                          valueEn={da.titleEn}
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <FormGroup>
                        <label>{t("Link")}</label>
                        <Input
                          style={{ fontFamily: "Tajawal", height: "40px" }}
                          id={"link"}
                          value={da.link}
                          onChange={(e) =>
                            HandleChange(da.id, e.target.value, e.target.id)
                          }
                          type="url"
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div
                    className="row"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="col">
                      <FormGroup className="image__button">
                        <label>{t("Image")}</label>
                        <UploadImageButton
                          isMultiple={false}
                          handleGetImages={(e) => handleGetImages(da.id, e)}
                          handleRemoveImage={() => handleRemoveImage(da.id)}
                          imagesFiles={
                            da.imagePath
                              ? [
                                  typeof da.imagePath === "string"
                                    ? apiEndPoint + da.imagePath
                                    : da.imagePath,
                                ]
                              : []
                          }
                        />
                      </FormGroup>
                    </div>
                    <div className="col">
                      <Button
                        className="btn btn btn-danger col-12"
                        onClick={() => Delete(da.id)}
                        // disabled={da.id <= 0}
                        //  onClick={Dele}
                      >
                        -
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ProgramDefinitionsFormList;
