import React, { useState, useEffect } from "react";

// import css
import "./SuccessParteners.css";

import {
	ParagraphSectionInterface,
	PartenerInterface,
} from "../../../../../Interfaces/Interfaces";
import { apiEndPoint } from "../../../../../Services/config.json";

import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GET_PARTENERS } from "../../../admin/Admin.Home.APi";
interface Props {
	dataTitle: ParagraphSectionInterface | any;
}
const SuccessParteners: React.FC<Props> = ({ dataTitle }) => {
	const { i18n } = useTranslation();
	let [Data, setData] = useState<PartenerInterface[]>([]);

	let fetch = async () => {
		let x: any = await GET_PARTENERS();
		setData(x);
	};
	useEffect(() => {
		fetch();
	}, []);

	return (
		<div className="successPartenersWrapper successPartenerContainer">
			<div className="successParteners text__middle w-100">
				{" "}
				{i18n.language === "en" ? dataTitle.titleEn : dataTitle.title}
			</div>
			<p style={{ textAlign: "center" }}>
				{i18n.language === "en"
					? dataTitle.descriptionEn
					: dataTitle.description}
			</p>
			<div className="successPartenersContainer">
				{Data.map((data, index) => {
					return (
						<div key={index} className="imageContainer ">
							<OverlayTrigger
								placement="right"
								delay={{ show: 250, hide: 400 }}
								overlay={(props) => {
									return (
										<Tooltip id="button-tooltip" {...props}>
											<b>
												{i18n.language === "en"
													? data.titleEn
													: data.title}
											</b>
										</Tooltip>
									);
								}}
							>
								<img
									onClick={() =>
										window.open(data.link, "_blank")
									}
									src={`${apiEndPoint}${encodeURI(
										data.imagePath.replaceAll("\\", "/")
									)}`}
									alt=""
									className="successPartenersImage "
								/>
							</OverlayTrigger>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SuccessParteners;
