import { useTranslation } from "react-i18next";
import Header from "./Components/Header";
import Members from "./Components/Members";

const AdminTeam = () => {
  const { i18n } = useTranslation();
  return (
    <div
      className="content"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Header />
      <Members />
    </div>
  );
};

export default AdminTeam;
