// React
import {
	useState,
	useRef,
	useLayoutEffect,
	useCallback,
	useEffect,
} from "react";

// Css
import "./LoginBar.css";
import { IoMdSearch } from "react-icons/io";
import { RiLoginCircleLine } from "react-icons/ri";
import { HiOutlineFolderDownload } from "react-icons/hi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
// Components

import { useTranslation } from "react-i18next";

function LoginBar() {
	let history = useHistory();
	const domainLang = "ar";
	const { t, i18n } = useTranslation();

	const [searchInputVisibility, setSearchInputVisibility] = useState(false);
	const [size, setSize] = useState(0);
	const pageMinSize = useRef(521);
	let [langueChecker, setlangueChecker] = useState<any>(null);
	const changeLanguage = useCallback(
		(e) => {
			langueChecker === "Tr"
				? i18n.language === "en"
					? i18n.changeLanguage("tr")
					: i18n.changeLanguage("en")
				: i18n.language === "en"
				? i18n.changeLanguage(domainLang)
				: i18n.changeLanguage("en");

			document.documentElement.setAttribute("lang", i18n.language);
		},
		[i18n, langueChecker]
	);

	useLayoutEffect(() => {
		function updateSize() {
			setSize(window.innerWidth);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	let toggleSearchVisibilty = () => {
		setSearchInputVisibility(!searchInputVisibility);
	};

	useEffect(() => {
		let url = window.location.href;
		if (url.toString().includes(".tr")) {
			setlangueChecker("Tr");
		} else if (url.toString().includes(".ly")) {
			setlangueChecker("En");
		} else if (url.toString().includes(".eg")) {
			setlangueChecker("En");
		} else {
			setlangueChecker("En");
		}
	}, []);
	return (
		<>
			<div className="loginBarWrapper" dir="auto">
				<div className={`container   loginBarContainer`}>
					<div className={`inOutLoginContainer`}>
						{size > pageMinSize.current ? (
							<div
								style={{ cursor: "pointer" }}
								onClick={() => {
									if (localStorage.getItem("user")) {
										localStorage.removeItem("user");
										history.push("/");
									} else {
										history.push("/log-in");
									}
								}}
							>
								{localStorage.getItem("user")
									? t("Log Out")
									: t("Log In")}
							</div>
						) : (
							<RiLoginCircleLine className="mobileSizeIconsStyle" />
						)}
						{/* <div>تسجيل خروج</div> */}

						{!searchInputVisibility ? (
							<IoMdSearch
								className={`mobileSizeIconsStyle`}
								onClick={toggleSearchVisibilty}
							/>
						) : null}

						{searchInputVisibility ? (
							<div
								style={{ direction: "ltr" }}
								className={`d-flex`}
								onBlur={toggleSearchVisibilty}
							>
								<div className="inputSearchIconContainer">
									<IoMdSearch className="inputSearchIcon" />
								</div>
								<input
									autoFocus
									type="text"
									className={`inputStyle`}
								/>
							</div>
						) : null}
						{size > pageMinSize.current ? null : (
							<HiOutlineFolderDownload
								className={`mobileSizeIconsStyle ${
									searchInputVisibility ? "d-none" : ""
								} `}
							/>
						)}

						{size > pageMinSize.current ? null : (
							<AiOutlineShoppingCart className="mobileSizeIconsStyle" />
						)}
						<div>
							{" "}
							{localStorage?.getItem("user")
								? JSON.parse(
										localStorage?.getItem("user") || "{}"
								  ).username
								: ""}
						</div>
					</div>

					{window.location.href.includes("localhost") ||
					window.location.href.includes(".eg") ||
					window.location.href.includes(".tr") ||
					window.location.href.includes(".ly") ? (
						<div
							style={{ fontSize: "17px", cursor: "pointer" }}
							onClick={changeLanguage}
						>
							{langueChecker === "Tr" ? t("lang2") : t("lang")}
						</div>
					) : (
						<div
							style={{ fontSize: "17px", cursor: "pointer" }}
						></div>
					)}
				</div>
			</div>
		</>
	);
}

export default LoginBar;
