import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { InputType } from "../../../Enums";

import Input from "../../../SharedComponents/Input/Input";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import { LOGIN } from "./API.Login";
import "./Login.css";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";

function Login() {
  let history = useHistory();

  const { t, i18n } = useTranslation();
  let [values, setvalues] = useState({ username: "", password: "" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.password || !values.username) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      await LOGIN(values)
        .then((res: any) => {
          if (res === 110) {
            notify(
              { message: t("you have to confirm your mail "), width: 600 },
              "error",
              3000
            );
            return;
          }

          localStorage.setItem("user", JSON.stringify(res));
          notify(
            { message: t("Sign in successfully"), width: 600 },
            "success",
            3000
          );

          if (localStorage.getItem("lastpage")) {
            let item = JSON.parse(localStorage.getItem("lastpage") || "{}");
            localStorage.removeItem("lastpage");
            console.log(item.url);
            window.location.href = item.url;
            return;
          }
          if (
            res.type &&
            (res.type[0] === "Admin" || res.type[0] === "SupperAdmin")
          ) {
            history.push("/admin");
          } else {
            history.push("/");
          }
        })
        .catch((e) => {
          notify(
            { message: t("check your username and password"), width: 600 },
            "error",
            3000
          );
        });
    },
    [values, history, t]
  );
  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);
  return (
    <div className="no__background">
      <Navbar />
      <div className="absolute__from__nav colorGrey ">
        <main>
          <div
            className="loginWrapper"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <form onSubmit={submit} className="loginContainer">
              <div className="loginWidnow">
                <div className="signInText">{t("Sign In")}</div>
                <div className="loginInputsContainer">
                  <div>
                    <Input
                      label={t("Username")}
                      isRequired={true}
                      type={InputType.text}
                      onChange={HandleChange}
                      value={values.username}
                      name="username"
                    />
                  </div>
                  <div>
                    <Input
                      label={t("Password")}
                      isRequired={true}
                      type={InputType.password}
                      onChange={HandleChange}
                      value={values.password}
                      name="password"
                    />
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-center">
                  <button className="btn signInButton">{t("SIGN IN")}</button>
                </div>

                <Link to="/email-change-password">
                  <div className="forgotPasswordDiv">
                    {t("Forgot password?")}
                  </div>
                </Link>
                <div className="w-100 d-flex justify-content-center">
                  <Link to="/register" className="btn CreateAnAccountButton">
                    {t("CREATE AN ACCOUNT")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default Login;
