import { useEffect, useState } from "react";

//css
import "./Footer.css";
import { useTranslation } from "react-i18next";
//Icons
import { AiTwotonePhone } from "react-icons/ai";
import { IoMdMail } from "react-icons/io";
import { GoLocation } from "react-icons/go";

import { GET_FOOTER, GET_NEAREST_BRANCH, GET_SOCIAL } from "../API.Footer";
import {
  defualtFooterInterface,
  FooterInterface,
  SocialInterface,
} from "../../../../../../Interfaces/Interfaces";
import { apiEndPoint } from "../../../../../../Services/config.json";
import { REQUEST } from "../../../../../../Services/callAPI";

function Footer() {
  const { t, i18n } = useTranslation();
  let [social, setsocial] = useState<SocialInterface[]>([]);
  let [footer, setfooter] = useState<FooterInterface>(defualtFooterInterface);

  let [Maindata, setMainData] = useState<any>({});
  let [nearist, setnearist] = useState<any>({});

  useEffect(() => {
    let fetch = async () => {
      let x: any = await GET_SOCIAL();
      setsocial(x);
      x = await GET_FOOTER(1);
      setfooter(x);
    };
    let getHeaders = async () => {
      await REQUEST({
        method: "get",
        url: "ParagraphSection/page/main",
      })
        .then((res: any) => {
          res = res[0];
          setMainData(res);
        })
        .catch(() => {
          document.title = "Almedad Soft";
        });
    };
    fetch();
    getHeaders();

    if (localStorage.getItem("Domain")) {
      let local: any = localStorage.getItem("Domain");
      let domain = JSON.parse(local);
      GETBRANCH(domain.x, domain?.y);
      return;
    }
    GETBRANCH(0, 0);
  }, []);
  const GETBRANCH = (x, y) => {
    GET_NEAREST_BRANCH({
      latitude: x,
      longitude: y,
    })
      .then((res: any) => {
        console.log(res);
        setnearist(res);
      })
      .catch((err) => {});
  };
  return (
    <div
      style={{ boxShadow: " 0 -10px 20px #888888ab" }}
      className="footerWrapper"
    >
      <div
        className="footerContainer container"
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
      >
        <div className="footerLogoContainer">
          <img
            src={
              typeof Maindata.imagePath === "string"
                ? `${apiEndPoint}${encodeURI(
                    Maindata.imagePath.replaceAll("\\", "/")
                  )}`
                : ""
            }
            className="logoStyle"
            alt="logo"
          />
        </div>
        <div className="addressCopyRightsWarpper">
          <div className="dataContainer">
            <div className="addressContainer">
              <GoLocation className="font15" />{" "}
              <div>
                {" "}
                {i18n.language === "en"
                  ? nearist.descriptionEn
                  : nearist.description}
              </div>
            </div>
            <div className="phoneContainer">
              <AiTwotonePhone className="font15" />
              <div>{footer.phoneNumber}</div>
            </div>
            <div className="hotPhoneContainer">
              <AiTwotonePhone className="font15 colorRed" />
              <div>{footer.hotline}</div>
            </div>
            <div className="mailContainer">
              <IoMdMail className="font15 " />
              <div>
                <a
                  rel="noreferrer"
                  href={"mailto:" + footer.email}
                  style={{ color: "white" }}
                >
                  {footer.email}
                </a>
              </div>
            </div>
          </div>

          <div className="addressCopyRightsSeparator"></div>

          <div className="copyRightsContainer">
            <div> {footer.copyRights}</div> <div></div>
          </div>
        </div>
        <div className="contactsWrapper">
          <div className="followUs">{t("Follow us")}</div>
          <div className="footerContactsContainer">
            {social.map((ele) => {
              return (
                <a
                  href={ele?.link}
                  target="_blank"
                  className="footerContactsContainerIcon"
                >
                  <i className={"fab fa-" + ele.type}></i>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
