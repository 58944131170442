import { useCallback, useRef, useState, useEffect } from "react";

import { Card, FormGroup, Input, CardBody, CardHeader } from "reactstrap";
import { apiEndPoint } from "../../../../../Services/config.json";

import InputTwoLanguages from "../../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";

import {
  DELETE_PARTENERS,
  GET_PARTENERS,
  INSERT_PARTENERS,
  UPDATE_PARTENERS,
} from "../../Admin.Home.APi";
import notify from "devextreme/ui/notify";
import UploadImageButton from "../../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { PartenerInterface } from "../../../../../Interfaces/Interfaces";
import { useTranslation } from "react-i18next";
import ButtonsComponent from "../../../../../SharedComponents/Loaders/ButtonsComponent";
import PartenersList from "./PartenersList";

const Parteners = () => {
  const { t, i18n } = useTranslation();
  let defaultdata = useRef<PartenerInterface>({
    id: 0,
    imagePath: "",
    title: "",
    titleEn: "",
    link: "",
    image: "",
  });
  let [Sevice, setServices] = useState<PartenerInterface>(defaultdata.current);
  let [Data, setData] = useState<PartenerInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_PARTENERS();
    setData(x);
  };
  useEffect(() => {
    fetch();
  }, []);
  const HandleChange = useCallback((value, id) => {
    setServices((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);
  let Updata = useCallback((element) => {
    setServices({ ...element, image: element.imagePath });
  }, []);
  let Delete = useCallback(
    async (element) => {
      await DELETE_PARTENERS(element)
        .then(() => {
          let x = Data.filter(function (el) {
            return el.id !== element;
          });
          setData(x);
          setServices(defaultdata.current);
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [Data, t]
  );
  let New = useCallback(() => {
    setServices({ ...defaultdata.current });
  }, []);
  let handleGetImages = (event) => {
    let files: any = event.target.files;
    setServices({ ...Sevice, image: files[0] });
  };

  let handleRemoveImage = useCallback(() => {
    setServices({ ...Sevice, image: "" });
  }, [Sevice]);
  let submit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!Sevice.image || !Sevice.title || !Sevice.titleEn) {
        notify({ message: t("Fill the inputs"), width: 600 }, "error", 3000);
        return;
      }
      let formData = new FormData();

      for (let [key, value] of Object.entries(Sevice)) {
        formData.append(key.toString(), value);
      }
      if (Sevice.id > 0) {
        await UPDATE_PARTENERS(formData)
          .then((res: any) => {
            setServices({ ...res, image: res.imagePath });
            setData(
              Data.map((da) => {
                if (da.id === res.id) {
                  return { ...res };
                }
                return da;
              })
            );
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      } else {
        await INSERT_PARTENERS(formData)
          .then((res: any) => {
            setData([...Data, { ...res, image: res.imagePath }]);
            setServices(defaultdata.current);
            notify(
              { message: t("Saved Successfully"), width: 600 },
              "success",
              3000
            );
          })
          .catch(() => {
            notify(
              { message: t("Failed Try again"), width: 600 },
              "error",
              3000
            );
          });
      }
    },
    [Sevice, Data, t]
  );
  return (
    <>
      <Card className="card-user">
        <CardHeader>
          <h4> {t("Parteners")}</h4>
        </CardHeader>
        <CardBody>
          <div
            className="row"
            style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}
          >
            <div className="col-lg-8">
              <form onSubmit={submit}>
                <FormGroup>
                  <InputTwoLanguages
                    id="title"
                    label={t("Title")}
                    onValueChange={HandleChange}
                    value={Sevice.title}
                    valueEn={Sevice.titleEn}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{t("Link")}</label>
                  <Input
                    style={{ fontFamily: "Tajawal", height: "40px" }}
                    id={"link"}
                    value={Sevice.link}
                    onChange={(e) => HandleChange(e.target.value, e.target.id)}
                    type="url"
                  />
                </FormGroup>
                <FormGroup className="image__button">
                  <label>{t("Image")}</label>
                  <UploadImageButton
                    isMultiple={false}
                    handleGetImages={handleGetImages}
                    handleRemoveImage={handleRemoveImage}
                    imagesFiles={
                      Sevice.image
                        ? [
                            typeof Sevice.image === "string"
                              ? apiEndPoint + Sevice.image
                              : Sevice.image,
                          ]
                        : []
                    }
                  />
                </FormGroup>
                <ButtonsComponent New={New} buttondisable={Sevice.id === 0} />
              </form>
            </div>
            <div className="col-lg-4 Aboutus-admin-service-list">
              <PartenersList serlist={Data} Update={Updata} Delete={Delete} />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Parteners;
