import { useCallback, useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import notify from "devextreme/ui/notify";
import { SocialInterface } from "../../../../../../../Interfaces/Interfaces";
import { GET_SOCIAL, UPDATE_SOCIAL } from "../../API.Footer";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../../../../../../SharedComponents/Loaders/SubmitButton";
const SocialMediaIcons = () => {
  const { t } = useTranslation();
  let [values, setValues] = useState<SocialInterface[]>([]);
  let fetch = async () => {
    let x: any = await GET_SOCIAL();
    setValues(x);
  };
  useEffect(() => {
    fetch();
  }, []);

  let change = useCallback(
    (val, e) => {
      setValues(
        values.map((da) => {
          if (da.id === val.id) {
            return { ...val, link: e.target.value };
          }
          return da;
        })
      );
    },
    [values]
  );

  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      await UPDATE_SOCIAL(values)
        .then(() => {
          notify({ message: "تم التخزين بنجاح", width: 600 }, "success", 3000);
        })
        .catch(() => {
          notify(
            { message: "  خطأ في البيانات حاول مجددا", width: 600 },
            "error",
            3000
          );
        });
    },
    [values]
  );
  return (
    <div>
      <Card className="card-user">
        <CardHeader>
          <h4>{t("Soical Media")}</h4>
        </CardHeader>

        <CardBody>
          <form onSubmit={submit}>
            {values
              ? values.map((da, index) => {
                  return (
                    <FormGroup key={index}>
                      <Label>
                        {t("Link")} {da.type}
                      </Label>
                      <Input
                        name={"link"}
                        value={da.link}
                        onChange={(e) => change(da, e)}
                        type="url"
                      />
                    </FormGroup>
                  );
                })
              : null}
            <SubmitButton />
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default SocialMediaIcons;
