export const DomainValue = () => {
  let url = window.location.href;
  if (url.toString().includes(".tr")) {
    return 3;
  } else if (url.toString().includes(".ly")) {
    return 1;
  } else if (url.toString().includes(".eg")) {
    return 2;
  }

  return 2;
};
