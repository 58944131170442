import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Status } from "../../../../Enums";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import { ControlsInformation } from "../../Interfaces";

interface Props {
  data: ControlsInformation | undefined;
  onSubmit: (formData: ControlsInformation) => void;
  onCancel: () => void;
}

const ControlsInformationForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const [status, setStatus] = useState(Status.IDLE);
  const { t } = useTranslation();
  const [controlInformationInitValues, setControlInformationInitValues] =
    useState<ControlsInformation>({
      Id: 0,
      IsActive: false,
      TitleEn: "",
      Title: "",
      DescriptionEn: "",
      Description: "",
      ProductId: 2,
    });

  const [controlInformation, setControlsInformation] =
    useState<ControlsInformation>({ ...controlInformationInitValues });

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setControlsInformation({ ...data });
      setControlInformationInitValues({ ...data });
    } else {
      setStatus(Status.ADD);
    }
  }, [data]);

  const updateControlsInformation = useCallback((value, id) => {
    setControlsInformation((prev) => ({ ...prev, [id]: value }));
  }, []);

  const isNotValid = useMemo(() => {
    let keysToCheck = [
      "TitleEn",
      "Title",
      "DescriptionEn",
      "Description",
      "ProductId",
    ];

    for (let key of keysToCheck) {
      if (!controlInformation[key] || !controlInformation[key].toString()) {
        return true;
      }
    }

    if (controlInformation.Id) {
      let result = true;
      for (let key of Object.keys(controlInformation)) {
        if (controlInformation[key] !== controlInformationInitValues[key]) {
          result = false;
        }
      }
      return result;
    }
  }, [controlInformation, controlInformationInitValues]);

  // submit form
  const addHandle = useCallback(() => {
    onSubmit(controlInformation);
  }, [controlInformation, onSubmit]);

  return (
    <>
      <div className="mt-2">
        <InputTwoLanguages
          id="Title"
          label={t("ControlsInformation Title")}
          onValueChange={updateControlsInformation}
          value={controlInformation.Title}
          valueEn={controlInformation.TitleEn}
        />
        <TextEditorTwoLanguages
          id="Description"
          label={t("ControlsInformation Description")}
          onValueChange={updateControlsInformation}
          value={controlInformation.Description}
          valueEn={controlInformation.DescriptionEn}
        />
        <Col className="pr-1" md="6">
          <FormGroup>
            <label htmlFor="IsActive" className="d-block">
              {t("Is Active")}
            </label>
            <input
              style={{
                width: "40px",
                height: "40px",
              }}
              type="checkbox"
              checked={controlInformation.IsActive}
              id="IsActive"
              onChange={(e) =>
                updateControlsInformation(e.target.checked, e.target.id)
              }
            />
          </FormGroup>
        </Col>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={false}
                onClick={onCancel}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ControlsInformationForm;
