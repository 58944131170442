import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputType } from "../../../../../Enums";
import {
  defualtRequestDemoFormInterface,
  RequestDemoFormInterface,
} from "../../../../../Interfaces/Interfaces";
import Input from "../../../../../SharedComponents/Input/Input";
import Modal from "../../../../../SharedComponents/Modal/Modal";
import "./RequestDemoModal.css";
import { SUBMIT_FORM } from "./RequestDemoModel.Api";

interface Props {
  visable: boolean;
  id: number | any;
  handleClose: () => void;
}

const RequestDemoModal: React.FC<Props> = ({ visable, handleClose, id }) => {
  const { t, i18n } = useTranslation();

  let [values, setvalues] = useState<RequestDemoFormInterface>(
    defualtRequestDemoFormInterface
  );
  useEffect(() => {
    setvalues({
      ...defualtRequestDemoFormInterface,
      productId: parseInt(id),
    });
  }, [id]);

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const HandleChange = useCallback((e) => {
    setvalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  //**blob to dataURL**
  function blobToDataURL(blob, callback) {
    var a = new FileReader();
    a.readAsDataURL(blob);
    a.onload = function (e: any) {
      console.log(e);
      callback(e.target.result);
    };
  }

  let submit = useCallback(
    async (e) => {
      e.preventDefault();

      await SUBMIT_FORM(values)
        .then((res: any) => {
          if (res.includes(".exe")) {
            let a = document.createElement("a");
            a.href = res;
            a.target = "_blank";
            a.click();
          }

          setvalues({
            ...defualtRequestDemoFormInterface,
            productId: parseInt(id),
          });

          notify(
            {
              message: t(
                "Send Successfully and we will connect with you as soon as possible"
              ),
              width: 600,
            },
            "success",
            6000
          );
          handleClose();
        })
        .catch((e) => {
          console.log(e);
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [values, id, t, handleClose]
  );

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <Modal
        title={t("Request Demo")}
        visable={visable}
        closeModalHandler={handleClose}
      >
        <form onSubmit={submit}>
          <div
            className="modal__body"
            style={{ color: "#3a7498", margin: "10px" }}
          >
            <h4 style={{ marginBottom: "20px" }}>
              {t("Thank you for your interest in receiving a demo ")}
            </h4>

            <div className="row px-5 py-2">
              <div className="col-md-6 col-12">
                <Input
                  label={t("First Name")}
                  isRequired={true}
                  type={InputType.text}
                  name="firstName"
                  onChange={HandleChange}
                  value={values.firstName}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={t("Last Name")}
                  isRequired={true}
                  type={InputType.text}
                  name="lastName"
                  onChange={HandleChange}
                  value={values.lastName}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={t("Phone Number")}
                  isRequired={true}
                  type={InputType.text}
                  onChange={HandleChange}
                  name="phoneNumber"
                  value={values.phoneNumber}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  label={t("E-mail")}
                  isRequired={true}
                  type={InputType.email}
                  onChange={HandleChange}
                  name={"email"}
                  value={values.email}
                />
              </div>

              <div className="col-md-6 col-12">
                <Input
                  isRequired
                  label={t("Company Name")}
                  name="companyName"
                  value={values.companyName}
                  onChange={HandleChange}
                  type={InputType.text}
                />
              </div>
              <div className="col-md-6 col-12">
                <Input
                  isRequired
                  label={t("Industry")}
                  name="industry"
                  onChange={HandleChange}
                  value={values.industry}
                  type={InputType.text}
                />
              </div>
            </div>
          </div>

          <div className="w-100 d-flex justify-content-center">
            <button className="btn signInButton" type={"submit"}>
              {t("Send")}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default RequestDemoModal;
