import notify from "devextreme/ui/notify";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CardBody } from "reactstrap";
import { ProductImages } from "../../Interfaces";
import ProgramImageForm from "./ProgramImageForm";
import { DELETE_PRODUCT_IMAGES } from "./ProgramImagesApi";
const ProgramImages = ({ data, ProductId = 0 }) => {
  const { t } = useTranslation();
  let [list, setList] = useState<ProductImages[]>([]);
  const defualtValues = useRef<ProductImages>({
    Id: 0,
    ImagePath: "",
    Title: "",
    TitleEn: "",
    ProductId: ProductId,
  });
  let add = useCallback(
    (e) => {
      list.push(defualtValues.current);
      setList([...list]);
    },
    [list]
  );
  let HandleChange = useCallback(
    (id, name, value) => {
      setList(
        list.map(function (el) {
          if (el.Id === id) {
            return { ...el, [name]: value };
          }
          return el;
        })
      );
    },
    [list]
  );
  useEffect(() => {
    setList(data);
  }, [data]);
  let insertAction = useCallback(
    (val) => {
      setList(
        list.map(function (el) {
          if (el.Id === 0) {
            return { ...val };
          }
          return el;
        })
      );
    },
    [list]
  );
  let Delete = useCallback(
    async (element) => {
      await DELETE_PRODUCT_IMAGES(element)
        .then(() => {
          setList(
            list.filter(function (el) {
              return el.Id !== element;
            })
          );
          notify(
            { message: t("Deleted Successfully"), width: 600 },
            "success",
            3000
          );
        })
        .catch(() => {
          notify({ message: t("Failed Try again"), width: 600 }, "error", 3000);
        });
    },
    [list, t]
  );
  return (
    <div>
      <div>
        <Button
          className="btn btn btn-success col-12"
          onClick={add}
          disabled={list && list.find((x) => x.Id === 0) ? true : false}
        >
          {t("Add")}
        </Button>
        <CardBody>
          <div
            style={{
              maxHeight: "500px",
              paddingBottom: "10px",
              overflowY: "auto",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            {list
              ? list.map((element, index) => {
                  return (
                    <div key={index}>
                      <ProgramImageForm
                        data={element}
                        Delete={Delete}
                        HandleChange={HandleChange}
                        insertAction={insertAction}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </CardBody>
      </div>
    </div>
  );
};

export default ProgramImages;
