import React from "react";
import { useTranslation } from "react-i18next";
import FAQs from "../../../../SharedComponents/FAQs/FAQs";

import { Question } from "../../../Admin.Product/Interfaces";
import "./ProductFAQs.css";

interface Props {
  productQuestions?: Question[];
}

const ProductFAQs: React.FC<Props> = ({ productQuestions }) => {
  const { t } = useTranslation();
  return (
    <>
      <section className="product__section product__faqs ">
        <div className="index__container">
          <h1 className="product__section__title text-center">
            {t("Frequently Asked Questions")}
          </h1>
          <div className="product__faqs__items col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12">
            <FAQs data={productQuestions} />
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductFAQs;
