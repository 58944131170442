import React from "react";
import "./TooltipContent.css";

import { useTranslation } from "react-i18next";
const TooltipContent = (props: { data: any }) => {
  const { i18n } = useTranslation();
  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        padding: "5px",
      }}
    >
      <div></div>
      <h3>{i18n.language === "en" ? props.data.titleEn : props.data.title}</h3>
      <p>
        {i18n.language === "en"
          ? props.data.descriptionEn
          : props.data.description}
      </p>
    </div>
  );
};

export default React.memo(TooltipContent);
