import React from "react";
import { useTranslation } from "react-i18next";
import { ControlsInformation } from "../../../Admin.Product/Interfaces";
import "./ProductControlInfo.css";
import ReactHtmlParser from "react-html-parser";
export interface ProductControlInfoItem {
	title: string;
	controls: string[];
}

interface Props {
	productControlInfo?: ControlsInformation[];
}

const ProductControlInfo: React.FC<Props> = ({ productControlInfo = [] }) => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<section className="product__section product__control__info">
				<div className="index__container section-divider">
					<h1 className="product__section__title text__middle w-100 weight-500">
						{t("Additional Info")}
					</h1>
					<div className="product__control__info__content my-5">
						{productControlInfo.map((item, index) => {
							return (
								<div
									key={index}
									className="product__control__info__item my-3 p-3 col-lg-3 col-md-3 col-sm-4 col-6"
								>
									<div className="title">
										{" "}
										{i18n.language === "en"
											? item?.TitleEn
											: item?.Title}
									</div>

									{ReactHtmlParser(
										i18n.language === "en"
											? item?.DescriptionEn
											: item?.Description
									)}
									{/* {item.controls.map((control, index) => {
										return <p key={index}>{control}</p>;
									})} */}
								</div>
							);
						})}
					</div>
				</div>
			</section>
		</>
	);
};

export default React.memo(ProductControlInfo);
