import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";
import CategoriesExamples from "./Modals/CategoriesExamples/CategoriesExamples";
import CategoriesItemsDefination from "./Modals/CategoriesItemsDefination/CategoriesItemsDefination";
import GetStarted from "./Modals/GetStarted/GetStarted";
//css

function Demos() {
  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="no__background">
        <Navbar />
        <div
          className="absolute__from__nav"
          style={{
            direction: i18n.language === "en" ? "ltr" : "rtl",
          }}
        >
          <main>
            <div className="demosWrapper ">
              <div className="demosContainer">
                <GetStarted />
                <CategoriesExamples />
                <CategoriesItemsDefination />
              </div>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    </>
  );
}

export default Demos;
