import { REQUEST } from "../../../Services/callAPI";
export const GET_SUPPORT = async () => {
  return await REQUEST({
    method: "get",
    url: "Support",
  });
};
export const UPDATE_SUPPORT = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Support",
    data: e,
  });
};
export const INSERT_SUPPORT = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Support",
    data: e,
  });
};
export const DELETE_SUPPORT = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Support/" + id,
  });
};
export const GET_TECHNOLOGY = async () => {
  return await REQUEST({
    method: "get",
    url: "Technology",
  });
};
export const UPDATE_TECHNOLOGY = async (e) => {
  return await REQUEST({
    method: "put",
    url: "Technology",
    data: e,
  });
};
export const INSERT_TECHNOLOGY = async (e) => {
  return await REQUEST({
    method: "post",
    url: "Technology",
    data: e,
  });
};
export const DELETE_TECHNOLOGY = async (id) => {
  return await REQUEST({
    method: "delete",
    url: "Technology/" + id,
  });
};
