import React, { useEffect, useState } from "react";
import { Popup } from "devextreme-react/popup";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { SelectBox } from "devextreme-react";
import { REQUEST } from "../../../../Services/callAPI";

const ImportPopup = ({ visible = false, onHiding, importHandle }) => {
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [products, setProducts] = useState([]);

	useEffect(() => {
		if (visible) {
			let config = {
				method: "GET",
				url: "Products/ProductsHaveOptions",
			};

			REQUEST(config).then((response) => {
				if (response && response.length > 0) {
					setProducts(response);
				}
			});
		}
	}, [visible]);

	return (
		<>
			<Popup
				id="importPopup"
				title="Import Options"
				visible={visible}
				onHiding={onHiding}
				width={"50%"}
				height={"250px"}
			>
				<Row>
					<Col className="pr-1" md="12">
						<FormGroup>
							<label>Product</label>
							<SelectBox
								id="product"
								disabled={!products}
								items={products}
								displayExpr={"ProductName"}
								valueExpr={"Id"}
								value={selectedProduct}
								onValueChange={(e) => setSelectedProduct(e)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className="pr-1" md="12">
						<Button
							className="btn btn btn-success col-12"
							disabled={!selectedProduct}
							onClick={() => importHandle(selectedProduct)}
						>
							{"Import"}
						</Button>
					</Col>
				</Row>
			</Popup>
		</>
	);
};

export default React.memo(ImportPopup);
