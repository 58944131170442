import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Card,
	Form,
	CardBody,
	Nav,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import "./Admin.Product.css";

import {
	AdditionalInfo,
	Benefit,
	Category,
	Control,
	ControlsInformation,
	Product,
	Question,
} from "./Interfaces";
import ControlForm from "./Forms/ControlForm/ControlForm";
import AdminSection from "./AdminSection";
import AdditionalInfoForm from "./Forms/AdditionalInfoForm/AdditionalInfoForm";
import BenefitsForm from "./Forms/BenefitsForm/BenefitsForm";
import QuestionForm from "./Forms/QuestionsForm/QuestionForm";
import ControlsInformationForm from "./Forms/ControlsInformationForm/ControlsInformationForm";
import DemoForm from "./Forms/DemoForm";
import { NavItem } from "react-bootstrap";
import DarkSectionForm from "./Forms/DarkSectionForm/DarkSectionForm";
import HeaderForm from "./Forms/HeaderForm/HeaderForm";
import BasicInformationForm from "./Forms/BasicInformationForm/BasicInformationForm";

import { AxiosRequestConfig } from "axios";
import { REQUEST } from "../../Services/callAPI";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProductTextForm from "./Forms/BenefitsForm/ProductTextForm";
import ProgramImages from "./Forms/ProgramImages/ProgramImages";
import PricingForm from "./Forms/PricingForm/PricingForm";

interface RouteParams {
	id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const AdminProduct: React.FC<Props> = ({ match }) => {
	const { t, i18n } = useTranslation();
	let productId = parseInt(match.params.id) || 0;

	const [productsCategries, setProductsCategries] = useState<Category[]>();

	// initial values
	const [product, setProduct] = useState<Product>();

	const updateProduct = useCallback((value, Id) => {
		setProduct((prev) => ({ ...prev, [Id]: value }));
	}, []);

	// ────────────────────────────────────────────────────────────────────────────────
	// ───────────────────────────────────────────────────────────── REPOSETORIES ─────
	// ────────────────────────────────────────────────────────────────────────────────

	const controlsColAttributes = useMemo(() => {
		return [
			{ field: "Title", caption: t("Title"), alignment: "center" },
			{
				field: "TitleEn",
				caption: t("Title En"),
				alignment: "center",
			},
			{ field: "SubTitle", caption: t("Subtitle"), alignment: "center" },
			{
				field: "SubTitleEn",
				caption: t("Subtitle") + "  En",
				alignment: "center",
			},
			{
				field: "IsActive",
				caption: t("Is Active"),
				dataType: "boolean",
				alignment: "center",
			},
		];
	}, [t]);

	const additionalInfoColAttributes = useMemo(() => {
		return [
			{ field: "Title", caption: t("Title"), alignment: "center" },
			{
				field: "TitleEn",
				caption: t("Title") + " En",
				alignment: "center",
			},
			{
				field: "IsActive",
				caption: t("Is Active"),
				dataType: "boolean",
				alignment: "center",
			},
		];
	}, [t]);

	const controlsInformationColAttributes = useMemo(() => {
		return [
			{
				field: "Title",
				caption: t("Control title"),
				alignment: "center",
			},
			{
				field: "TitleEn",
				caption: t("Control title") + "  En",
				alignment: "center",
			},
			{
				field: "IsActive",
				caption: t("Is Active"),
				alignment: "center",
				dataType: "boolean",
			},
		];
	}, [t]);

	const benefitsColAttributes = useMemo(() => {
		return [
			{ field: "Title", caption: t("Title"), alignment: "center" },
			{
				field: "TitleEn",
				caption: t("Title") + " En",
				alignment: "center",
			},
			{
				field: "Description",
				caption: t("Description"),
				alignment: "center",
			},
			{
				field: "DescriptionEn",
				caption: t("Description En"),
				alignment: "center",
			},
			{
				field: "IsActive",
				caption: t("Is Active"),
				alignment: "center",
				dataType: "boolean",
			},
		];
	}, [t]);

	const questionsColAttributes = useMemo(() => {
		return [
			{ field: "Rank", caption: t("Rank"), alignment: "center" },
			{ field: "Question", caption: t("Questions"), alignment: "center" },
			{
				field: "QuestionEn",
				caption: t("Question En"),
				alignment: "center",
			},
			{ field: "Answer", caption: t("Answer"), alignment: "center" },
			{
				field: "AnswerEn",
				caption: t("Answer En"),
				alignment: "center",
			},
		];
	}, [t]);
	//#endregion

	useEffect(() => {
		// get categories
		let config: AxiosRequestConfig = {
			method: "GET",
			url: `Categories`,
		};

		REQUEST(config).then((response) => {
			let _productsCategries = response as unknown as Category[];
			setProductsCategries([..._productsCategries]);
		});

		// request and get product data
		if (productId) {
			let config: AxiosRequestConfig = {
				method: "GET",
				url: `Products/GetByDomainProductId/${productId}`,
			};

			REQUEST(config).then((response) => {
				let _product = response as unknown as Product;

				setProduct({ ..._product });
			});
		}
	}, [productId]);

	// ────────────────────────────────────────────────────────────────────────────────
	// ────────────────────────────────────────────────────────────────────────────────
	// ────────────────────────────────────────────────────────────────────────────────

	const submitForm = useCallback((e) => {
		e.preventDefault();
	}, []);

	const [activeTab, setActiveTab] = useState("0");
	const toggle = useCallback(
		(tab) => {
			if (activeTab !== tab) setActiveTab(tab);
		},
		[activeTab]
	);

	return (
		<>
			<div className="content">
				<div
					style={{
						direction: i18n.language === "en" ? "ltr" : "rtl",
					}}
				>
					<Form onSubmit={submitForm}>
						<Card className="card-user">
							<CardBody>
								<Nav tabs>
									{[
										t("Basic Information"), // 0
										t("Header"), // 1
										t("Controls"), // 2
										t("Benefits"), // 3
										t("Questions"), // 4
										t("Dark Section"), // 5
										t("Additional Info"), // 6
										t("Controls Information"), // 7
										t("Demo"), // 8
										t("Galary"), // 9
										t("Pricing"),
									].map((tabName, index) => {
										return index.toString() === "0" ||
											(productId && productId > 0) ? (
											<NavItem key={index}>
												<NavLink
													className={`${
														activeTab ===
														index.toString()
															? "active "
															: "cursorPointer text-info"
													}`}
													onClick={() => {
														toggle(
															index.toString()
														);
													}}
												>
													{tabName}
												</NavLink>
											</NavItem>
										) : null;
									})}
								</Nav>

								<TabContent
									activeTab={activeTab}
									className="mt-3"
								>
									<TabPane tabId="0">
										<BasicInformationForm
											updateProduct={updateProduct}
											data={
												product?.ProductBasicsInformation
											}
											productId={productId}
											categries={productsCategries}
										/>
									</TabPane>

									<TabPane tabId="1">
										<HeaderForm
											data={product?.ProductHeader}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="2">
										<AdminSection<Control>
											data={product?.ProductControls}
											component={ControlForm}
											colAttributes={
												controlsColAttributes
											}
											controller={"ProductControls"}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="3">
										<ProductTextForm
											data={product?.ProductText}
											productId={productId}
										/>
										<AdminSection<Benefit>
											data={product?.ProductBenefits}
											component={BenefitsForm}
											colAttributes={
												benefitsColAttributes
											}
											controller={"ProductBenefits"}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="4">
										<AdminSection<Question>
											data={product?.ProductQuestions}
											component={QuestionForm}
											colAttributes={
												questionsColAttributes
											}
											controller={"ProductQuestions"}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="5">
										<DarkSectionForm
											data={product?.ProductDarkSection}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="6">
										<AdminSection<AdditionalInfo>
											data={
												product?.ProductAdditionalInfos
											}
											component={AdditionalInfoForm}
											colAttributes={
												additionalInfoColAttributes
											}
											controller={"ProductAdditionalInfo"}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="7">
										<AdminSection<ControlsInformation>
											data={
												product?.ProductControlInformation
											}
											component={ControlsInformationForm}
											colAttributes={
												controlsInformationColAttributes
											}
											controller={
												"ProductControlsInformation"
											}
											productId={productId}
										/>
									</TabPane>

									<TabPane tabId="8">
										<DemoForm
											data={product?.ProductDemo}
											productId={productId}
										/>
									</TabPane>
									<TabPane tabId="9">
										<ProgramImages
											data={product?.ProductImages}
											ProductId={productId}
										/>
									</TabPane>
									<TabPane tabId="10">
										<PricingForm
											data={product?.ProductPricing}
											productId={productId}
										/>
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Form>
				</div>
			</div>
		</>
	);
};

export default React.memo(AdminProduct);

/* <NumberBox
	Id={"rank"}
	name={"rank"}
	value={product?.rank}
	onInput={({ event, element }) => {
		if (event) {
			let input = event.target as HTMLInputElement;

			updateProduct(parseInt(input.value), element.Id);
		}
	}}
/>; */
