import React, { useEffect } from "react";
import "./Modal.css";

interface Props {
  title: string;
  visable: boolean;
  closeModalHandler: (e: any) => void;
}

const Modal: React.FC<Props> = ({
  title,
  visable,
  closeModalHandler,
  children,
}) => {
  useEffect(() => {
    visable
      ? document.body.classList.add("no__scroll")
      : document.body.classList.remove("no__scroll");
  }, [visable]);
  return (
    <>
      <div className={"modal " + (visable ? "" : " hidden")}>
        <div className="modal__overlay"></div>
        <div
          className="modal__wrapper"
          style={{
            transform: visable ? "translateY(10vh)" : "translateY(-100vh)",
          }}
        >
          <div className="modal__header">
            <p>{title}</p>
            <i
              className="close__modal__btn fas fa-times fa-lg"
              onClick={closeModalHandler}
            />
          </div>
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Modal;
