import { useState, useEffect } from "react";

import {
	defualtParagraphSectionInterface,
	ParagraphSectionInterface,
} from "../../../Interfaces/Interfaces";
import { GET_SECTION } from "../../12.TeamMembers/admin/Admin.Team.Api";
import Header from "./Components/Header/Header";
import Services from "./Components/Services/Services";
import Tecnologies from "./Components/Techonologies/Tecnologies";
import Navbar from "../../../SharedComponents/Navbar/Navbar";
import Footer from "../../1.Home/user/Components/Footer/user/Footer";

// import MainNavigationBar from "../../SharedComponents/MainNavigationBar/MainNavigationBar";
const AboutUS = () => {
	let [paragraphs, setparagraphs] = useState<ParagraphSectionInterface[]>([]);

	useEffect(() => {
		let get_data = async () => {
			let x: any = await GET_SECTION("Aboutus");

			setparagraphs(x);
		};
		get_data();

		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="about__us__header">
			<Navbar />

			<div className="product__container">
				<section style={{ position: "relative" }}>
					<Header
						obj={
							paragraphs[0]
								? paragraphs[0]
								: defualtParagraphSectionInterface
						}
					/>
				</section>
				<main>
					<Services
						obj={
							paragraphs[1]
								? paragraphs[1]
								: defualtParagraphSectionInterface
						}
					/>
					<Tecnologies />
					<Footer />
				</main>
			</div>
			{/* </div> */}
		</div>
	);
};

export default AboutUS;
