import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Control } from "../../Interfaces";
import InputTwoLanguages from "../../../../SharedComponents/AdminPanel/InputTwoLanguages/InputTwoLanguages";
import UploadImageButton from "../../../../SharedComponents/UploadImageButton/UploadImageButton";
import { apiEndPoint } from "../../../../Services/config.json";
import TextEditorTwoLanguages from "../../../../SharedComponents/AdminPanel/TextEditorTwoLanguages/TextEditorTwoLanguages";
import { Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";

interface Props {
  data: Control | undefined;
  onSubmit: (formData: Control, images?) => void;
  onCancel: () => void;
  productId: number;
}

const ControlForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  data,
  productId,
}) => {
  const [status, setStatus] = useState(Status.IDLE);
  const { t } = useTranslation();
  let defualtdata = useRef({
    Id: 0,
    Image: null,
    Image2: "",
    TitleEn: "",
    Title: "",
    SubTitleEn: "",
    SubTitle: "",
    DescriptionEn: "",
    Description: "",
    QuoteEn: "",
    Quote: "",
    IsActive: false,
    ProductId: productId,
  });
  const [controlInitValues, setControlInitValues] = useState<Control>({
    ...defualtdata.current,
  });
  const [images, setImages] = useState<any>([]);
  const [control, setControl] = useState<Control>(controlInitValues);

  useEffect(() => {
    if (data) {
      setStatus(Status.UPDATE);
      setControl({ ...data });
      if (data["Image"] && (data["Image"] as string).includes(apiEndPoint)) {
        data["Image"] = data["Image"].replace(apiEndPoint, "");
      }
      setImages(data.Image2 ? [data?.Image, data.Image2] : [data?.Image]);
      setControlInitValues({ ...data });
    } else {
      setStatus(Status.ADD);
      setControlInitValues({ ...defualtdata.current });
      setControl({ ...defualtdata.current });
    }
    setOnclickSubmit(false);
  }, [data]);

  //
  const updateControl = useCallback((value, id) => {
    setControl((prev) => ({ ...prev, [id]: value }));
  }, []);

  // tab navigation
  const [activeTab, setActiveTab] = useState("1");
  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  // image handlers
  let handleGetImages = async (event) => {
    let files: any = event.target.files;

    let dataxx = [...images, ...files];
    if (dataxx.length > 2) {
      dataxx = dataxx.reverse();
      dataxx = dataxx.slice(0, 2);
      control.Image = dataxx[0];
      control.Image2 = dataxx[1];
    } else if (images.length === 1) {
      control.Image2 = files[0];
    } else {
      control.Image = files[0];
      control.Image2 = files[1] ? files[1] : "";
    }
    setControl(control);
    setImages(dataxx);
  };

  let handleRemoveImage = (element: FileList | string) => {
    if (
      typeof element === "string" &&
      (element as string).toString().includes(apiEndPoint)
    ) {
      element = element.toString().replace(apiEndPoint, "");
    }

    if (images.indexOf(element) === 0) {
      control.Image = "";
      if (images.length === 2) {
        control.Image = images[1];
        control.Image2 = "";
      }
    }
    if (images.indexOf(element) === 1) {
      control.Image2 = "";
    }
    setControl(control);
    setImages((prevState) => prevState.filter((ele) => ele !== element));
  };
  let handleRemoveAllImages = () => {
    setImages([]);
    control.Image = "";
    control.Image2 = "";
    setControl(control);
  };
  const isNotValid = useMemo(() => {
    let keysToCheck = [
      "TitleEn",
      "Title",
      "SubTitleEn",
      "SubTitle",
      "DescriptionEn",
      "Description",
      "QuoteEn",
      "Quote",
      "ProductId",
    ];

    for (let key of keysToCheck) {
      if (!control[key] || !control[key].toString()) {
        return true;
      }
    }

    if (images.length === 0) {
      return true;
    }

    return false;
  }, [control, images]);

  // submit form
  const addHandle = useCallback(async () => {
    setOnclickSubmit(true);
    await onSubmit(control, images);
  }, [control, onSubmit, images]);
  let [onsubmitclick, setOnclickSubmit] = useState(false);

  return (
    <>
      <div className="mt-2">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`cursorPointer ${
                activeTab === "1" ? "active" : "text-info"
              }`}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("Information")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursorPointer ${
                activeTab === "2" ? "active" : "text-info"
              }`}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("Description")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursorPointer ${
                activeTab === "3" ? "active" : "text-info"
              }`}
              onClick={() => {
                toggle("3");
              }}
            >
              {t("Quote")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          <TabPane tabId="1">
            <InputTwoLanguages
              id="Title"
              label={t("Control Title")}
              onValueChange={updateControl}
              value={control.Title}
              valueEn={control.TitleEn}
            />
            <InputTwoLanguages
              id="SubTitle"
              label={t("Control Subtitle")}
              onValueChange={updateControl}
              value={control.SubTitle}
              valueEn={control.SubTitleEn}
            />
            <FormGroup className="image__button">
              <label>{t("Control Image")}</label>
              <UploadImageButton
                isMultiple={true}
                handleGetImages={handleGetImages}
                handleRemoveImage={handleRemoveImage}
                handleRemoveAllImages={handleRemoveAllImages}
                imagesFiles={
                  images
                    ? images.map((da) => {
                        return typeof da === "string" ? apiEndPoint + da : da;
                      })
                    : []
                }
              />
            </FormGroup>
            <Col className="pr-1" md="6">
              <FormGroup>
                <label htmlFor="IsActive" className="d-block">
                  {t("Is Active")}
                </label>
                <input
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  type="checkbox"
                  checked={control.IsActive}
                  id="IsActive"
                  onChange={(e) => updateControl(e.target.checked, e.target.id)}
                />
              </FormGroup>
            </Col>
          </TabPane>
          <TabPane tabId="2">
            <TextEditorTwoLanguages
              id="Description"
              label={t("Control Description")}
              value={control.Description}
              valueEn={control.DescriptionEn}
              onValueChange={updateControl}
            />
          </TabPane>
          <TabPane tabId="3">
            <TextEditorTwoLanguages
              id="Quote"
              label={t("Control Quote")}
              value={control.Quote}
              valueEn={control.QuoteEn}
              onValueChange={updateControl}
            />
          </TabPane>
        </TabContent>
        <div className="button-container">
          <Row>
            <Col style={{ width: "170px" }}>
              <Button
                className="btn btn btn-success col-12"
                disabled={isNotValid || onsubmitclick}
                onClick={addHandle}
              >
                {status === Status.UPDATE ? t("Update") : t("Add")}
              </Button>
            </Col>
            <Col style={{ width: "170px" }}>
              <Button
                className=" btn btn-danger col-12"
                disabled={false}
                onClick={onCancel}
              >
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ControlForm;
